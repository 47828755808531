import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Alert, Button, Card } from "react-bootstrap";

import {
  Sliders,
  BookOpen,
  PlusSquare,
  PlusCircle,
  Settings,
} from "react-feather";

import {
  SIDEBAR_POSITION,
  SIDEBAR_BEHAVIOR,
  LAYOUT,
  THEME,
} from "../constants";
import useOuterClick from "../hooks/useOuterClick";
import useTheme from "../hooks/useTheme";
import useSidebar from "../hooks/useSidebar";
import useLayout from "../hooks/useLayout";

import empty_invoice from "../assets/img/icons/empty_invoice.png";
import empty_inventory from "../assets/img/icons/empty_inventory.png";
import empty_partner from "../assets/img/icons/empty_partner.png";
import empty_transaction from "../assets/img/icons/empty_transaction.png";
import schedule from "../assets/img/icons/schedule.png";

const themeOptions = [
  {
    name: "Default",
    value: THEME.DEFAULT,
  },
  {
    name: "Colored",
    value: THEME.COLORED,
  },
  {
    name: "Dark",
    value: THEME.DARK,
  },
  {
    name: "Light",
    value: THEME.LIGHT,
  },
];

const sidebarPositionOptions = [
  {
    name: "Left",
    value: SIDEBAR_POSITION.LEFT,
  },
  {
    name: "Right",
    value: SIDEBAR_POSITION.RIGHT,
  },
];

const sidebarBehaviorOptions = [
  {
    name: "Sticky",
    value: SIDEBAR_BEHAVIOR.STICKY,
  },
  {
    name: "Fixed",
    value: SIDEBAR_BEHAVIOR.FIXED,
  },
  {
    name: "Compact",
    value: SIDEBAR_BEHAVIOR.COMPACT,
  },
];

const layoutOptions = [
  {
    name: "Fluid",
    value: LAYOUT.FLUID,
  },
  {
    name: "Boxed",
    value: LAYOUT.BOXED,
  },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Settings2 = ({ navigation }) => {
  const query = useQuery();
  const [isOpen, setIsOpen] = useState(false);

  const { theme, setTheme } = useTheme();
  const { position, setPosition, behavior, setBehavior } = useSidebar();
  const { layout, setLayout } = useLayout();

  const navigate = useNavigate();

  const innerRef = useOuterClick(() => {
    setIsOpen(false);
  });

  const setSettingByQueryParam = (name, set) => {
    const value = query.get(name);
    if (value) {
      set(value);
    }
  };

  // Read from query parameter (e.g. ?theme=dark)
  // only for demo purposes
  useEffect(() => {
    setSettingByQueryParam("theme", setTheme);
    setSettingByQueryParam("sidebarPosition", setPosition);
    setSettingByQueryParam("sidebarBehavior", setBehavior);
    setSettingByQueryParam("layout", setLayout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setIsOpen(false);
  }, [navigate]);

  return <></>;
};

export default Settings2;
