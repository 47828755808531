import React, { useEffect, useState } from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import { AtSign, ChevronRight, LogOut, Trello } from "react-feather";
import { ReactComponent as Logo } from "../../assets/img/logo1.svg";
import icon from "../../assets/img/illustrations/icon.png";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ items = true }) => {
  const [data, setData] = useState({});
  const [token, settoken] = useState("");
  const { isOpen, behavior } = useSidebar();

  const navigate = useNavigate();
  const getData = (token) => {
    fetch("https://bestmate.us/api/business/get", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setData(result);
          // setLoading(false);
          console.log(result, result.businessExists);
        },
        (error) => {
          console.error("Error fetching data: ", error);
        }
      );
  };

  useEffect(async () => {
    // firebase.auth().onAuthStateChanged(async (user) => {
    var user = firebase.auth().currentUser;
    if (user) {
      console.log(user);
      let tokenss = await user.getIdToken();
      // setBusiness_email(user.email);
      settoken(tokenss);
      getData(tokenss);
    }
    // });
  }, []);
  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""}`}>
      <div className="sidebar-content">
        <PerfectScrollbar
          style={{
            display: "flex",
            flexDirection: "column",
            // marginBottom: 400,
            paddingBottom: 40,
            backgroundColor: "#405189",
          }}
        >
          {behavior !== "sticky" ? (
            <a
              className=" sidebar-brand mb-0"
              href="/customer"
              style={{
                position: "sticky",
                top: "0",
                minWidth: "80px",
                maxWidth: "80px",
                zIndex: "20",
                padding: "18.4px 20px 15px",
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
              }}
            >
              <img
                src={icon}
                alt="bestmate"
                style={{ height: 25, marginRight: 9, marginBottom: 8 }}
              />
            </a>
          ) : (
            <a
              className=" sidebar-brand mb-0"
              href="/customer"
              style={{
                position: "sticky",
                top: "0",
                minWidth: "220px",
                maxWidth: "220px",
                zIndex: "20",
                padding: "18.4px 20px 15px",
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
              }}
            >
              <img
                src={icon}
                alt="bestmate"
                style={{ height: 25, marginRight: 9, marginBottom: 8 }}
              />
              <span className="align-middle me-3">BestMate</span>
            </a>
          )}
          <h2
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 30,
              marginBottom: 0,
              fontSize: 18,
              fontWeight: "bold",
              color: "white",
            }}
          >
            CRM
          </h2>
          <div style={{ marginTop: 20 }}>
            <SidebarNav items={items} />
          </div>
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
