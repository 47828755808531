import React from "react";
import moment from "moment";
import useAuth from "../hooks/useAuth";
import { Document, Page } from "react-pdf";
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function InvoiceTemplate(props) {
  const { bdata } = useAuth();
  let usercurrency = localStorage.getItem("currency") || "USD";
  const isTemplate = props.from ? true : false;
  console.log(bdata);
  let data = props.data;
  const pricelist =
    data.invoice_partner &&
    data.invoice_partner.partner_price_list &&
    data.invoice_partner.partner_price_list.price_list_multiplier
      ? Number(data.invoice_partner.partner_price_list.price_list_multiplier)
      : 1;
  const htmlContent = `<body style="background: #edf0f3; padding: 0; margin: 0;" id="printableArea" >
 <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" style="background: #edf0f3;">
   <tr>
       <td align="center" valign="top" style="padding: 20px 20px;">
           <table border="0" cellpadding="0" cellspacing="0" align="center" style="width:100%;max-width:350px;min-width:250px;background: #fff; border-radius: 5px; font-family: 'Libre Franklin', sans-serif; font-size: 13px; font-weight: 300; color: #000;">                
               <tr>
                   
                   <td align="left" valign="top" style="padding: 10px 15px; border-bottom: 1px solid #ddd;" class="requireborder">
                       <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
                           <tr>
                            
                                  ${
                                    bdata.business_logo
                                      ? ` <td valign="top" style="padding-top:4px;padding-bottom:3px;" class="imagecontainer">                    
                                      <img src=${
                                        bdata.business_logo
                                      } alt="Business_Logo" style="height:65px;margin-right:auto" class="image"; />   
                                
 
                                   </td>
                                   <td align="right" valign="top">
                                      <h4 style="margin: 0; padding: 0; padding-bottom: 4px; font-size: 18px;">${capitalizeFirstLetter(
                                        data.invoice_workflow_type.toLowerCase()
                                      )}</h4>
                                      <p style="margin:0; padding:0; line-height: 14px;font-size:10px;">${capitalizeFirstLetter(
                                        data.invoice_workflow_type.toLowerCase()
                                      )} No: ${data.invoice_number} <br>
                                      Date: ${moment(
                                        new Date(data.invoice_created_at)
                                      ).format("MMM DD, YYYY")} <br>
                                      Due Date: ${moment(
                                        new Date(data.invoice_due_date)
                                      ).format("MMM DD, YYYY")}</p>
                               </td>`
                                      : ` <td align="left" valign="top">
 
                                      <h4 style="margin: 0; padding: 0; padding-bottom: 4px; font-size: 18px;">${capitalizeFirstLetter(
                                        data.invoice_workflow_type.toLowerCase()
                                      )}</h4>
                                      <p style="margin:0; padding:0; line-height: 14px;font-size:10px;">${capitalizeFirstLetter(
                                        data.invoice_workflow_type.toLowerCase()
                                      )} No: ${data.invoice_number} <br>
                                      Date: ${moment(
                                        new Date(data.invoice_created_at)
                                      ).format("MMM DD, YYYY")} <br>
                                      Due Date: ${moment(
                                        new Date(data.invoice_due_date)
                                      ).format("MMM DD, YYYY")}</p>
                               </td>`
                                  }
                              
                              
                           </tr>
                          
                       </table>
                   </td>
               </tr>
               <tr>
                   <td align="left" valign="top" style="padding: 10px 15px;">
                       <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
                           <tr>
                               <td align="left"  valign="top">
                                   <h4 style="margin: 0; padding: 0; font-size: 10px; color: #999;">BILL TO:</h4>
                                   <p style="margin: 0; padding: 0; line-height: 14px;font-size:10px;"><strong style="margin: 0; padding: 0; display: block;">${
                                     data.invoice_partner.partner_first_name
                                   } ${
    data.invoice_partner.partner_last_name
  }</strong>
                                    ${
                                      Object.keys(
                                        data.invoice_partner
                                          .partner_billing_address
                                      ).includes("street")
                                        ? data.invoice_partner
                                            .partner_billing_address.street
                                        : ""
                                    }<br>
                                    ${
                                      Object.keys(
                                        data.invoice_partner
                                          .partner_billing_address
                                      ).includes("city")
                                        ? data.invoice_partner
                                            .partner_billing_address.city
                                        : ""
                                    }<br>
                                      ${
                                        Object.keys(
                                          data.invoice_partner
                                            .partner_billing_address
                                        ).includes("state")
                                          ? data.invoice_partner
                                              .partner_billing_address.state
                                          : ""
                                      }  ${
    Object.keys(data.invoice_partner.partner_billing_address).includes(
      "zip_code"
    )
      ? data.invoice_partner.partner_billing_address.zip_code
      : ""
  }  ${
    Object.keys(data.invoice_partner.partner_billing_address).includes(
      "country"
    )
      ? data.invoice_partner.partner_billing_address.country
      : ""
  }</p>
                               </td>
                               <td align="right" style="text-align:right" valign="top">
                                  
                               <h4 style="margin: 0; padding: 0; font-size: 10px; color: #999;">FROM:</h4>
                                      
                                   ${
                                     isTemplate
                                       ? `    <p style="margin: 0; padding: 0; line-height: 14px; font-size: 10px;"><strong style="margin: 0; padding: 0; display: block;">Acme Business LLC</strong>
                                     123 Easy Street<br>
                                     New York
                                      <br>
                                     NY 11001</p>`
                                       : `    <p style="margin: 0; padding: 0; line-height: 14px; font-size: 10px;"><strong style="margin: 0; padding: 0; display: block;">${
                                           bdata.business_name
                                             ? bdata.business_name
                                             : bdata.business_user_name
                                         }</strong>
                                     ${
                                       Object.keys(
                                         bdata.business_address
                                       ).includes("street")
                                         ? bdata.business_address.street
                                         : ""
                                     }<br>
                                     ${
                                       Object.keys(
                                         bdata.business_address
                                       ).includes("city")
                                         ? bdata.business_address.city
                                         : ""
                                     }<br>
                                     ${
                                       Object.keys(
                                         bdata.business_address
                                       ).includes("state")
                                         ? bdata.business_address.state
                                         : ""
                                     }  ${
                                           Object.keys(
                                             bdata.business_address
                                           ).includes("zip_code")
                                             ? bdata.business_address.zip_code
                                             : ""
                                         }  ${
                                           Object.keys(
                                             bdata.business_address
                                           ).includes("country")
                                             ? bdata.business_address.country
                                             : ""
                                         }</p>`
                                   }
                               </td>
                           </tr>
                       </table>
                   </td>
               </tr>
               <tr>
                   <td align="right" valign="top" style="min-height: 270px; display: table; width: 100%;">
                       <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" >
                           <tr>
                               <td align="left" valign="top" style="padding: 0 15px">
                                   <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
                                       <thead style="margin: 0; padding: 0;">
                                           <tr class="th" style="margin: 0; padding: 0; background: #4f83cc; color: #fff;">
                                               <th align="left" valign="top" style="padding:2px;font-size:10px;flex:3">Items</th>
                                               <th align="center" valign="top" style="padding:2px;font-size:10px;width:%;text-align:center">Qty</th>
                                               <th align="center" valign="top"  style="padding:2px;font-size:10px;width:20%; text-align:center">Unit Cost</th>
                                               <th align="right" valign="top"  align="right" style="padding:2px;font-size:10px;width:14%; text-align:right">Total</th>
                                           </tr>
                                       </thead>
                                        <tbody style="margin: 0; padding: 0; border: 0;">
                                      
                                          ${data.invoice_order
                                            .map((elem) => {
                                              return `<tr class="itemrowstyle">
                                            <td align="left" valign="top" style="border-bottom: 1px solid #ddd; padding: 4px; flex:3" >
                                                <p style="margin: 0; padding: 0; line-height: 20px;font-size:10px">${
                                                  elem.item_name
                                                }</p>
                                            </td>
                                            <td align="center" valign="top" style="border-bottom: 1px solid #ddd; padding: 4px;"><p style="margin: 0; padding: 0; line-height: 20px;font-size:10px; text-align:center">${
                                              elem.item_quantity
                                            }</p></td>
                                            <td align="center" valign="top" style="border-bottom: 1px solid #ddd; padding: 4px;"><p style="margin: 0; padding: 0; line-height: 20px;font-size:10px; text-align:center">${
                                              data.invoice_partner_type ===
                                              "CUSTOMER"
                                                ? (
                                                    elem.item_selling_price *
                                                    pricelist
                                                  ).toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency:
                                                      data &&
                                                      data.invoice_currency_code
                                                        ? data.invoice_currency_code
                                                        : usercurrency,
                                                  })
                                                : elem.item_cost_price.toLocaleString(
                                                    "en-US",
                                                    {
                                                      style: "currency",
                                                      currency:
                                                        data &&
                                                        data.invoice_currency_code
                                                          ? data.invoice_currency_code
                                                          : usercurrency,
                                                    }
                                                  )
                                            }</p></td>
                                            <td align="right" valign="top" style="border-bottom: 1px solid #ddd; padding: 4px;"><p style="margin: 0; padding: 0; line-height: 20px;font-size:10px; text-align:right">${
                                              data.invoice_partner_type ===
                                              "CUSTOMER"
                                                ? (
                                                    elem.item_selling_price *
                                                    pricelist *
                                                    elem.item_quantity
                                                  ).toLocaleString("en-US", {
                                                    style: "currency",
                                                    currency:
                                                      data &&
                                                      data.invoice_currency_code
                                                        ? data.invoice_currency_code
                                                        : usercurrency,
                                                  })
                                                : elem.item_cost_price.toLocaleString(
                                                    "en-US",
                                                    {
                                                      style: "currency",
                                                      currency:
                                                        data &&
                                                        data.invoice_currency_code
                                                          ? data.invoice_currency_code
                                                          : usercurrency,
                                                    }
                                                  )
                                            }
                                             </p></td>
                                        </tr>     
                                      `;
                                            })
                                            .join("")}
                                          
                                       </tbody>
                                   </table>
                               </td>
                           </tr>
                           <tr >
                           <td align="right" valign="top" style="padding: 0px 15px;">
                           <p style="margin: 0; padding: 0; line-height: 17px; font-weight: bold; font-size:10px;margin-top:8px">Subtotal: <span style="display: inline-block; min-width: 70px;">${props.subtotal.toLocaleString(
                             "en-US",
                             {
                               style: "currency",
                               currency:
                                 data && data.invoice_currency_code
                                   ? data.invoice_currency_code
                                   : usercurrency,
                             }
                           )}</span><br></p>
                           <p style="margin: 0; padding: 0; line-height: 17px; font-size:10px;">Discount: <span style="display: inline-block; min-width: 70px;">-${(
                             props.subtotal * data.invoice_discount_rate
                           ).toLocaleString("en-US", {
                             style: "currency",
                             currency:
                               data && data.invoice_currency_code
                                 ? data.invoice_currency_code
                                 : usercurrency,
                           })}</span><br>
                           Tax: <span style="display: inline-block; min-width: 70px;">${(
                             (props.subtotal -
                               props.subtotal * data.invoice_discount_rate) *
                             data.invoice_tax_rate
                           ).toLocaleString("en-US", {
                             style: "currency",
                             currency:
                               data && data.invoice_currency_code
                                 ? data.invoice_currency_code
                                 : usercurrency,
                           })}</span></p>
                           <p class="total-txt" style="margin: 0; padding: 0; padding-top: 5px; font-weight: bold; line-height: 17px; font-size:10px;">Total: <span style="display: inline-block; min-width: 70px;">${(
                             props.subtotal -
                             props.subtotal * data.invoice_discount_rate +
                             (props.subtotal -
                               props.subtotal * data.invoice_discount_rate) *
                               data.invoice_tax_rate
                           ).toLocaleString("en-US", {
                             style: "currency",
                             currency:
                               data && data.invoice_currency_code
                                 ? data.invoice_currency_code
                                 : usercurrency,
                           })}</span></p>
                           <p style="margin: 0; padding: 0; line-height: 17px; font-size:10px;">Settled Amount: <span style="display: inline-block; min-width: 70px;">${Number(
                             data.invoice_settled_amount
                           ).toLocaleString("en-US", {
                             style: "currency",
                             currency:
                               data && data.invoice_currency_code
                                 ? data.invoice_currency_code
                                 : usercurrency,
                           })}</span><br> <strong style="display: inline-block; background: #f5f5f5; margin-top: 8px; margin-right: -15px; padding: 5px 15px">Balance Amount: <span style="display: inline-block; min-width: 70px;">${(
    props.subtotal -
    props.subtotal * data.invoice_discount_rate +
    (props.subtotal - props.subtotal * data.invoice_discount_rate) *
      data.invoice_tax_rate -
    data.invoice_settled_amount
  ).toLocaleString("en-US", {
    style: "currency",
    currency:
      data && data.invoice_currency_code
        ? data.invoice_currency_code
        : usercurrency,
  })}</span></strong></p>
                       </td>
                           </tr>
                       </table>
                   </td>
               </tr>
               <tr>
                ${
                  data.invoice_notes
                    ? `<td align="left" valign="top" style="padding: 10px 15px; border-top: 1px solid #ddd;">                    
                        <p style="margin: 0; padding: 0; line-height: 17px; font-size:10px"><strong style="display:block;">Notes:</strong> Thank you for your business.</p>                        
                   </td>`
                    : ` <td align="left" valign="top" style="padding: 20px 30px;">                    
                        <p style="margin: 0; padding: 0; line-height: 21px;"></p>                        
                   </td>`
                }
               </tr>
               
           </table>    
       </td>
   </tr>
 </table>
 </body>`;
  //   return (
  //     <>
  //       <Document file="https://bestmate.us/invoice/view/ImPYUR3refmB2SSkchFx">
  //       </Document>
  //     </>
  //   );
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}
export default InvoiceTemplate;
