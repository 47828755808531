import './Style/Table.css';
import '../../assets/css/App.css';

const Table = ({ data }) => {
    const columns = data[0];

    const maxColumns = Math.max(...data.map((row) => row.length));
    const filledRows = data
        .slice(1)
        .map((row) => [...row, ...Array(maxColumns - row.length).fill('')]);

    return (
        <div style={{ overflowX: 'auto' }}>
            {/* <table className='styled-table'>
                <thead>
                    <tr>
                        {columns.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {filledRows.map((row, rowIndex) => (
                        <tr
                            key={rowIndex}
                            className={
                                rowIndex % 2 === 0 ? 'even-row' : 'odd-row'
                            }
                        >
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table> */}
            <table className='styled-table'>
                <thead>
                    <tr>
                        {columns.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {filledRows.map((row, rowIndex) => (
                        <tr
                            key={rowIndex}
                            className={
                                rowIndex % 2 === 0 ? 'even-row' : 'odd-row'
                            }
                        >
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
