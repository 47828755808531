import { createContext, useEffect, useReducer, useState } from "react";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
// import { getStorage } from "firebase/storage";

import { firebaseConfig } from "../config";

const INITIALIZE = "INITIALIZE";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
  // var store = firebase.storage();
}

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === INITIALIZE) {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [token, setToken] = useState("");
  const [bdata, setBdata] = useState({});
  const [currency, setCurrency] = useState();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async (user, tokenid) => {
        if (user) {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
              token: tokenid,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: { isAuthenticated: false, user: null, token: "" },
          });
        }
      }),
    [dispatch]
  );

  const signIn = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  // const signInWithFaceBook = () => {
  //   const provider = new firebase.auth.FacebookAuthProvider();
  //   return firebase.auth().signInWithPopup(provider);
  // };
  const signInWithApple = () => {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    return firebase.auth().signInWithPopup(provider);
  };
  // const getToken = async () => {
  //   let token = await firebase.auth().currentUser.getIdToken(true);
  //   setToken(token);
  // };
  const getbdata = async () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      // var user = firebase.auth().currentUser;
      if (user) {
        let tokenss = await user.getIdToken();
        setToken(tokenss);
        const res = await fetch("https://bestmate.us/api/business/get", {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenss,
          },
        });
        const result = await res.json();
        setCurrency(result.business_currency_code);
        if (result.business_currency_code) {
          localStorage.setItem("currency", result.business_currency_code);
          localStorage.setItem("currencyName", result.business_currency_name);
        } else {
          localStorage.setItem("currency", "USD");
          localStorage.setItem("currencyName", "United States Dollar");
        }
        setBdata(result);
        console.log(result);
      } else {
        setToken("");
      }
    });
  };
  useEffect(() => {
    getbdata();
  }, []);

  const signUp = async (email, password) => {
    await firebase.auth().createUserWithEmailAndPassword(email, password);
    console.log("created");
  };
  const signOut = async () => {
    await firebase.auth().signOut();
    setBdata({});
    localStorage.removeItem("currency");
  };

  const resetPassword = async (email) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  // const auth = { ...state.user };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "firebase",
        // user: {
        //   id: auth.uid,
        //   email: auth.email,
        //   avatar: auth.avatar || profile?.avatar,
        //   displayName: auth.displayName || profile?.displayName,
        //   role: "user",
        // },
        token,
        bdata,
        signIn,
        signUp,
        signInWithGoogle,
        signInWithApple,
        usercurrency: currency,
        // signInWithFaceBook,
        // signInWithTwitter,
        signOut,
        setBdata,
        resetPassword,
        getbdata,
        // store,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
