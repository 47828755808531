import React from "react";
import { Helmet } from "react-helmet-async";
import { Card, Col, FloatingLabel, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";
import "../../assets/css/App.css";

import SignUp from "../../components/auth/SignUp";
import icon from "../../assets/img/illustrations/icon.png";
import google from "../../assets/img/icons/google.png";
import apple from "../../assets/img/icons/apple.png";
import worker from "../../assets/img/icons/worker.jpeg";
import useAuth from "../../hooks/useAuth";
import GoogleButton from "react-google-button";

const SignInPage = () => {
  const navigate = useNavigate();
  const { signIn, signInWithGoogle, signInWithApple } = useAuth();

  // if (isAuthenticated) {
  //   navigate("/dashboard");
  // }

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithGoogle();
      navigate("/customer");
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleAppleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithApple();
      navigate("/customer");
    } catch (error) {
      console.log(error.message);
    }
  };
  console.log("Signup");
  return (
    <React.Fragment>
      <Helmet title="Sign In" />
      {/* <div className="text-center mt-4">
        <h2>Welcome back to BestMate</h2>
        <p className="lead">Sign in to your account to continue</p>
      </div>
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center">
              <img
                className="img-fluid rounded-circle"
                src={icon}
                alt="BestMate"
                width="132"
                height="132"
              />
            </div>
            <SignIn />
          </div>
        </Card.Body>
      </Card> */}
      <div
        id="authheader"
        style={{
          width: "100%",
          // height: "80px",
          backgroundColor: "white",
          borderBottom: "1px solid #ebebeb",
          // boxShadow: "0px 45px 10px -15px #111",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
            maxWidth: "820px",
            // maxWidth: "1050px",
            margin: "auto",
            paddingRight: 20,
            paddingLeft: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 0,
              marginTop: 4,
            }}
          >
            <img
              src={icon}
              alt="BestMate"
              id="authheaderlogo"
              style={{ alignSelf: "center" }}
            />
            <strong id="authheadertext" style={{ fontWeight: "bolder" }}>
              BESTMATE
            </strong>
          </div>
          {/* <img src={icon} alt="BestMate" id="authheaderlogo" /> */}
          <a
            style={{
              color: "black",
              fontSize: "16px",
              margin: 0,
              fontWeight: "400",
            }}
            href="mailto:support@bestmate.us"
          >
            Help
          </a>
        </div>
      </div>
      {/* <div style={{ backgroundColor: "#f3f3f3" }}> */}
      <Row
        style={{
          marginRight: 0,
          paddingTop: 20,
          // paddingBottom: 20,
          backgroundColor: "white",
          // backgroundColor: "#f3f3f3",
          // height: "100vh",
          minHeight: "calc(100vh - 80px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Col
          sm={6}
          // id="leftsigninbox"
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 0,
            borderRight: "1px solid grey",
            // borderRight: "1px solid #ebebeb",
          }}
        >
          {/* <div id="leftsigninbox" style={{ width: "100%" }}> */}
          <div
            style={{
              // height: 500,
              maxWidth: 500,
              width: "100%",
              // backgroundColor: "pink",
              padding: "30px 60px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                // justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <h2 style={{ fontWeight: "bold", color: "black" }}>Login</h2>
                <p style={{ margin: 0, color: "black", fontSize: 15 }}>
                  Hi, Welcome back &#128075;
                </p>
              </div>

              {/* <Link
                to="/sign-up"
                style={{
                  fontSize: 16,
                  color: "#75a8fa",
                  fontWeight: "bold",
                }}
              >
                Sign up
              </Link> */}
            </div>
            <button
              style={{
                width: "100%",
                border: "1px solid #c4c4c2",
                padding: 10,
                backgroundColor: "transparent",
                fontWeight: "500",
                marginTop: 20,
                borderRadius: 10,
              }}
              onClick={handleGoogleSignIn}
            >
              <img
                src={google}
                alt="google"
                style={{ height: 20, marginRight: 5 }}
              />
              Sign in with Google
            </button>
            <button
              style={{
                width: "100%",
                border: "1px solid #c4c4c2",
                padding: 10,
                backgroundColor: "transparent",
                fontWeight: "500",
                marginTop: 10,
                borderRadius: 10,
              }}
              onClick={handleAppleSignIn}
            >
              <img
                src={apple}
                alt="apple"
                style={{ height: 20, marginRight: 8 }}
              />
              Sign in with Apple
            </button>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <hr style={{ width: "45%" }} />
              <p style={{ margin: 0 }}>OR</p>
              <hr style={{ width: "45%" }} />
            </div>
            <div style={{ marginTop: 20 }}>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                  submit: false,
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Must be a valid email")
                    .max(255)
                    .required("Email is required"),
                  password: Yup.string()
                    .max(255)
                    .required("Password is required"),
                })}
                onSubmit={async (
                  values,
                  { setErrors, setStatus, setSubmitting }
                ) => {
                  try {
                    await signIn(values.email, values.password);
                    // navigate("/dashboard", {
                    //   state: { email: values.email },
                    // });
                    navigate("/customer");
                  } catch (error) {
                    console.log(error);
                    if (error.code === "auth/user-not-found") {
                      setErrors({
                        submit:
                          "There is no user record corresponding to this identifier. The user may have been deleted",
                      });
                    } else if (error.code === "auth/network-request-failed") {
                      setErrors({
                        submit:
                          "A network AuthError (such as timeout, interrupted connection or unreachable host) has occurred",
                      });
                    } else if (error.code === "auth/wrong-password") {
                      setErrors({
                        submit:
                          "The password is invalid or the user does not have a password.",
                      });
                    } else if (error.code === "auth/too-many-requests") {
                      setErrors({
                        submit:
                          "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
                      });
                    } else {
                      setErrors({ submit: "Something went wrong" });
                    }
                    setStatus({ success: false });
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {errors.submit && (
                      <Alert className="my-3" variant="danger">
                        <div className="alert-message">{errors.submit}</div>
                      </Alert>
                    )}

                    {/* <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        size="lg"
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                        value={values.email}
                        isInvalid={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.email && (
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group> */}
                    {/* <FloatingLabel
                      controlId="floatingInput"
                      label="Email"
                      className="mb-3"
                    > */}
                    <Form.Group style={{ marginBottom: 10 }}>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        size="lg"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        isInvalid={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        style={{
                          borderColor: "black",
                          borderRadius: 10,
                          borderColor: "#c4c4c2",
                        }}
                      />
                      {!!touched.email && (
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                    {/* </FloatingLabel> */}
                    {/* <FloatingLabel
                      controlId="floatingInput"
                      label="Password"
                      className="mb-3"
                    > */}
                    <Form.Group style={{ marginBottom: 10 }}>
                      {" "}
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        size="lg"
                        type="password"
                        name="password"
                        placeholder="Password"
                        value={values.password}
                        isInvalid={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        style={{
                          borderColor: "black",
                          borderRadius: 10,
                          borderColor: "#c4c4c2",
                        }}
                      />
                      {!!touched.password && (
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    {/* </FloatingLabel> */}

                    {/* <Form.Group className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        size="lg"
                        type="password"
                        name="password"
                        placeholder="Enter your password"
                        value={values.password}
                        isInvalid={Boolean(touched.password && errors.password)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {!!touched.password && (
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      )}
                      <small>
                        <Link to="/reset-password">Forgot password?</Link>
                      </small>
                    </Form.Group> */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Form.Check
                        type="checkbox"
                        id="rememberMe"
                        label="Remember me"
                        defaultChecked
                      />
                      <p style={{ fontSize: 13, marginBottom: 5 }}>
                        <Link to="/reset-password">Forgot password?</Link>
                      </p>
                    </div>

                    <div className="text-center mt-3">
                      <Button
                        type="submit"
                        variant="primary"
                        size="lg"
                        disabled={isSubmitting}
                        style={{
                          width: "100%",
                          borderRadius: 10,
                        }}
                      >
                        Sign in
                      </Button>
                      <p style={{ margin: 0, marginTop: 10, fontSize: 11 }}>
                        By clicking "Sign in" you agree to Bestmate's Terms of
                        Service and Privacy Policy
                      </p>
                    </div>

                    {/* <div align="center">
                      <GoogleButton
                        className="g-btn"
                        type="dark"
                        onClick={handleGoogleSignIn}
                      />
                    </div> */}
                  </Form>
                )}
              </Formik>
              <p className="mt-3" style={{ textAlign: "center" }}>
                {/* Don't have an account ? <Link to="/sign-up">Sign up</Link> */}
              </p>
            </div>
            {/* </div> */}
          </div>
        </Col>
        <Col
          sm={6}
          style={{
            display: "flex",
            justifyContent: "center",
            padding: 0,
          }}
        >
          <div
            style={{
              // height: 500,
              maxWidth: 500,
              width: "100%",
              paddingRight: 20,
              paddingLeft: 20,
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              flexDirection: "column",
              // backgroundColor: "red",
            }}
          >
            <img
              src={worker}
              alt="BestMate"
              style={{ width: "70%", alignSelf: "center" }}
            />
            {/* <h4 style={{ color: "black", marginTop: 30 }}>
              Manage your business with Bestmate
            </h4>
            <p style={{ color: "black", marginTop: 0 }}>
              Bestmate all-in-one app powers your entire business. Invoice,
              Estimate, Schedules, Payments, Inventory, & Customers Management
              (CRM)
            </p> */}
            <div style={{ textAlign: "center", marginTop: 30 }}>
              <h2 style={{ fontSize: "2rem", color: "black" }}>
                Manage. Organize.
              </h2>
              <h2 style={{ fontSize: "2rem", color: "#086AD8" }}>
                Grow your Business.
              </h2>
              <p style={{ color: "black", marginTop: 20 }}>
                Invoices | Estimates | Online Payments | Scheduling & Bookings |
                Manage Customers & Suppliers | Inventory
              </p>
            </div>
          </div>
        </Col>
      </Row>
      {/* </div> */}
    </React.Fragment>
  );
};

export default SignInPage;
