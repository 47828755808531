import React, { useMemo, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useTable, useGlobalFilter, useSortBy, useSticky } from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import { useEffect, useState } from "react";
import { ArrowRight, Mail, Phone, Plus, PlusSquare, X } from "react-feather";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import useAuth from "../../hooks/useAuth";
// import InputComponent from "./InputComponentPartner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Breadcrumb,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Row,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import NotyfContext from "../../contexts/NotyfContext";
import useSidebar from "../../hooks/useSidebar";
import empty_partner from "../../assets/img/icons/empty_partner.png";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "../../assets/css/App.css";

const buttonBackgroundColor = "#E1E5EB";
const buttonTextColor = "#484F7C";

const FilteringTable = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openModals, setOpenModals] = useState(false);
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();
  const [deleteData, setDeletedata] = useState({});
  const [nothavemodal, setNothavemodal] = useState(false);
  const [nothavetype, setNothavetype] = useState("Phone");

  console.log(location);

  let usercurrency = localStorage.getItem("currency") || "USD";

  const COLUMNS = [
    {
      id: "namefirst",
      Header: "Name",
      width: 150,
      accessor: `business_user_name`,
      disableSortBy: true,
    },
    {
      id: "businessfirst",

      Header: "Business",
      // width: 230,
      width: 150,

      accessor: `business_name`,
      disableSortBy: true,
    },
    {
      Header: "City",
      width: 80,

      // width: 230,
      accessor: `$city`,
      disableSortBy: true,
    },
    {
      Header: "Country",
      width: 50,

      // width: 230,
      accessor: `$country_code`,
      disableSortBy: true,
    },
    {
      Header: "Use",
      // width: 230,
      width: 90,

      accessor: `business_use_case`,
      disableSortBy: true,
    },
    {
      Header: "Industry",
      // width: 230,
      width: 90,

      accessor: `business_industry`,
      disableSortBy: true,
    },

    {
      Header: "Phone",
      // width: 230,
      width: 110,
      // maxWidth: 80,
      /*Cell: ({ value }) => {
        if (value && value.startsWith("+")) {
          value = value.substring(1); // Remove the "+" character
        }
        if (value) {
          return (
            <div
              style={{
                color: buttonTextColor,
                fontSize: 9,
                padding: 0,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: buttonBackgroundColor,
                borderRadius: 5,
                fontWeight: "bold",
              }}
            >
              {value}
            </div>)
        } else {
          return "";
        }
      },*/
      accessor: `business_phone`,
      disableSortBy: true,
    },

    {
      Header: "Ses.",
      // width: 230,
      width: 60,

      accessor: `$ae_total_app_sessions`,
      disableSortBy: false,
      Cell: ({ value }) => {
        if (value) {
          return (
            <div
              style={{
                color: buttonTextColor,
                fontSize: 9,
                padding: 0,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: buttonBackgroundColor,
                borderRadius: 5,
                fontWeight: "bold",
              }}
            >
              {value}
            </div>
          );
        } else {
          return "";
        }
      },
    },

    {
      Header: "Work",
      // width: 230,
      width: 70,

      accessor: `business_workflows`,
      disableSortBy: false,
      Cell: ({ value }) => {
        if (value) {
          return (
            <div
              style={{
                color: buttonTextColor,
                fontSize: 9,
                padding: 0,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: buttonBackgroundColor,
                borderRadius: 5,
                fontWeight: "bold",
              }}
            >
              {value}
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      Header: "Plat.",
      // width: 230,
      width: 80,

      accessor: `business_platform`,
      disableSortBy: true,
      Cell: ({ value }) => {
        if (value) {
          return (
            <div
              style={{
                color: buttonTextColor,
                fontSize: 9,
                padding: 0,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: buttonBackgroundColor,
                borderRadius: 5,
                fontWeight: "bold",
              }}
            >
              {value}
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      Header: "Crea.",
      accessor: "business_created_at_time",
      sortType: "basic",
      width: 70,

      Cell: ({ value }) => {
        if (value) {
          const now = moment();
          const createdAt = moment(new Date(value));
          const diffInMinutes = now.diff(createdAt, "minutes");
          const diffInHours = now.diff(createdAt, "hours");
          const diffInDays = now.diff(createdAt, "days");

          if (diffInDays > 0) {
            return (
              <div
                style={{
                  color: buttonTextColor,
                  fontSize: 9,
                  padding: 0,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  backgroundColor: buttonBackgroundColor,
                  borderRadius: 5,
                  fontWeight: "bold",
                }}
              >
                {diffInDays} day
              </div>
            );
          } else if (diffInHours > 0) {
            return (
              <div
                style={{
                  color: buttonTextColor,
                  fontSize: 9,
                  padding: 0,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  backgroundColor: buttonBackgroundColor,
                  borderRadius: 5,
                  fontWeight: "bold",
                }}
              >
                {diffInHours} hr
              </div>
            );
          } else if (diffInMinutes > 0) {
            return (
              <div
                style={{
                  color: buttonTextColor,
                  fontSize: 9,
                  padding: 0,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  backgroundColor: buttonBackgroundColor,
                  borderRadius: 5,
                  fontWeight: "bold",
                }}
              >
                {diffInMinutes} min
              </div>
            );
          } else {
            return (
              <div
                style={{
                  color: buttonTextColor,
                  fontSize: 9,
                  padding: 0,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  backgroundColor: buttonBackgroundColor,
                  borderRadius: 5,
                  fontWeight: "bold",
                }}
              >
                Just now
              </div>
            );
          }
        } else {
          return "";
        }
      },
    },
    {
      Header: "Upda.",
      width: 70,

      accessor: "business_updated_at_time",
      sortType: "basic",
      Cell: ({ value }) => {
        if (value) {
          const now = moment();
          const updatedAt = moment(new Date(value));
          const diffInMinutes = now.diff(updatedAt, "minutes");
          const diffInHours = now.diff(updatedAt, "hours");
          const diffInDays = now.diff(updatedAt, "days");

          if (diffInDays > 0) {
            return (
              <div
                style={{
                  color: buttonTextColor,
                  fontSize: 9,
                  padding: 0,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  backgroundColor: buttonBackgroundColor,
                  borderRadius: 5,
                  fontWeight: "bold",
                }}
              >
                {diffInDays} day
              </div>
            );
          } else if (diffInHours > 0) {
            return (
              <div
                style={{
                  color: buttonTextColor,
                  fontSize: 9,
                  padding: 0,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  backgroundColor: buttonBackgroundColor,
                  borderRadius: 5,
                  fontWeight: "bold",
                }}
              >
                {diffInHours} hr
              </div>
            );
          } else if (diffInMinutes > 0) {
            return (
              <div
                style={{
                  color: buttonTextColor,
                  fontSize: 9,
                  padding: 0,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  backgroundColor: buttonBackgroundColor,
                  borderRadius: 5,
                  fontWeight: "bold",
                }}
              >
                {diffInMinutes} min
              </div>
            );
          } else {
            return (
              <div
                style={{
                  color: buttonTextColor,
                  fontSize: 9,
                  padding: 0,
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  backgroundColor: buttonBackgroundColor,
                  borderRadius: 5,
                  fontWeight: "bold",
                }}
              >
                Just now
              </div>
            );
          }
        } else {
          return "";
        }
      },
    },

    // {
    //   Header: "Region",
    //   width: 200,

    //   // width: 230,
    //   accessor: `$region`,
    //   disableSortBy: true,
    // },
    {
      Header: "Unres.",
      // width: 500,
      width: 70,

      accessor: `latest_unresponded_message`,
      sortType: "basic",
      Cell: ({ value }) => {
        if (value === -1) {
          return "";
        } else {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
                backgroundColor: "#f0f0f0",
                color: "#666769",
                borderRadius: 5,
                fontSize: 9,
              }}
            >{`${moment(new Date(value)).format("MMM DD")}`}</div>
          );
        }
      },
    },
    {
      Header: "Sent",
      // width: 1000,
      width: 70,

      accessor: `last_sent_message`,
      sortType: "basic",
      Cell: ({ value }) => {
        if (value === -1) {
          return "";
        } else {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: 0,
                backgroundColor: "#dcfae1",
                color: "#65a370",
                borderRadius: 5,
                fontSize: 9,
              }}
            >{`${moment(new Date(value)).format("MMM DD")}`}</div>
          );
        }
      },
    },
    {
      Header: "Subscip.",
      width: 70,
      accessor: "Subscription",
      // sortType: "basic",
      disableSortBy: true,
      Cell: ({ row }) => {
        console.log(row);
        if (
          (row.original.business_play_billing &&
            row.original.business_play_billing.is_premium) ||
          (row.original.business_ios_billing &&
            row.original.business_ios_billing.is_premium)
        ) {
          return (
            <div
              style={{
                color: "white",
                fontSize: 9,
                padding: 0,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: "#3F9937",
                borderRadius: 5,
                fontWeight: "bold",
              }}
            >
            {
            row.original.business_play_billing && row.original.business_play_billing.is_premium 
            ? 
            (
              (
                row.original.business_play_billing.product_id === "android.starter_tier"
                 || row.original.business_play_billing.product_id === "android.starter"
              )
                ? "Starter" 
                : "Android Paid"
            ) 
            : 
            (
              row.original.business_ios_billing.transaction_product_id === "starter_plan" 
                ? "Starter" 
                : (row.original.business_ios_billing.transaction_product_id === "pro_plan" 
                  ? "Pro"
                  : (
                    row.original.business_ios_billing.transaction_product_id === "basic_plan" 
                    ? "Basic"
                    : "iOS"
                    )
                  )
            ) 
            }
            </div>
          );
        } else if (
          (row.original.business_play_billing &&
            row.original.business_play_billing.product_id) ||
          (row.original.business_ios_billing &&
            row.original.business_ios_billing.transaction_product_id)
        ) {
          return (
            <div
              style={{
                color: "white",
                fontSize: 9,
                padding: 0,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: "#C94848",
                borderRadius: 5,
                fontWeight: "bold",
              }}
            >
              Cancelled
            </div>
          );
        } else {
          return (
            <div
              style={{
                color: "#395a96",
                fontSize: 9,
                padding: 0,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: "#d7e4fc",
                borderRadius: 5,
                fontWeight: "bold",
              }}
            >
              Free
            </div>
          );
        }
      },
    },
    {
      Header: "Mixpanel",
      width: 50,

      // width: 500,
      disableSortBy: true,
      accessor: `mixpanel_link`,
      Cell: ({ value }) => {
        if (value) {
          return (
            <div
              style={{
                color: buttonTextColor,
                fontSize: 9,
                padding: 0,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: buttonBackgroundColor,
                borderRadius: 5,
                fontWeight: "bold",
              }}
              onClick={(e) => {
                e.stopPropagation();
                window.open(value, "_blank");
              }}
            >
              Link
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      Header: "Email",
      // width: 230,
      width: 180,

      accessor: `business_email`,
      disableSortBy: true,
    },
    {
      Header: "Referrer",
      width: 330,
      //width: 180,

      accessor: `business_referrer`,
      disableSortBy: true,
    },
    {
      Header: "Campaign",
      width: 330,
      //width: 180,

      accessor: `business_referrer_campaign`,
      disableSortBy: true,
    },
    {
      Header: "MMP",
      width: 330,
      accessor: `business_referrer_mmp_combined`,
      disableSortBy: false,
    },
    {
      Header: "Referrer U",
      width: 330,
      //width: 180,

      accessor: `business_referrer_unparsed`,
      disableSortBy: true,
    },
    {
      Header: "MMP",
      width: 330,
      //width: 180,

      accessor: `business_referrer_mmp_unparsed`,
      disableSortBy: true,
    },
    {
      Header: "MMP O.",
      width: 330,
      //width: 180,

      accessor: `business_referrer_mmp_organic_unparsed`,
      disableSortBy: true,
    },
    {
      Header: "Name",
      id: "namelast",

      width: 150,
      accessor: `business_user_name`,
      disableSortBy: true,
    },
    {
      Header: "Business",
      id: "businesslast",

      // width: 230,
      width: 150,

      accessor: `business_name`,
      disableSortBy: true,
    },
    // {
    //   // Header: "Actions",
    //   Header: () => (
    //     <div
    //       style={{
    //         textAlign: "center",
    //       }}
    //     >
    //       Actions
    //     </div>
    //   ),
    //   width: 50,
    //   accessor: "actions",
    //   disableSortBy: true,
    //   Cell: ({ row }) => {
    //     return (
    //       <div style={{ display: "flex", justifyContent: "end" }}>
    //         <ButtonGroup>
    //           <Button
    //             size="sm"
    //             variant="light"
    //             style={{ backgroundColor: "white" }}
    //             className="border"
    //             onClick={(e) => {
    //               e.stopPropagation();
    //               navigate("/customer/" + row.original.business_id);
    //             }}
    //           >
    //             View
    //           </Button>
    //         </ButtonGroup>
    //       </div>
    //     );
    //   },
    // },
  ];

  const columns = useMemo(() => COLUMNS, []);
  const data = props.data;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
    // useSticky
  );
  const { globalFilter } = state;
  return (
    <>
      <InfiniteScroll
        dataLength={rows.length}
        next={() => props.update(props.token, props.id)}
        hasMore={props.hasmore}
        style={{ overflowX: "auto" }}
        loader={
          <div className="d-flex justify-content-center">
            <Spinner
              animation="border"
              variant="dark"
              size="sm"
              className="me-2"
            />
          </div>
        }
      >
        {/* <div style={{ overflowX: "auto" }}> */}
        <Table
          striped
          bordered
          size="sm"
          // className="sticky-table"

          {...getTableProps()}
          style={{ color: "grey" }}
        >
          <thead
            style={{
              color: "black",
              backgroundColor: "#F2f2f4",
              fontSize: 11,
              // position: "sticky",
              // top: 0,
            }}
          >
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getFooterGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ fontSize: 12, backgroundColor: "#f2f2f4" }}
                    className="table-cell"
                  >
                    {column.render("Header")}
                    {!column.disableSortBy && (
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <FontAwesomeIcon icon={faSortUp} className="ms-2" />
                          ) : (
                            <FontAwesomeIcon
                              icon={faSortDown}
                              className="ms-2"
                            />
                          )
                        ) : (
                          <FontAwesomeIcon icon={faSort} className="ms-2" />
                        )}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ borderTop: 0 }}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  onClick={() => {
                    console.log(location);
                    // navigate("/customer/" + row.original.business_id);
                    window.open(
                      `/customer/${row.original.business_id}`,
                      "_blank"
                      // "rel=noopener noreferrer"
                    );
                  }}
                  style={{ cursor: "pointer" }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          maxWidth: cell.column.width,
                          minWidth: cell.column.width,
                          // maxWidth: cell.column.maxWidth,
                          color: "black",
                          fontSize: "12px",
                          padding: 8,
                          // backgroundColor: "pink",
                          // overflow: "auto",
                          // overflow: "scroll",
                          overflowX: "auto",
                          whiteSpace: "nowrap",
                        }}
                        className="scroll-container table-cell"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {/* </div> */}
      </InfiniteScroll>
      {/* <Modal
        show={openModals}
        onHide={() => setOpenModals(!openModals)}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Delete Customer
            </h1>
            <div
              onClick={() => {
                setOpenModals(!openModals);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                // flexDirection: "column",
                // alignItems: "center",
              }}
            >
              <p style={{ margin: "15px 0px 20px", textAlign: "center" }}>
                Are you sure you want to delete this customer?
              </p>
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "12px 0",
              }}
            >
              <button
                type="button"
                style={{
                  marginRight: 5,
                  padding: "5px 10px",
                  backgroundColor: "white",
                  borderWidth: 1,
                }}
                onClick={() => setOpenModals(!openModals)}
              >
                Cancel
              </button>
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                onClick={() => {
                  setOpenModals(!openModals);
                  // props.deleteRecord(deleteData);
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
      {/* <Modal
        show={nothavemodal}
        onHide={() => setNothavemodal(false)}
        centered
        size="sm"
      >
        <Modal.Body style={{ padding: 0 }}>
          <div
            style={{
              width: "100%",
              backgroundColor: "#f4f4f4",
              height: 46,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 24px",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
                fontSize: 18,
                color: "black",
                margin: 0,
              }}
            >
              Not Available
            </h1>
            <div
              onClick={() => {
                setNothavemodal(false);
              }}
            >
              <X
                size={24}
                strokeWidth={2}
                stroke="black"
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div style={{ padding: "10px 24px 0 24px" }}>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                // flexDirection: "column",
                // alignItems: "center",
              }}
            >
              <p style={{ margin: "15px 0 20px" }}>
                {nothavetype === "Phone"
                  ? " No phone number has been added. Add a new phone number from the customer page."
                  : " No Email has been added. Add a new email address from the customer page."}
              </p>
            </div>

            <hr style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                margin: "12px 0",
              }}
            >
              <button
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#3679e8",
                  borderWidth: 0,
                  color: "white",
                  width: 80,
                }}
                onClick={() => {
                  setNothavemodal(false);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

const Customer = () => {
  const [token, settoken] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [searchtable, showSearchtable] = useState(false);
  const [searchdata, setSearchdata] = useState([]);
  const [searchname, setSearchname] = useState("");
  const [searchcont, setSearchcont] = useState("");
  const [searchout, setSearchout] = useState("");
  const [searchphone, setSearchphone] = useState("");
  const [searchwork, setSearchwork] = useState("");
  const [searchurm, setSearchurm] = useState("");
  const [searchcust, setSearchcust] = useState("");
  const [searchplat, setSearchplat] = useState("");
  const [searchref, setSearchref] = useState("");
  const [id, setId] = useState("");
  const [hasmore, sethasmore] = useState(true);
  const navigate = useNavigate();
  const notyf = useContext(NotyfContext);
  const { isOpen } = useSidebar();

  // const deleteRecord = async (deleteData) => {
  //   var user = firebase.auth().currentUser;

  //   if (user) {
  //     let tokenss = await user.getIdToken();
  //     fetch("https://bestmate.us/api/partners/delete", {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + tokenss,
  //       },
  //       body: JSON.stringify(deleteData),
  //     })
  //       .then((response) => {
  //         console.log("response", response);
  //         // navigate("/customer");
  //         notyf.open({
  //           type: isOpen ? "home" : "full",
  //           message: "Customer deleted successfully",
  //           ripple: "true",
  //           dismissible: "true",
  //         });
  //         setLoading(true);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   }
  //   // });
  //   // setShow(false);
  // };

  const getData = (token, signal) => {
    const response = fetch("https://bestmate.us/crmPublic/getAllInvoices", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }).then((res) => {
      console.log("Here - %s", JSON.stringify(res.json()));
      fetch("https://bestmate.us/api/crm/customers", {
        method: "GET",
        signal,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            setData(result);
            setLoading(false);
            sethasmore(false);
          },
          (error) => {
            console.error("Error fetching data: ", error);
          }
        );
    });
  };

  function searchnow(
    searchname,
    searchcont,
    searchout,
    searchurm,
    searchcust,
    searchphone,
    searchwork,
    searchplat,
    searchref
  ) {
    let result = data;
    if (searchname !== "") {
      result = result.filter((elem) => {
        if (Object.keys(elem).includes("business_user_name")) {
          return elem.business_user_name
            .toLowerCase()
            .includes(searchname.toLowerCase());
        }
      });
    }

    if (searchcont !== "") {
      result = result.filter((elem) => {
        if (Object.keys(elem).includes("business_name")) {
          return elem.business_name
            .toLowerCase()
            .includes(searchcont.toLowerCase());
        }
      });
    }

    if (searchout !== "") {
      result = result.filter((elem) => {
        if (Object.keys(elem).includes("business_email")) {
          return elem.business_email
            .toLowerCase()
            .includes(searchout.toLowerCase());
        }
      });
    }
    if (searchphone !== "") {
      result = result.filter((elem) => {
        if (Object.keys(elem).includes("business_phone")) {
          return elem.business_phone
            .toLowerCase()
            .includes(searchphone.toLowerCase());
        }
      });
    }
    if (searchurm !== "") {
      result = result.filter((elem) => {
        if (Object.keys(elem).includes("latest_unresponded_message")) {
          if (searchurm === "DHUM") {
            return elem.latest_unresponded_message === -1;
          } else if (searchurm === "HUM") {
            return elem.latest_unresponded_message !== -1;
          }
        }
      });
    }
    if (searchcust !== "") {
      result = result.filter((elem) => {
        if (
          Object.keys(elem).includes("business_play_billing") ||
          Object.keys(elem).includes("business_ios_billing")
        ) {
          if (searchcust === "SUBS") {
            return (
              (elem.business_play_billing &&
                elem.business_play_billing.is_premium) ||
              (elem.business_ios_billing &&
                elem.business_ios_billing.is_premium)
            );
          } else if (searchcust === "CANC") {
            return (
              (elem.business_play_billing &&
                elem.business_play_billing.product_id) ||
              (elem.business_ios_billing &&
                elem.business_ios_billing.transaction_product_id)
            );
          }
        }
      });
    }
    if (searchwork !== "") {
      result = result.filter((elem) => {
        if (Object.keys(elem).includes("business_workflows")) {
          // if (searchurm === "DHUM") {
          //   return elem.latest_unresponded_message === -1;
          // } else if (searchurm === "HUM") {
          //   return elem.latest_unresponded_message !== -1;
          // }
          return elem.business_workflows > searchwork;
        }
      });
    }
    if (searchplat !== "") {
      result = result.filter((elem) => {
        if (Object.keys(elem).includes("business_platform")) {
          return elem.business_platform
            .toLowerCase()
            .includes(searchplat.toLowerCase());
        }
      });
    }
    if(searchref != "") {
      result = result.filter((elem) => {
        if (Object.keys(elem).includes("business_referrer")) {
          return elem.business_referrer
            .toLowerCase()
            .includes(searchref.toLowerCase());
        }
      });
    }

    setSearchdata(result);
  }

  useEffect(() => {
    showSearchtable(false);

    if (
      searchname !== "" ||
      searchcont !== "" ||
      searchout !== "" ||
      searchphone !== "" ||
      searchurm !== "" ||
      searchcust !== "" ||
      searchwork !== "" ||
      searchplat !== "" ||
      searchref != ""
    ) {
      console.log("calling again");
      showSearchtable(true);
      searchnow(
        searchname,
        searchcont,
        searchout,
        searchurm,
        searchcust,
        searchphone,
        searchwork,
        searchplat,
        searchref
      );
    }
  }, [
    searchname,
    searchcont,
    searchout,
    searchurm,
    searchcust,
    searchphone,
    searchwork,
    searchplat,
    searchref
  ]);

  useEffect(async () => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    var user = firebase.auth().currentUser;

    // firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      let tokenss = await user.getIdToken();
      settoken(tokenss);
      setData([]);
      setId("");
      sethasmore(true);
      showSearchtable(false);
      setSearchdata([]);
      setSearchname("");
      setSearchcont("");
      setSearchout("");
      setSearchphone("");
      setSearchwork("");
      setSearchurm("");
      setSearchcust("");
      setSearchplat("");
      setSearchref("");
      getData(tokenss, signal);
    }
    // });
    return () => {
      console.log("Sf");
      abortController.abort();
    };
  }, []);

  console.log(data, searchdata);

  return (
    <React.Fragment>
      <Helmet title="Customer" />
      <Container fluid className="p-0">
        <Card
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0rem 1.5rem 2rem 1.5rem",
          }}
          className="pt-2 pb-2 border-bottom "
        >
          <div>
            <h2
              className="mt-3 lead"
              style={{
                fontSize: "24px",
              }}
            >
              Customers
            </h2>
            <Breadcrumb className="mb-0 lead" style={{ fontSize: "13px" }}>
              <Breadcrumb.Item active>CRM</Breadcrumb.Item>
              <Breadcrumb.Item active className="font-weight-bold">
                Customers
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Card>
        <div
          style={{
            padding: "0rem 1.5rem 2rem 1.5rem",
          }}
        >
          <Row>
            <Col>
              <Card className="mb-2 border rounded-0">
                <Card.Header>
                  <Form>
                    <Row>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label> Name</Form.Label>
                          <Form.Control
                            type="text"
                            // name="email"
                            value={searchname}
                            autoComplete="none"
                            onChange={(e) => setSearchname(e.target.value)}
                            placeholder="Name"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>Business Name</Form.Label>
                          <Form.Control
                            type="text"
                            // name="email"
                            autoComplete="none"
                            value={searchcont}
                            onChange={(e) => setSearchcont(e.target.value)}
                            placeholder="Business Name"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label> Email</Form.Label>
                          <Form.Control
                            type="text"
                            autoComplete="none"
                            // name="email"
                            value={searchout}
                            onChange={(e) => setSearchout(e.target.value)}
                            placeholder="Email"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={1}>
                        <Form.Group className="mb-3">
                          <Form.Label> Phone</Form.Label>
                          <Form.Control
                            type="text"
                            autoComplete="none"
                            // name="email"
                            value={searchphone}
                            onChange={(e) => setSearchphone(e.target.value)}
                            placeholder="Phone"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={1}>
                        <Form.Group className="mb-3">
                          <Form.Label>Platform</Form.Label>

                          <Form.Select
                            value={searchplat}
                            onChange={(e) => setSearchplat(e.target.value)}
                          >
                            <option value="">All</option>
                            <option value="ios">ios</option>
                            <option value="android">android</option>
                            <option value="web">web</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={1}>
                        <Form.Group className="mb-3">
                          <Form.Label>Referrer</Form.Label>

                          <Form.Select
                            value={searchref}
                            onChange={(e) => setSearchref(e.target.value)}
                          >
                            <option value="">All</option>
                            <option value="apple search ads">Apple Search Ads</option>
                            <option value="facebook">Facebook</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={1}>
                        <Form.Group className="mb-3">
                          <Form.Label> Work</Form.Label>
                          <Form.Control
                            type="number"
                            autoComplete="none"
                            // name="email"
                            value={searchwork}
                            onChange={(e) => setSearchwork(e.target.value)}
                            placeholder="Work"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        {/* <Form.Group className="mb-3">
                          <Form.Label>Unresponded Message Type</Form.Label>

                          <Form.Select
                            value={searchurm}
                            onChange={(e) => setSearchurm(e.target.value)}
                          >
                            <option value="">All</option>
                            <option value="HUM">
                              Have Unresponded Message
                            </option>
                            <option value="DHUM">
                              Don't have Unresponded Message
                            </option>
                          </Form.Select>
                        </Form.Group> */}
                        {searchurm ? (
                          <Button
                            style={{
                              width: "100%",
                              textAlign: "center",
                              margin: 5,
                            }}
                            onClick={() => setSearchurm("")}
                          >
                            See All Customers
                          </Button>
                        ) : (
                          <Button
                            style={{
                              width: "100%",
                              textAlign: "center",
                              margin: 5,
                            }}
                            onClick={() => setSearchurm("HUM")}
                          >
                            See Unresponded Customers
                          </Button>
                        )}
                        {searchcust === "" ? (
                          <Button
                            style={{
                              width: "100%",
                              textAlign: "center",
                              margin: 5,
                            }}
                            onClick={() => setSearchcust("SUBS")}
                          >
                            Show Subcribers
                          </Button>
                        ) : searchcust === "SUBS" ? (
                          <Button
                            style={{
                              width: "100%",
                              textAlign: "center",
                              margin: 5,
                            }}
                            onClick={() => setSearchcust("CANC")}
                          >
                            Show Cancelled Customer
                          </Button>
                        ) : (
                          <Button
                            style={{
                              width: "100%",
                              textAlign: "center",
                              margin: 5,
                            }}
                            onClick={() => setSearchcust("")}
                          >
                            Show All Customer
                          </Button>
                        )}
                      </Col>
                      <Col
                        md={1}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* <Button
                          onClick={() => {
                            setSearchcont("");
                            setSearchname("");
                            setSearchout("");
                            setSearchurm("");
                            setSearchphone("");
                            // searchname, searchcont, searchout, searchurm, searchphone
                          }}
                          variant="primary"
                          style={{ backgroundColor: "#405189", marginTop: 11 }}
                        >
                          Clear
                        </Button> */}
                        <div
                          onClick={() => {
                            setSearchcont("");
                            setSearchname("");
                            setSearchout("");
                            setSearchurm("");
                            setSearchcust("");
                            setSearchphone("");
                            setSearchwork("");
                            setSearchplat("");
                            setSearchref("");
                            // searchname, searchcont, searchout, searchurm, searchphone
                          }}
                          style={{
                            backgroundColor: "#5c71b5",
                            marginTop: 11,
                            color: "white",
                            cursor: "pointer",
                            padding: "5px 15px",
                            borderRadius: 5,
                          }}
                        >
                          Clear
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Header>
              </Card>
              <Row style={{}}>
                <Col lg="12">
                  <Card
                    className="mb-2 border rounded-0"
                    style={{ position: "sticky", top: 0 }}
                  >
                    <Card.Header>
                      {loading ? (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ height: "200px" }}
                        >
                          <Spinner
                            animation="border"
                            variant="dark"
                            className="me-2"
                          />
                        </div>
                      ) : (
                        <>
                          {!searchtable ? (
                            <FilteringTable
                              data={data}
                              // show={setShow}
                              token={token}
                              update={getData}
                              hasmore={hasmore}
                              id={id}
                              // deleteRecord={deleteRecord}
                            />
                          ) : (
                            <FilteringTable
                              data={searchdata}
                              // show={setShow}
                              token={token}
                              update={getData}
                              hasmore={hasmore}
                              id={id}
                              // deleteRecord={deleteRecord}
                            />
                          )}
                        </>
                      )}
                    </Card.Header>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
    // <>
    //   <div>ed</div>
    // </>
  );
};

export default Customer;
