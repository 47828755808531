import React from "react";

const EmailTemplate = (props) => {
  const selected = props.email;
  const email_3 = `<div>
  <div style="background-color:#f9f9f9;margin:0px;padding:20px" bgcolor="f9f9f9">
     <table cellspacing="0" border="0" cellpadding="0" align="center" width="100%" bgcolor="white" style="background-color:white;border-collapse:separate;border-spacing:0;font-family:Helvetica,Arial,sans-serif;letter-spacing:0;table-layout:fixed">
        <tbody>
           <tr>
              <td style="border:1px solid #dddddd;border-radius:2px;font-family:Helvetica,Arial,sans-serif;font-size:16px;padding:40px 60px">
                 <table width="100%" style="border-collapse:separate;border-spacing:0;table-layout:fixed">
                    <tbody>
                       <tr>
                          <td style="font-family:Helvetica,Arial,sans-serif;font-size:16px;padding:0;text-align:left" align="left">
                             <img src="http://bestmate.us/assets/images/logo-3.png" width="200"  style="padding:15px 0 0;text-align:left"><br><br>
                          </td>
                       </tr>
                    </tbody>
                 </table>
                 <table width="100%" style="border-collapse:separate;border-spacing:0;table-layout:fixed">
                    <tbody>
                       <tr>
                          <td style="color:#4d6974;font-family:Helvetica,Arial,sans-serif;font-size:15px;line-height:26px;padding:0">
                             <p style="line-height:1.7;margin:0 0 17px">{{greeting}}</p>
                             <p style="color:#546270;line-height:1.7;margin:0 0 17px">Now that you're up and running, let's use Bestmate to create and send your first estimate to a client.<br></p>
                             <p style="color:#546270;line-height:1.7;margin:0 0 17px">With BestMate, you can create quotes online and even send and track them on the go with our mobile app. Plus, our system is designed to keep everything organized and easily accessible for you at all times.<br></p>
                             <div style="line-height:0;margin-bottom:17px;margin-top:17px"><img src="https://bestmate.us/assets/images/estimates-2.png" width="600" style="margin:0;max-width:100%" tabindex="0"><div style="opacity: 0.01; left: 506px; top: 577.5px;"></div></div>
                             <hr style="border-style:solid none none;border-top-color:#cfcfcf;border-top-width:1px;margin:0px 0%">
                             <!-- Section -->
                             <h2 style="color:#012939;font-size:18px;font-weight:bold;margin-bottom:8px;margin-top:30px"><b>Create your first estimate on BestMate app</b></h2>
                             <p style="line-height:1.4;margin:0 0 10px">1. Click 'Add Estimate' from the '+' sign in the bottom bar.</p>
                             <p style="line-height:1.4;margin:0 0 10px">2. Add the client's name from the list or create a new client.</p>
                             <p style="line-height:1.4;margin:0 0 10px">3. Add a product or service by clicking 'Add Product' or 'Add Service,' which will also be stored for future use in the Product/Service list.</p>
                             <p style="line-height:1.4;margin:0 0 10px">4. Alter prices as needed.</p>
                             <p style="line-height:1.4;margin:0 0 10px">5. Save or send your first estimate.</p>
                             <!-- Section -->
                             <h2 style="color:#012939;font-size:18px;font-weight:bold;margin-bottom:8px;margin-top:20px"><b>Automated quote follow-ups & reminders</b></h2>
                             <p style="line-height:1.8;margin:0 0 10px">Bestmate streamlines the process of obtaining estimate approvals with automated, customized emails to customers for follow-up. It proactively prompts customers to respond to your quotes, saving time and effort.</p>
                             <!-- Section -->
                             <h2 style="color:#012939;font-size:18px;font-weight:bold;margin-bottom:8px;margin-top:20px"><b>Online approvals</b></h2>
                             <p style="line-height:1.8;margin:0 0 20px">Clients can approve or respond to quotes online, saving you time and ensuring you never miss approved quotes.</p>
                             <!-- Section -->
                             <hr style="border-style:solid none none;border-top-color:#cfcfcf;border-top-width:1px;margin-top: 10px;">

                             <table style="border-collapse:collapse;border-spacing:0;margin:17px 0;table-layout:fixed">
                                <tbody>
                                   <tr>
                                      <td style="text-align:left; vertical-align:top; padding-top: 10px;" align="left" valign="top">
                                         <a href="https://apps.apple.com/us/app/bestmate-manage-your-business/id1626461580"> 
                                         <img style="width: 120px;" src="https://ci5.googleusercontent.com/proxy/3NBXKOfbteYtDNK3va8AxIwziNcr3qdzu6oXLRdTUfSrLeN8klr0e3h4N7U3ZHvaWIi0P8Zo7XCg-F9VTzSjncOLQAWMrt3-96yV_zKyQXjyRxJc3AXhYz65slTMBp7zsQElVOPxzEIxQtZ7Sxpra5U=s0-d-e1-ft#https://static.pdffiller.com/mail-templates/img/common/pdffiller-blog/button-apple-blog-2x.png"/></a> 
                                         <a href="https://play.google.com/store/apps/details?id=com.bestmate.pro"> 
                                         <img style="width: 120px; margin-left: 10px;" src="https://ci3.googleusercontent.com/proxy/eT_5EAfPVlHjdev1qxCuoxKFeArbPCgdOR_THuqkpZT5N-IydFomNN5bJKy5TmSt-5VTdB4rAC83hA_UjuJetV_0dhSBx89N3BH_zEe_1I-KBfL9gDhQXZZSiZT3oNK0g1SrYRUQe4kRLT4TpKCtNyiu=s0-d-e1-ft#https://static.pdffiller.com/mail-templates/img/common/pdffiller-blog/button-google-blog-2x.png" /> </a>
                                   </tr>
                                </tbody>
                             </table>
                             <p style="line-height:1.8;margin:0 0 10px"><b>Need assistance?</b> We're here to help. Reach out to us with any questions at +1 (415) 735-6106 or replying to this email, we're happy to assist you.</p>

                             
                             <a href="https://calendly.com/sam-bestmate/30min" style="border:none;color:#1251ba;outline:none!important" target="_blank" data-saferedirecturl="https://calendly.com/sam-bestmate/30min">Book a meeting with Sam</a>
                             <br>
                             <p style="color:#546270;line-height:1.7;margin-top:16px">Best,<br>Sam at <span>BestMate</span><br></p>                              
                          </td>
                       </tr>
                    </tbody>
                 </table>
              </td>
           </tr>
        </tbody>
     </table>
     <table width="100%" align="middle" style="border-collapse:separate;border-spacing:0;table-layout:fixed">
        <tbody>
           <tr>
              <td style="font-family:Helvetica,Arial,sans-serif;font-size:16px;padding:0">
                 <table cellspacing="0" border="0" cellpadding="0" align="center" width="600" bgcolor="transparent" style="border-collapse:separate;border-spacing:0;table-layout:fixed">
                    <tbody>
                       <tr>
                          <td style="font-family:Helvetica,Arial,sans-serif;font-size:16px;padding:21px 30px 15px;text-align:center" align="center">
                             <p style="color:#657884;font-size:12px;font-weight:300;margin:0 0 6px;text-decoration:none">20830 Stevens Creek Blvd #1064 Cupertino, CA 95014 USA.</p>
                             <a style="border:none;color:#657884;font-size:12px;font-weight:300;outline:none!important;text-decoration:none">Unsubscribe from our emails</a>
                          </td>
                       </tr>
                    </tbody>
                 </table>
              </td>
           </tr>
        </tbody>
     </table>
  </div>
</div>`;

  return (
    <div
      style={{ color: "black" }}
      dangerouslySetInnerHTML={{
        __html: selected === "email_3" ? email_3 : selected,
      }}
    />
  );
};

export default EmailTemplate;
