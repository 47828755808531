import React, { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Alert,
    Breadcrumb,
    Button,
    ButtonGroup,
    Card,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    FloatingLabel,
    Form,
    Modal,
    Row,
    Spinner,
} from 'react-bootstrap';
import Page404Authenticated from '../auth/Page404authenticated';
import icon from '../../assets/img/illustrations/icon.png';
import * as Yup from 'yup';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import '../../assets/css/App.css';

// import { useTable, useGlobalFilter } from "react-table";
// import { COLUMNS } from "./column";
// import { GlobalFilter } from "./GlobalFilter";
// import { useEffect, useState } from "react";
import { ArrowLeft, Edit, Plus, Send, X } from 'react-feather';
import useAuth from '../../hooks/useAuth';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { Formik } from 'formik';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import useSidebar from '../../hooks/useSidebar';
import NotyfContext from '../../contexts/NotyfContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPrint } from '@fortawesome/free-solid-svg-icons';
import InvoiceTemplate from '../../components/InvoiceTemplate';

const sumCalculation = (sum, invoice) => {
    return (
        Number(sum).toFixed(2) -
        Number(
            invoice.invoice_discount_type &&
                invoice.invoice_discount_type === 'absolute'
                ? Number(invoice.invoice_discount_rate).toFixed(2)
                : Number(sum * invoice.invoice_discount_rate).toFixed(2)
        ) +
        Number(
            invoice.invoice_tax_type && invoice.invoice_tax_type === 'absolute'
                ? Number(invoice.invoice_tax_rate).toFixed(2)
                : (
                      Number(
                          invoice.invoice_discount_type &&
                              invoice.invoice_discount_type === 'absolute'
                              ? sum - invoice.invoice_discount_rate
                              : sum * (1 - invoice.invoice_discount_rate)
                      ) * invoice.invoice_tax_rate
                  ).toFixed(2)
        )
    );
};
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const ShowDetail = () => {
    // const { bdata } = useAuth();
    // const location = useLocation();
    var location = { state: {} };
    const data = new URLSearchParams(window.location.search);
    location.state = JSON.parse(data.get('data') || '{}');
    let usercurrency = location.state.row
        ? location.state.row.invoice_currency_code
        : 'USD';

    const bdata = location.state ? location.state.bdata : {};
    console.log(bdata);

    const navigate = useNavigate();
    const { id } = useParams();
    const notyf = useContext(NotyfContext);
    const { isOpen } = useSidebar();
    const [mailstatus, setMailstatus] = useState('');
    const [subtotal, setSubtotal] = useState(0);
    const [openModals, setOpenModals] = useState(false);
    const [opendeleteModals, setOpendeleteModals] = useState(false);
    const [rowdata, setRowdata] = useState({});
    const [token, settoken] = useState('');
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('CUSTOMER');
    const [multiplier, setMultiplier] = useState(1);
    const [invoiceerror, setinvoiceerror] = useState(false);
    const [paidtotal, setpaidtotal] = useState(0);
    const [markaspaiddialog, setmarkaspaiddialog] = useState(false);
    const [acceptdialog, setacceptdialog] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [workflow, setWorkflow] = useState({ type: 'Invoice' });
    // const [sendmecopy, setSendmecopy] = useState(false);

    console.log(rowdata);

    //   useEffect(async () => {
    //     setLoading(true);
    //     setFetchError(false);
    //     setSubtotal(0);
    //     var user = firebase.auth().currentUser;
    //     if (user) {
    //       let tokenss = await user.getIdToken();
    //       settoken(tokenss);
    //       if (
    //         location.state !== undefined &&
    //         Object.keys(location.state.row).length !== 0
    //       ) {
    //         console.log("first");

    //         setRowdata(location.state.row);
    //         // getBusinessData(tokenss);
    //         if (location.state.action === "send") {
    //           setOpenModals(true);
    //         }
    //         // setType(
    //         //   rowdata !== undefined
    //         //     ? rowdata.invoice_partner.partner_type
    //         //     : "CUSTOMER"
    //         // );
    //       }
    //       // else {
    //       // console.log("second");
    //       // getRequiredData(tokenss);
    //       // getBusinessData(tokenss);
    //       // setLoading(false);

    //       // }
    //     }
    //     // });
    //   }, [id]);

    //   useEffect(() => {
    //     if (rowdata && Object.keys(rowdata).length !== 0) {
    //       setinvoiceerror(false);
    //       setWorkflow({
    //         type: capitalizeFirstLetter(
    //           rowdata.invoice_workflow_type.toLowerCase() === "order"
    //             ? "job"
    //             : rowdata.invoice_workflow_type.toLowerCase()
    //         ),
    //       });
    //       setType(
    //         rowdata.invoice_partner.partner_type
    //           ? rowdata.invoice_partner.partner_type
    //           : "CUSTOMER"
    //       );
    //       const mult =
    //         rowdata !== undefined && Object.keys(rowdata).length !== 0
    //           ? rowdata.invoice_partner.partner_price_list !== undefined &&
    //             Object.keys(rowdata.invoice_partner.partner_price_list).length !== 0
    //             ? rowdata.invoice_partner.partner_type === "SUPPLIER"
    //               ? 1
    //               : Number(
    //                   rowdata.invoice_partner.partner_price_list
    //                     .price_list_multiplier
    //                 )
    //             : 1
    //           : 1;
    //       setMultiplier(mult);
    //       rowdata.invoice_order.forEach((element) => {
    //         setSubtotal(
    //           (prev) =>
    //             prev +
    //             element.item_quantity *
    //               (rowdata.invoice_partner.partner_type === "SUPPLIER"
    //                 ? element.item_cost_price
    //                   ? element.item_cost_price
    //                   : 0
    //                 : element.item_selling_price
    //                 ? element.item_selling_price
    //                 : 0) *
    //               mult
    //         );
    //       });
    //       setLoading(false);
    //     } else if (rowdata === undefined) {
    //       setinvoiceerror(true);
    //       setLoading(false);
    //     }
    //     return () => setSubtotal(0);
    //   }, [rowdata]);

    // useEffect(async () => {
    //   setRowdata({});
    //   var user = firebase.auth().currentUser;
    //   if (user) {
    //     let tokenss = await user.getIdToken();
    //     settoken(tokenss);
    //     console.log("second");
    //     getRequiredData(tokenss);
    //     // getBusinessData(tokenss);
    //   }
    // }, [id]);

    // console.log(location);
    console.log(rowdata);
    console.log();
    // const type =
    //   rowdata !== undefined && Object.keys(rowdata).length !== 0
    //     ? rowdata.invoice_partner.partner_type
    //     : "CUSTOMER";
    // const multiplier =
    //   rowdata !== undefined && Object.keys(rowdata).length !== 0
    //     ? rowdata.invoice_partner.partner_price_list !== undefined &&
    //       Object.keys(rowdata.invoice_partner.partner_price_list).length !== 0
    //       ? type === "SUPPLIER"
    //         ? 1
    //         : Number(
    //             rowdata.invoice_partner.partner_price_list.price_list_multiplier
    //           )
    //       : 1
    //     : 1;

    // console.log(type, multiplier);
    const printableArea = useRef();
    console.log(rowdata, multiplier);

    const title = `${workflow.type} Detail`;
    console.log(rowdata);
    const createTableFromObject = (obj) => {
        console.log(obj);
        if (obj == null) {
            return null; // or return a message indicating the absence of data
        }
        return (
            <table>
                <tbody>
                    {Object.entries(obj).map(([key, value]) => (
                        <React.Fragment key={key}>
                            {typeof value === 'object' ? (
                                <>
                                    <tr>
                                        <td colSpan='2'>
                                            <strong>{key}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan='2'>
                                            {createTableFromObject(value)}
                                        </td>
                                    </tr>
                                </>
                            ) : value !== null ? (
                                <tr>
                                    <td>
                                        <strong>{key}</strong>
                                    </td>
                                    <td>{value}</td>
                                </tr>
                            ) : (
                                'null'
                            )}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
        );
    };
    //   const createTableFromObject = (obj, level = 0) => {
    //     return (
    //       <table className="nested-table">
    //         <tbody>
    //           {Object.entries(obj).map(([key, value]) => (
    //             <React.Fragment key={key}>
    //               {typeof value === "object" ? (
    //                 <React.Fragment>
    //                   <tr>
    //                     <td style={{ paddingLeft: `${level * 20}px` }}>
    //                       <strong>{key}</strong>
    //                     </td>
    //                   </tr>
    //                   <tr>
    //                     <td style={{ paddingLeft: `${(level + 1) * 20}px` }}>
    //                       {createTableFromObject(value, level + 1)}
    //                     </td>
    //                   </tr>
    //                 </React.Fragment>
    //               ) : (
    //                 <tr>
    //                   <td style={{ paddingLeft: `${level * 20}px` }}>
    //                     <strong>{key}</strong>
    //                   </td>
    //                   <td>{value}</td>
    //                 </tr>
    //               )}
    //             </React.Fragment>
    //           ))}
    //         </tbody>
    //       </table>
    //     );
    //   };
    return (
        <>
            {/* {rowdata !== undefined && Object.keys(bdata).length !== 0 ? ( */}
            {!loading ? (
                <>
                    {!invoiceerror ? (
                        <>
                            <Helmet title={title} />
                            <Container fluid className='p-0 '>
                                <Card
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '0rem 1.5rem 2rem 1.5rem',
                                    }}
                                    className='pt-2 pb-2 border-bottom '
                                >
                                    <div>
                                        <h2
                                            className='mt-3 lead'
                                            style={{
                                                fontSize: '24px',
                                            }}
                                        >
                                            {location.state.title + 's'}
                                        </h2>
                                        <Breadcrumb
                                            className='mb-0 lead'
                                            style={{ fontSize: '13px' }}
                                        >
                                            <Breadcrumb.Item
                                                active
                                                onClick={() =>
                                                    navigate('/customer')
                                                }
                                                style={{ cursor: 'pointer' }}
                                            >
                                                Customers
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item
                                                onClick={() => navigate(-1)}
                                                style={{ cursor: 'pointer' }}
                                                active
                                            >
                                                Customer
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item
                                                active
                                                className='font-weight-bold'
                                            >
                                                {location.state.title + 's'}
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </div>
                                </Card>
                                <div
                                    style={{
                                        padding: '0rem 1.5rem 2rem 1.5rem',
                                    }}
                                >
                                    <Row>
                                        <Col>
                                            <div
                                                style={{
                                                    maxWidth: '100%',
                                                    //   backgroundColor: "pink",
                                                    wordWrap: 'break-word',
                                                }}
                                            >
                                                <Card
                                                    style={{
                                                        padding:
                                                            '0rem 1.5rem 2rem 1.5rem',
                                                    }}
                                                    className='pt-2 pb-2 border-bottom'
                                                >
                                                    <Card.Body>
                                                        {/* {JSON.stringify(rowdata)} */}
                                                        {createTableFromObject(
                                                            location.state.row
                                                        )}
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        </>
                    ) : (
                        <Page404Authenticated />
                    )}
                </>
            ) : (
                <div
                    className='d-flex justify-content-center align-items-center'
                    style={{ height: '70vh' }}
                >
                    <Spinner
                        animation='border'
                        variant='dark'
                        className='me-2'
                    />
                </div>
            )}
        </>
    );
};

export default ShowDetail;
