import React, { useState, useEffect, useMemo, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useSortBy, useTable } from 'react-table';
import {
    Card,
    Col,
    Row,
    Form,
    FloatingLabel,
    Button,
    Alert,
    Modal,
    Nav,
    Tab,
    Table,
    Breadcrumb,
    Badge,
    ButtonGroup,
    Spinner,
} from 'react-bootstrap';
import {
    faSort,
    faSortUp,
    faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import empty_invoice from '../../assets/img/icons/empty_invoice.png';
import Select from 'react-select';
import NotyfContext from '../../contexts/NotyfContext';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import useSidebar from '../../hooks/useSidebar';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import {
    Plus,
    Edit2,
    Phone,
    Mail,
    MessageSquare,
    X,
    Filter,
    ChevronRight,
    Send,
    Package,
    Edit,
    ArrowDownLeft,
    ArrowLeft,
} from 'react-feather';
import moment from 'moment';
import '../../assets/css/App.css';
import Page404Authenticated from '../auth/Page404authenticated';
import EmailTemplate from './EmailTemplate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function truncateTextByWordCount(text, wordLimit) {
    console.log(text.length);
    if (text.length > wordLimit) {
        return text.slice(0, wordLimit) + '...';
    } else {
        console.log(text);
        return text;
    }
}
function convertTo12HourFormat(time24) {
    const [hours, minutes] = time24.split(':').map(Number);

    const period = hours >= 12 ? 'PM' : 'AM';

    const hours12 = hours % 12 || 12;

    const time12 = `${hours12.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')} ${period}`;

    return time12;
}
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
const daysOfWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
];
const sumCalculation = (sum, invoice) => {
    return (
        Number(sum).toFixed(2) -
        Number(
            invoice.invoice_discount_type &&
                invoice.invoice_discount_type === 'absolute'
                ? Number(invoice.invoice_discount_rate).toFixed(2)
                : Number(sum * invoice.invoice_discount_rate).toFixed(2)
        ) +
        Number(
            invoice.invoice_tax_type && invoice.invoice_tax_type === 'absolute'
                ? Number(invoice.invoice_tax_rate).toFixed(2)
                : (
                      Number(
                          invoice.invoice_discount_type &&
                              invoice.invoice_discount_type === 'absolute'
                              ? sum - invoice.invoice_discount_rate
                              : sum * (1 - invoice.invoice_discount_rate)
                      ) * invoice.invoice_tax_rate
                  ).toFixed(2)
        )
    );
};
function getObjectWithNA(obj) {
    if (Object.keys(obj).length === 0) {
        return 'N/A';
    } else {
        let result = '';
        for (const key in obj) {
            const value = obj[key];
            const displayValue =
                value !== undefined && value !== null && value !== ''
                    ? value
                    : 'N/A';
            result += `${key}: ${displayValue}\n`;
        }
        return result;
    }
}
function timeAgoFromTimestampWithMillis(timestampWithMillis) {
    const now = Date.now();
    const differenceInMillis = now - timestampWithMillis;

    const minutes = Math.floor(differenceInMillis / (1000 * 60));
    const hours = Math.floor(differenceInMillis / (1000 * 60 * 60));
    const days = Math.floor(differenceInMillis / (1000 * 60 * 60 * 24));

    if (minutes < 60) {
        return `${minutes} minutes ago`;
    } else if (hours < 24) {
        return `${hours} hours ago`;
    } else {
        return `${days} days ago`;
    }
}

const FilteringTable = (props) => {
    // console.log(props.data);

    const navigate = useNavigate();

    let usercurrency = localStorage.getItem('currency') || 'USD';
    let InvoiceTotal = 0;

    const COLUMNS =
        props.type === 'Invoice'
            ? [
                  {
                      Header: `${
                          props.type === 'JOB' ? 'Booking' : props.type
                      } Id`,
                      accessor: 'invoice_number',
                      disableSortBy: true,
                  },
                  {
                      Header: 'Date',
                      sortType: 'basic',
                      // accessor: (elem) =>
                      //   `${moment(new Date(elem.invoice_created_at)).format(
                      //     "DD MMM YYYY"
                      //   )}`,
                      Cell: ({ row }) => {
                          return (
                              <div>
                                  {moment(
                                      new Date(row.original.invoice_created_at)
                                  ).format('DD MMM YYYY')}
                              </div>
                          );
                      },
                      accessor: 'invoice_created_at',
                  },
                  {
                      Header: `${props.type} Total`,
                      Cell: ({ value, row }) => {
                          console.log(value, row);
                          let invoice = row.original;
                          var sum = 0;
                          var price_list = 1;
                          if (
                              invoice &&
                              invoice.invoice_partner &&
                              invoice.invoice_partner.partner_price_list &&
                              invoice.invoice_partner.partner_price_list
                                  .price_list_multiplier &&
                              !isNaN(
                                  invoice.invoice_partner.partner_price_list
                                      .price_list_multiplier
                              )
                          ) {
                              price_list = Number(
                                  invoice.invoice_partner.partner_price_list
                                      .price_list_multiplier
                              );
                          }
                          for (
                              var i = 0;
                              i < invoice.invoice_order.length;
                              i++
                          ) {
                              if (invoice.invoice_partner_type === 'CUSTOMER') {
                                  sum =
                                      sum +
                                      (invoice.invoice_order[i].item_quantity
                                          ? Number(
                                                invoice.invoice_order[i]
                                                    .item_quantity
                                            )
                                          : 1) *
                                          (invoice.invoice_order[i]
                                              .item_selling_price &&
                                          !isNaN(
                                              invoice.invoice_order[i]
                                                  .item_selling_price
                                          )
                                              ? Number(
                                                    invoice.invoice_order[i]
                                                        .item_selling_price
                                                )
                                              : 0) *
                                          (price_list && !isNaN(price_list)
                                              ? price_list
                                              : 1);
                                  console.log(sum);
                              } else {
                                  sum =
                                      sum +
                                      (invoice.invoice_order[i].item_quantity
                                          ? Number(
                                                invoice.invoice_order[i]
                                                    .item_quantity
                                            )
                                          : 1) *
                                          (invoice.invoice_order[i]
                                              .item_cost_price &&
                                          isNaN(
                                              invoice.invoice_order[i]
                                                  .item_cost_price
                                          )
                                              ? Number(
                                                    invoice.invoice_order[i]
                                                        .item_cost_price
                                                )
                                              : 0);
                              }
                          }
                          var sumWithDiscountAndTax = sumCalculation(
                              sum,
                              invoice
                          );

                          InvoiceTotal = sumWithDiscountAndTax;
                          console.log(price_list, sum, sumWithDiscountAndTax);
                          return (
                              <div style={{ textAlign: 'right' }}>
                                  {sumWithDiscountAndTax.toLocaleString(
                                      'en-US',
                                      {
                                          style: 'currency',
                                          currency:
                                              invoice &&
                                              invoice.invoice_currency_code
                                                  ? invoice.invoice_currency_code
                                                  : usercurrency,
                                      }
                                  )}
                              </div>
                          );
                      },
                      disableSortBy: true,
                  },

                  {
                      Header: 'Pending',
                      disableSortBy: true,
                      accessor: (elem) => elem.invoice_settled_amount,
                      Cell: ({ value, row }) => {
                          const num = InvoiceTotal - parseInt(value);
                          if (Number.isNaN(num)) {
                              return (
                                  <div style={{ textAlign: 'right' }}>
                                      {'-'}
                                  </div>
                              );
                          } else {
                              return (
                                  <div style={{ textAlign: 'right' }}>
                                      {num.toLocaleString('en-US', {
                                          style: 'currency',
                                          currency:
                                              row.original &&
                                              row.original.invoice_currency_code
                                                  ? row.original
                                                        .invoice_currency_code
                                                  : usercurrency,
                                      })}
                                  </div>
                              );
                          }
                      },
                  },
                  {
                      // Header: "Status",
                      Header: () => (
                          <div
                              style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  // textAlign: "center",
                              }}
                          >
                              Status
                          </div>
                      ),
                      disableSortBy: true,
                      width: 180,
                      accessor: 'status',
                      Cell: ({ row }) => {
                          console.log(InvoiceTotal, row.values);
                          if (props.type === 'Invoice') {
                              if (
                                  row.original.invoice_payment_status === 'PAID'
                              ) {
                                  return (
                                      <div
                                          style={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  backgroundColor: '#1AB395',
                                                  padding: '4px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 55,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Paid
                                          </div>
                                      </div>
                                  );
                              } else {
                                  return (
                                      <div
                                          style={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  backgroundColor: '#EC5667',
                                                  padding: '4px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 55,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Unpaid
                                          </div>
                                      </div>
                                  );
                              }
                          } else if (props.type === 'Estimate') {
                              if (row.original.invoice_status === 'ACCEPTED') {
                                  return (
                                      <div
                                          style={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  backgroundColor: '#1AB395',
                                                  padding: '4px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 65,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Accepted
                                          </div>
                                      </div>
                                  );
                              } else if (
                                  row.original.invoice_status === 'CONVERTED'
                              ) {
                                  if (
                                      row.original.invoice_children.some(
                                          (e) =>
                                              e.invoice_workflow_type ===
                                              'INVOICE'
                                      )
                                  ) {
                                      return (
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      // backgroundColor: "#1a7bb8",
                                                      backgroundColor:
                                                          '#3f80ea',
                                                      padding: '4px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      width: 65,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  Invoiced
                                              </div>
                                          </div>
                                      );
                                  } else {
                                      return (
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      backgroundColor:
                                                          '#bf6dab',
                                                      padding: '4px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      // width: 65,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  {/* Converted to Job */}
                                                  Job Created
                                              </div>
                                          </div>
                                      );
                                  }
                              } else if (
                                  row.original.invoice_status === 'OPEN'
                              ) {
                                  return (
                                      <div
                                          style={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  backgroundColor: '#EC5667',
                                                  padding: '4px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 65,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Open
                                          </div>
                                      </div>
                                  );
                              }
                          } else if (props.type === 'Job') {
                              if (row.original.invoice_status === 'CONVERTED') {
                                  return (
                                      <div
                                          style={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  // backgroundColor: "#1a7bb8",
                                                  backgroundColor: '#3f80ea',
                                                  padding: '4px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 75,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Invoiced
                                          </div>
                                      </div>
                                  );
                              } else {
                                  if (
                                      row.original.invoice_time_status ===
                                          'PAST' &&
                                      row.original.invoice_visits &&
                                      row.original.invoice_visits.length !== 0
                                  ) {
                                      return (
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      backgroundColor:
                                                          '#807e7f',
                                                      padding: '4px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      width: 75,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  Past
                                              </div>
                                          </div>
                                      );
                                  } else if (
                                      row.original.invoice_time_status ===
                                          'UPCOMING' &&
                                      row.original.invoice_visits &&
                                      row.original.invoice_visits.length !== 0
                                  ) {
                                      return (
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      backgroundColor:
                                                          '#1AB395',
                                                      padding: '4px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      width: 75,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  Upcoming
                                              </div>
                                          </div>
                                      );
                                  } else {
                                      return (
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      backgroundColor:
                                                          '#EC5667',
                                                      padding: '4px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      width: 75,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  Unscheduled
                                              </div>
                                          </div>
                                      );
                                  }
                              }
                          }
                      },
                  },
                  {
                      // Header: "Actions",
                      width: 80,
                      disableSortBy: true,
                      Header: () => (
                          <div
                              style={{
                                  // display: "flex",
                                  // justifyContent: "center",
                                  textAlign: 'center',
                              }}
                          >
                              Actions
                          </div>
                      ),
                      accessor: 'actions',
                      disableSortBy: true,
                      Cell: ({ row }) => {
                          // const num = parseInt(value);
                          return (
                              <div
                                  style={{
                                      display: 'flex',
                                      justifyContent: 'end',
                                  }}
                              >
                                  <ButtonGroup>
                                      <Button
                                          size='sm'
                                          variant='light'
                                          style={{ backgroundColor: 'white' }}
                                          className='border'
                                          onClick={(e) => {
                                              e.stopPropagation();

                                              // navigate(
                                              // `/work/` +
                                              //     row.original.invoice_id,
                                              //     {
                                              //         state: {
                                              // row: row.original,
                                              // items: props.items,
                                              // bdata: props.rowdata,
                                              //         },
                                              //     }
                                              // );
                                              const url = new URL(
                                                  `/work/` +
                                                      row.original.invoice_id,
                                                  window.location.origin
                                              );
                                              const params =
                                                  new URLSearchParams({
                                                      data: JSON.stringify({
                                                          row: row.original,
                                                          items: props.items,
                                                          bdata: props.rowdata,
                                                      }),
                                                  });
                                              url.search = params.toString();
                                              window.open(
                                                  url.toString(),
                                                  '_blank'
                                              );
                                          }}
                                      >
                                          View
                                      </Button>
                                  </ButtonGroup>
                              </div>
                          );
                      },
                  },
              ]
            : props.type === 'Job'
            ? [
                  {
                      Header: `Booking Id`,
                      accessor: 'invoice_number',
                      disableSortBy: true,
                      width: 100,
                  },
                  {
                      // Header: " Pending",
                      Header: 'Date',
                      sortType: 'basic',
                      width: 200,
                      accessor: 'invoice_created_at',
                      // accessor: (elem) => elem.invoice_settled_amount,
                      Cell: ({ row }) => {
                          if (
                              row.original.invoice_visits &&
                              row.original.invoice_visits.length !== 0
                          ) {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                          // alignItems: "center",
                                          marginLeft: 10,
                                      }}
                                  >
                                      <div style={{}}>
                                          {`${moment(
                                              new Date(
                                                  row.original.invoice_visits[0].appointment_start_time
                                              )
                                          ).format('DD MMM YYYY')}`}
                                      </div>
                                      {/* <div style={{ fontSize: 12, fontStyle: "italic" }}>to</div> */}
                                      {/* <div style={{ fontSize: 13 }}>
                      {` ${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_start_time
                        )
                      ).format("h:mm A")}  -  ${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_end_time
                        )
                      ).format("h:mm A")}`}
                    </div> */}
                                  </div>
                              );
                          } else {
                              return <div style={{ marginLeft: 10 }}>N/A</div>;
                          }
                      },
                  },
                  {
                      // Header: " Pending",
                      Header: () => (
                          <div
                              style={{
                                  // textAlign: "left",
                                  marginLeft: 10,
                              }}
                          >
                              Time
                          </div>
                      ),
                      disableSortBy: true,
                      width: 280,
                      accessor: 'jobtime',
                      // accessor: (elem) => elem.invoice_settled_amount,
                      Cell: ({ row }) => {
                          if (
                              row.original.invoice_visits &&
                              row.original.invoice_visits.length !== 0
                          ) {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                          // alignItems: "center",
                                          marginLeft: 10,
                                      }}
                                  >
                                      {/* <div style={{}}>
                      {`${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_start_time
                        )
                      ).format("DD MMM YYYY")}`}
                    </div> */}
                                      {/* <div style={{ fontSize: 12, fontStyle: "italic" }}>to</div> */}
                                      <div style={{}}>
                                          {` ${moment(
                                              new Date(
                                                  row.original.invoice_visits[0].appointment_start_time
                                              )
                                          ).format('h:mm A')}  -  ${moment(
                                              new Date(
                                                  row.original.invoice_visits[0].appointment_end_time
                                              )
                                          ).format('h:mm A')}`}
                                      </div>
                                  </div>
                              );
                          } else {
                              return <div style={{ marginLeft: 10 }}>N/A</div>;
                          }
                      },
                  },
                  {
                      Header: `Booking Total`,
                      Cell: ({ value, row }) => {
                          console.log(value, row);
                          let invoice = row.original;
                          var sum = 0;
                          var price_list = 1;
                          if (
                              invoice &&
                              invoice.invoice_partner &&
                              invoice.invoice_partner.partner_price_list &&
                              invoice.invoice_partner.partner_price_list
                                  .price_list_multiplier &&
                              !isNaN(
                                  invoice.invoice_partner.partner_price_list
                                      .price_list_multiplier
                              )
                          ) {
                              price_list = Number(
                                  invoice.invoice_partner.partner_price_list
                                      .price_list_multiplier
                              );
                          }
                          for (
                              var i = 0;
                              i < invoice.invoice_order.length;
                              i++
                          ) {
                              if (invoice.invoice_partner_type === 'CUSTOMER') {
                                  sum =
                                      sum +
                                      (invoice.invoice_order[i].item_quantity
                                          ? Number(
                                                invoice.invoice_order[i]
                                                    .item_quantity
                                            )
                                          : 1) *
                                          (invoice.invoice_order[i]
                                              .item_selling_price &&
                                          !isNaN(
                                              invoice.invoice_order[i]
                                                  .item_selling_price
                                          )
                                              ? Number(
                                                    invoice.invoice_order[i]
                                                        .item_selling_price
                                                )
                                              : 0) *
                                          (price_list && !isNaN(price_list)
                                              ? price_list
                                              : 1);
                                  console.log(sum);
                              } else {
                                  sum =
                                      sum +
                                      (invoice.invoice_order[i].item_quantity
                                          ? Number(
                                                invoice.invoice_order[i]
                                                    .item_quantity
                                            )
                                          : 1) *
                                          (invoice.invoice_order[i]
                                              .item_cost_price &&
                                          isNaN(
                                              invoice.invoice_order[i]
                                                  .item_cost_price
                                          )
                                              ? Number(
                                                    invoice.invoice_order[i]
                                                        .item_cost_price
                                                )
                                              : 0);
                              }
                          }
                          var sumWithDiscountAndTax = sumCalculation(
                              sum,
                              invoice
                          );

                          InvoiceTotal = sumWithDiscountAndTax;
                          console.log(price_list, sum, sumWithDiscountAndTax);
                          return (
                              <div style={{ textAlign: 'right' }}>
                                  {sumWithDiscountAndTax.toLocaleString(
                                      'en-US',
                                      {
                                          style: 'currency',
                                          currency:
                                              invoice &&
                                              invoice.invoice_currency_code
                                                  ? invoice.invoice_currency_code
                                                  : usercurrency,
                                      }
                                  )}
                              </div>
                          );
                      },
                      disableSortBy: true,
                  },

                  {
                      // Header: "Status",
                      Header: () => (
                          <div
                              style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  // textAlign: "center",
                              }}
                          >
                              Status
                          </div>
                      ),
                      disableSortBy: true,
                      width: 180,
                      accessor: 'status',
                      Cell: ({ row }) => {
                          console.log(InvoiceTotal, row.values);
                          if (props.type === 'Invoice') {
                              if (
                                  row.original.invoice_payment_status === 'PAID'
                              ) {
                                  return (
                                      <div
                                          style={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  backgroundColor: '#1AB395',
                                                  padding: '4px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 55,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Paid
                                          </div>
                                      </div>
                                  );
                              } else {
                                  return (
                                      <div
                                          style={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  backgroundColor: '#EC5667',
                                                  padding: '4px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 55,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Unpaid
                                          </div>
                                      </div>
                                  );
                              }
                          } else if (props.type === 'Estimate') {
                              if (row.original.invoice_status === 'ACCEPTED') {
                                  return (
                                      <div
                                          style={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  backgroundColor: '#1AB395',
                                                  padding: '4px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 65,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Accepted
                                          </div>
                                      </div>
                                  );
                              } else if (
                                  row.original.invoice_status === 'CONVERTED'
                              ) {
                                  if (
                                      row.original.invoice_children.some(
                                          (e) =>
                                              e.invoice_workflow_type ===
                                              'INVOICE'
                                      )
                                  ) {
                                      return (
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      // backgroundColor: "#1a7bb8",
                                                      backgroundColor:
                                                          '#3f80ea',
                                                      padding: '4px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      width: 65,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  Invoiced
                                              </div>
                                          </div>
                                      );
                                  } else {
                                      return (
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      backgroundColor:
                                                          '#bf6dab',
                                                      padding: '4px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      // width: 65,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  {/* Converted to Job */}
                                                  Job Created
                                              </div>
                                          </div>
                                      );
                                  }
                              } else if (
                                  row.original.invoice_status === 'OPEN'
                              ) {
                                  return (
                                      <div
                                          style={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  backgroundColor: '#EC5667',
                                                  padding: '4px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 65,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Open
                                          </div>
                                      </div>
                                  );
                              }
                          } else if (props.type === 'Job') {
                              if (row.original.invoice_status === 'CONVERTED') {
                                  return (
                                      <div
                                          style={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  // backgroundColor: "#1a7bb8",
                                                  backgroundColor: '#3f80ea',
                                                  padding: '4px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 75,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Invoiced
                                          </div>
                                      </div>
                                  );
                              } else {
                                  if (
                                      row.original.invoice_time_status ===
                                          'PAST' &&
                                      row.original.invoice_visits &&
                                      row.original.invoice_visits.length !== 0
                                  ) {
                                      return (
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      backgroundColor:
                                                          '#807e7f',
                                                      padding: '4px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      width: 75,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  Past
                                              </div>
                                          </div>
                                      );
                                  } else if (
                                      row.original.invoice_time_status ===
                                          'UPCOMING' &&
                                      row.original.invoice_visits &&
                                      row.original.invoice_visits.length !== 0
                                  ) {
                                      return (
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      backgroundColor:
                                                          '#1AB395',
                                                      padding: '4px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      width: 75,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  Upcoming
                                              </div>
                                          </div>
                                      );
                                  } else {
                                      return (
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      backgroundColor:
                                                          '#EC5667',
                                                      padding: '4px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      width: 75,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  Unscheduled
                                              </div>
                                          </div>
                                      );
                                  }
                              }
                          }
                      },
                  },
                  {
                      // Header: "Actions",
                      // width: 200,
                      Header: () => (
                          <div
                              style={{
                                  // display: "flex",
                                  // justifyContent: "center",
                                  textAlign: 'center',
                              }}
                          >
                              Actions
                          </div>
                      ),
                      disableSortBy: true,
                      width: 80,
                      accessor: 'actions',
                      disableSortBy: true,
                      Cell: ({ row }) => {
                          // const num = parseInt(value);
                          return (
                              <div
                                  style={{
                                      display: 'flex',
                                      justifyContent: 'end',
                                  }}
                              >
                                  <ButtonGroup>
                                      <Button
                                          size='sm'
                                          variant='light'
                                          style={{ backgroundColor: 'white' }}
                                          className='border'
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              console.log('ddd');
                                              // navigate(
                                              //     `/work/` +
                                              //         row.original.invoice_id,
                                              //     {
                                              //         state: {
                                              //             row: row.original,
                                              //             items: props.items,
                                              //             bdata: props.rowdata,
                                              //         },
                                              //     }
                                              // );
                                              const url = new URL(
                                                  `/work/` +
                                                      row.original.invoice_id,
                                                  window.location.origin
                                              );
                                              const params =
                                                  new URLSearchParams({
                                                      data: JSON.stringify({
                                                          row: row.original,
                                                          items: props.items,
                                                          bdata: props.rowdata,
                                                      }),
                                                  });
                                              url.search = params.toString();
                                              window.open(
                                                  url.toString(),
                                                  '_blank'
                                              );
                                          }}
                                      >
                                          View
                                      </Button>
                                  </ButtonGroup>
                              </div>
                          );
                      },
                  },
              ]
            : props.type === 'Estimate'
            ? [
                  {
                      Header: `${props.type} Id`,
                      accessor: 'invoice_number',
                      disableSortBy: true,
                  },
                  {
                      Header: 'Date',
                      sortType: 'basic',

                      // accessor: (elem) =>
                      //   `${moment(new Date(elem.invoice_created_at)).format(
                      //     "DD MMM YYYY"
                      //   )}`,
                      Cell: ({ row }) => {
                          return (
                              <div>
                                  {moment(
                                      new Date(row.original.invoice_created_at)
                                  ).format('DD MMM YYYY')}
                              </div>
                          );
                      },
                      accessor: 'invoice_created_at',
                  },
                  {
                      Header: `${props.type} Total`,
                      disableSortBy: true,
                      Cell: ({ value, row }) => {
                          console.log(value, row);
                          let invoice = row.original;
                          var sum = 0;
                          var price_list = 1;
                          if (
                              invoice &&
                              invoice.invoice_partner &&
                              invoice.invoice_partner.partner_price_list &&
                              invoice.invoice_partner.partner_price_list
                                  .price_list_multiplier &&
                              !isNaN(
                                  invoice.invoice_partner.partner_price_list
                                      .price_list_multiplier
                              )
                          ) {
                              price_list = Number(
                                  invoice.invoice_partner.partner_price_list
                                      .price_list_multiplier
                              );
                          }
                          for (
                              var i = 0;
                              i < invoice.invoice_order.length;
                              i++
                          ) {
                              if (invoice.invoice_partner_type === 'CUSTOMER') {
                                  sum =
                                      sum +
                                      (invoice.invoice_order[i].item_quantity
                                          ? Number(
                                                invoice.invoice_order[i]
                                                    .item_quantity
                                            )
                                          : 1) *
                                          (invoice.invoice_order[i]
                                              .item_selling_price &&
                                          !isNaN(
                                              invoice.invoice_order[i]
                                                  .item_selling_price
                                          )
                                              ? Number(
                                                    invoice.invoice_order[i]
                                                        .item_selling_price
                                                )
                                              : 0) *
                                          (price_list && !isNaN(price_list)
                                              ? price_list
                                              : 1);
                                  console.log(sum);
                              } else {
                                  sum =
                                      sum +
                                      (invoice.invoice_order[i].item_quantity
                                          ? Number(
                                                invoice.invoice_order[i]
                                                    .item_quantity
                                            )
                                          : 1) *
                                          (invoice.invoice_order[i]
                                              .item_cost_price &&
                                          isNaN(
                                              invoice.invoice_order[i]
                                                  .item_cost_price
                                          )
                                              ? Number(
                                                    invoice.invoice_order[i]
                                                        .item_cost_price
                                                )
                                              : 0);
                              }
                          }
                          var sumWithDiscountAndTax = sumCalculation(
                              sum,
                              invoice
                          );
                          InvoiceTotal = sumWithDiscountAndTax;
                          console.log(price_list, sum, sumWithDiscountAndTax);
                          return (
                              <div style={{ textAlign: 'right' }}>
                                  {sumWithDiscountAndTax.toLocaleString(
                                      'en-US',
                                      {
                                          style: 'currency',
                                          currency:
                                              invoice &&
                                              invoice.invoice_currency_code
                                                  ? invoice.invoice_currency_code
                                                  : usercurrency,
                                      }
                                  )}
                              </div>
                          );
                      },
                  },
                  {
                      // Header: "Status",
                      Header: () => (
                          <div
                              style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  // textAlign: "center",
                              }}
                          >
                              Status
                          </div>
                      ),
                      disableSortBy: true,
                      width: 180,
                      accessor: 'status',
                      Cell: ({ row }) => {
                          console.log(InvoiceTotal, row.values);
                          if (props.type === 'Invoice') {
                              if (
                                  row.original.invoice_payment_status === 'PAID'
                              ) {
                                  return (
                                      <div
                                          style={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  backgroundColor: '#1AB395',
                                                  padding: '4px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 55,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Paid
                                          </div>
                                      </div>
                                  );
                              } else {
                                  return (
                                      <div
                                          style={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  backgroundColor: '#EC5667',
                                                  padding: '4px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 55,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Unpaid
                                          </div>
                                      </div>
                                  );
                              }
                          } else if (props.type === 'Estimate') {
                              if (row.original.invoice_status === 'ACCEPTED') {
                                  return (
                                      <div
                                          style={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  backgroundColor: '#1AB395',
                                                  padding: '4px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 65,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Accepted
                                          </div>
                                      </div>
                                  );
                              } else if (
                                  row.original.invoice_status === 'CONVERTED'
                              ) {
                                  if (
                                      row.original.invoice_children.some(
                                          (e) =>
                                              e.invoice_workflow_type ===
                                              'INVOICE'
                                      )
                                  ) {
                                      return (
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      // backgroundColor: "#1a7bb8",
                                                      backgroundColor:
                                                          '#3f80ea',
                                                      padding: '4px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      width: 65,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  Invoiced
                                              </div>
                                          </div>
                                      );
                                  } else {
                                      return (
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      backgroundColor:
                                                          '#bf6dab',
                                                      padding: '4px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      // width: 65,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  {/* Converted to Job */}
                                                  Job Created
                                              </div>
                                          </div>
                                      );
                                  }
                              } else if (
                                  row.original.invoice_status === 'OPEN'
                              ) {
                                  return (
                                      <div
                                          style={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  backgroundColor: '#EC5667',
                                                  padding: '4px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 65,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Open
                                          </div>
                                      </div>
                                  );
                              }
                          } else if (props.type === 'Job') {
                              if (row.original.invoice_status === 'CONVERTED') {
                                  return (
                                      <div
                                          style={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  // backgroundColor: "#1a7bb8",
                                                  backgroundColor: '#3f80ea',
                                                  padding: '4px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 75,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Invoiced
                                          </div>
                                      </div>
                                  );
                              } else {
                                  if (
                                      row.original.invoice_time_status ===
                                          'PAST' &&
                                      row.original.invoice_visits &&
                                      row.original.invoice_visits.length !== 0
                                  ) {
                                      return (
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      backgroundColor:
                                                          '#807e7f',
                                                      padding: '4px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      width: 75,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  Past
                                              </div>
                                          </div>
                                      );
                                  } else if (
                                      row.original.invoice_time_status ===
                                          'UPCOMING' &&
                                      row.original.invoice_visits &&
                                      row.original.invoice_visits.length !== 0
                                  ) {
                                      return (
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      backgroundColor:
                                                          '#1AB395',
                                                      padding: '4px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      width: 75,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  Upcoming
                                              </div>
                                          </div>
                                      );
                                  } else {
                                      return (
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      backgroundColor:
                                                          '#EC5667',
                                                      padding: '4px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      width: 75,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  Unscheduled
                                              </div>
                                          </div>
                                      );
                                  }
                              }
                          }
                      },
                  },
                  {
                      // Header: "Actions",
                      width: 80,
                      Header: () => (
                          <div
                              style={{
                                  // display: "flex",
                                  // justifyContent: "center",
                                  textAlign: 'center',
                              }}
                          >
                              Actions
                          </div>
                      ),
                      disableSortBy: true,
                      accessor: 'actions',
                      disableSortBy: true,
                      Cell: ({ row }) => {
                          // const num = parseInt(value);
                          return (
                              <div
                                  style={{
                                      display: 'flex',
                                      justifyContent: 'end',
                                  }}
                              >
                                  <ButtonGroup>
                                      <Button
                                          size='sm'
                                          variant='light'
                                          style={{ backgroundColor: 'white' }}
                                          className='border'
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              console.log('ddd');
                                              // navigate(
                                              //     `/work/` +
                                              //         row.original.invoice_id,
                                              //     {
                                              //         state: {
                                              //             row: row.original,
                                              //             items: props.items,
                                              //             bdata: props.rowdata,
                                              //         },
                                              //     }
                                              // );
                                              const url = new URL(
                                                  `/work/` +
                                                      row.original.invoice_id,
                                                  window.location.origin
                                              );
                                              const params =
                                                  new URLSearchParams({
                                                      data: JSON.stringify({
                                                          row: row.original,
                                                          items: props.items,
                                                          bdata: props.rowdata,
                                                      }),
                                                  });
                                              url.search = params.toString();
                                              window.open(
                                                  url.toString(),
                                                  '_blank'
                                              );
                                          }}
                                      >
                                          View
                                      </Button>
                                  </ButtonGroup>
                              </div>
                          );
                      },
                  },
              ]
            : props.type === 'Request'
            ? [
                  {
                      Header: `Request Id`,
                      accessor: 'invoice_number',
                      disableSortBy: true,
                      width: 100,
                  },
                  {
                      // Header: " Pending",
                      Header: 'Date',
                      sortType: 'basic',
                      width: 200,
                      accessor: 'invoice_created_at',
                      // accessor: (elem) => elem.invoice_settled_amount,
                      Cell: ({ row }) => {
                          if (
                              row.original.invoice_visits &&
                              row.original.invoice_visits.length !== 0
                          ) {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                          // alignItems: "center",
                                          marginLeft: 10,
                                      }}
                                  >
                                      <div style={{}}>
                                          {`${moment(
                                              new Date(
                                                  row.original.invoice_visits[0].appointment_start_time
                                              )
                                          ).format('DD MMM YYYY')}`}
                                      </div>
                                      {/* <div style={{ fontSize: 12, fontStyle: "italic" }}>to</div> */}
                                      {/* <div style={{ fontSize: 13 }}>
                      {` ${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_start_time
                        )
                      ).format("h:mm A")}  -  ${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_end_time
                        )
                      ).format("h:mm A")}`}
                    </div> */}
                                  </div>
                              );
                          } else {
                              return <div style={{ marginLeft: 10 }}>N/A</div>;
                          }
                      },
                  },
                  {
                      // Header: " Pending",
                      Header: () => (
                          <div
                              style={{
                                  // textAlign: "left",
                                  marginLeft: 10,
                              }}
                          >
                              Time
                          </div>
                      ),
                      disableSortBy: true,
                      width: 280,
                      accessor: 'jobtime',
                      // accessor: (elem) => elem.invoice_settled_amount,
                      Cell: ({ row }) => {
                          if (
                              row.original.invoice_visits &&
                              row.original.invoice_visits.length !== 0
                          ) {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                          // alignItems: "center",
                                          marginLeft: 10,
                                      }}
                                  >
                                      {/* <div style={{}}>
                      {`${moment(
                        new Date(
                          row.original.invoice_visits[0].appointment_start_time
                        )
                      ).format("DD MMM YYYY")}`}
                    </div> */}
                                      {/* <div style={{ fontSize: 12, fontStyle: "italic" }}>to</div> */}
                                      <div style={{}}>
                                          {` ${moment(
                                              new Date(
                                                  row.original.invoice_visits[0].appointment_start_time
                                              )
                                          ).format('h:mm A')}  -  ${moment(
                                              new Date(
                                                  row.original.invoice_visits[0].appointment_end_time
                                              )
                                          ).format('h:mm A')}`}
                                      </div>
                                  </div>
                              );
                          } else {
                              return <div style={{ marginLeft: 10 }}>N/A</div>;
                          }
                      },
                  },
                  {
                      // Header: "Status",
                      Header: () => (
                          <div
                              style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  // textAlign: "center",
                              }}
                          >
                              Status
                          </div>
                      ),
                      disableSortBy: true,
                      width: 180,
                      accessor: 'status',
                      Cell: ({ row }) => {
                          return (
                              <>
                                  {row.original.invoice_status ===
                                  'CONVERTED' ? (
                                      <>
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      backgroundColor:
                                                          '#3f80ea',
                                                      padding: '4px',
                                                      marginLeft: '5px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      // marginBottom: "13.2px",
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      width: 75,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  Converted
                                              </div>
                                          </div>
                                      </>
                                  ) : row.original.invoice_status ===
                                    'ARCHIVED' ? (
                                      <div
                                          style={{
                                              // width: "100%",
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  backgroundColor: '#807e7f',
                                                  padding: '4px',
                                                  // marginBottom: "13.2px",
                                                  marginLeft: '5px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 75,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Archived
                                          </div>
                                      </div>
                                  ) : row.original.invoice_partner_type ===
                                    'LEAD' ? (
                                      <div
                                          style={{
                                              // width: "100%",
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  backgroundColor: '#71BE1D',
                                                  padding: '4px',
                                                  color: 'white',
                                                  // marginBottom: "13.2px",
                                                  marginLeft: '5px',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 75,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Lead
                                          </div>
                                      </div>
                                  ) : row.original.invoice_status === 'OPEN' ? (
                                      <div
                                          style={{
                                              // width: "100%",
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  backgroundColor: '#DE5474',
                                                  padding: '4px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 75,

                                                  textAlign: 'center',
                                              }}
                                          >
                                              Open
                                          </div>
                                      </div>
                                  ) : (
                                      <></>
                                  )}
                              </>
                          );
                      },
                  },
                  {
                      // Header: "Actions",
                      // width: 200,
                      Header: () => (
                          <div
                              style={{
                                  // display: "flex",
                                  // justifyContent: "center",
                                  textAlign: 'center',
                              }}
                          >
                              Actions
                          </div>
                      ),
                      disableSortBy: true,
                      width: 80,
                      accessor: 'actions',
                      disableSortBy: true,
                      Cell: ({ row }) => {
                          // const num = parseInt(value);
                          return (
                              <div
                                  style={{
                                      display: 'flex',
                                      justifyContent: 'end',
                                  }}
                              >
                                  <ButtonGroup>
                                      <Button
                                          size='sm'
                                          variant='light'
                                          style={{ backgroundColor: 'white' }}
                                          className='border'
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              console.log('ddd');
                                              // navigate(`/showDetail/`, {
                                              //     state: {
                                              // row: row.original,
                                              // title: 'Request',
                                              //     },
                                              // });
                                              const url = new URL(
                                                  `/showDetail/`,
                                                  window.location.origin
                                              );
                                              const params =
                                                  new URLSearchParams({
                                                      data: JSON.stringify({
                                                          row: row.original,
                                                          title: 'Request',
                                                      }),
                                                  });
                                              url.search = params.toString();
                                              window.open(
                                                  url.toString(),
                                                  '_blank'
                                              );
                                          }}
                                      >
                                          View
                                      </Button>
                                  </ButtonGroup>
                              </div>
                          );
                      },
                  },
              ]
            : props.type === 'Item'
            ? [
                  {
                      Header: `Item Code`,
                      accessor: 'item_code',
                      disableSortBy: true,
                      width: 100,
                  },
                  {
                      width: 300,
                      Header: 'Item Name',
                      accessor: 'item_name',
                      disableSortBy: true,
                      Cell: ({ value, row }) => {
                          // const num = parseInt(value) * multiplier;
                          // console.log(row);

                          if (
                              Object.keys(row.original).includes(
                                  'item_description'
                              ) &&
                              row.original.item_description !== ''
                          ) {
                              return (
                                  <div
                                      style={{
                                          wordWrap: 'break-word',
                                          maxWidth: 250,
                                      }}
                                  >
                                      <div>
                                          {value.length > 55
                                              ? value.substring(0, 55) + ' ...'
                                              : value}
                                      </div>
                                      <div
                                          style={{
                                              marginTop: '0px',
                                              fontSize: '0.7rem',
                                              color: 'grey',
                                              whiteSpace: 'pre-line',
                                              maxHeight: '3.3rem',
                                              overflow: 'hidden',
                                          }}
                                      >
                                          {row.original.item_description
                                              .length > 120
                                              ? row.original.item_description.substring(
                                                    0,
                                                    120
                                                ) + ' ...'
                                              : row.original.item_description}
                                      </div>
                                  </div>
                              );
                          } else {
                              return (
                                  <div
                                      style={{
                                          wordWrap: 'break-word',
                                          maxWidth: 250,
                                      }}
                                  >
                                      {value.length > 55
                                          ? value.substring(0, 55) + ' ...'
                                          : value}
                                      {/* <div style={{ marginTop: "10px" }}>No Description</div> */}
                                  </div>
                              );
                          }
                      },
                  },
                  {
                      // Header: "Status",
                      Header: () => (
                          <div
                              style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  // textAlign: "center",
                              }}
                          >
                              Item Type
                          </div>
                      ),
                      disableSortBy: true,
                      width: 180,
                      accessor: 'itme_type',
                      Cell: ({ row }) => {
                          return (
                              <>
                                  {row.original.item_type === 'PRODUCT' ? (
                                      <>
                                          <div
                                              style={{
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              <div
                                                  style={{
                                                      backgroundColor:
                                                          '#3f80ea',
                                                      padding: '4px',
                                                      marginLeft: '5px',
                                                      color: 'white',
                                                      borderRadius: '5px',
                                                      // marginBottom: "13.2px",
                                                      fontSize: '10px',
                                                      fontWeight: 'bold',
                                                      width: 75,
                                                      textAlign: 'center',
                                                  }}
                                              >
                                                  Product
                                              </div>
                                          </div>
                                      </>
                                  ) : row.original.item_type === 'SERVICE' ? (
                                      <div
                                          style={{
                                              // width: "100%",
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'center',
                                          }}
                                      >
                                          <div
                                              style={{
                                                  backgroundColor: '#807e7f',
                                                  padding: '4px',
                                                  // marginBottom: "13.2px",
                                                  marginLeft: '5px',
                                                  color: 'white',
                                                  borderRadius: '5px',
                                                  fontSize: '10px',
                                                  fontWeight: 'bold',
                                                  width: 75,
                                                  textAlign: 'center',
                                              }}
                                          >
                                              Service
                                          </div>
                                      </div>
                                  ) : (
                                      <></>
                                  )}
                              </>
                          );
                      },
                  },
                  {
                      Header: 'Cost Price',
                      accessor: 'item_cost_price',
                      disableSortBy: true,
                      width: 100,
                      Cell: ({ value, row }) => {
                          const num = Number(value);
                          if (Number.isNaN(num)) {
                              return (
                                  <div style={{ textAlign: 'right' }}>
                                      {'-'}
                                  </div>
                              );
                          } else {
                              return (
                                  <div style={{ textAlign: 'right' }}>
                                      {num.toLocaleString('en-US', {
                                          style: 'currency',
                                          currency: usercurrency,
                                      })}
                                  </div>
                              );
                          }
                      },
                  },
                  {
                      Header: 'Selling Price',
                      accessor: 'item_selling_price',
                      disableSortBy: true,
                      width: 100,
                      Cell: ({ value, row }) => {
                          const num = Number(value);
                          if (Number.isNaN(num)) {
                              return (
                                  <div style={{ textAlign: 'right' }}>
                                      {'-'}
                                  </div>
                              );
                          } else {
                              return (
                                  <div style={{ textAlign: 'right' }}>
                                      {num.toLocaleString('en-US', {
                                          style: 'currency',
                                          currency: usercurrency,
                                      })}
                                  </div>
                              );
                          }
                      },
                  },
                  {
                      // Header: "Actions",
                      // width: 200,
                      Header: () => (
                          <div
                              style={{
                                  // display: "flex",
                                  // justifyContent: "center",
                                  textAlign: 'center',
                              }}
                          >
                              Actions
                          </div>
                      ),
                      disableSortBy: true,
                      width: 80,
                      accessor: 'actions',
                      disableSortBy: true,
                      Cell: ({ row }) => {
                          // const num = parseInt(value);
                          return (
                              <div
                                  style={{
                                      display: 'flex',
                                      justifyContent: 'end',
                                  }}
                              >
                                  <ButtonGroup>
                                      <Button
                                          size='sm'
                                          variant='light'
                                          style={{ backgroundColor: 'white' }}
                                          className='border'
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              console.log('ddd');
                                              // navigate(`/showDetail/`, {
                                              //     state: {
                                              //         row: row.original,
                                              //         title: 'Item',
                                              //     },
                                              // });
                                              const url = new URL(
                                                  `/showDetail/`,
                                                  window.location.origin
                                              );
                                              const params =
                                                  new URLSearchParams({
                                                      data: JSON.stringify({
                                                          row: row.original,
                                                          title: 'Item',
                                                      }),
                                                  });
                                              url.search = params.toString();
                                              window.open(
                                                  url.toString(),
                                                  '_blank'
                                              );
                                          }}
                                      >
                                          View
                                      </Button>
                                  </ButtonGroup>
                              </div>
                          );
                      },
                  },
              ]
            : props.type === 'Expense'
            ? [
                  // {
                  //   Header: "Date",
                  //   width: 80,
                  //   sortType: "basic",
                  //   accessor: (elem) =>
                  //     `${moment(new Date(elem.expense_date)).format("DD MMM YYYY")}`,
                  // },
                  {
                      Header: 'Date',
                      sortType: 'basic',

                      // accessor: (elem) =>
                      //   `${moment(new Date(elem.invoice_created_at)).format(
                      //     "DD MMM YYYY"
                      //   )}`,
                      width: 80,
                      Cell: ({ row, elem }) => {
                          return (
                              <div>
                                  {moment(
                                      new Date(row.original.expense_date)
                                  ).format('DD MMM YYYY')}
                              </div>
                          );
                      },
                      accessor: 'invoice_created_at',
                  },
                  {
                      Header: 'Category',
                      accessor: 'expense_category',
                      disableSortBy: true,
                      width: 100,
                      Cell: ({ value }) => {
                          if (!value) {
                              return (
                                  <div style={{ textAlign: 'center' }}>
                                      {'-'}
                                  </div>
                              );
                          } else {
                              return (
                                  <div style={{ textAlign: 'center' }}>
                                      {value}
                                  </div>
                              );
                          }
                      },
                  },
                  {
                      Header: `Expense Name`,
                      accessor: 'expense_name',
                      disableSortBy: true,
                      // width: 00,
                  },
                  {
                      Header: 'Total',
                      accessor: 'expense_total',
                      disableSortBy: true,
                      width: 100,
                      Cell: ({ value, row }) => {
                          const num = Number(value);
                          if (Number.isNaN(num)) {
                              return (
                                  <div style={{ textAlign: 'right' }}>
                                      {'-'}
                                  </div>
                              );
                          } else {
                              return (
                                  <div style={{ textAlign: 'right' }}>
                                      {num.toLocaleString('en-US', {
                                          style: 'currency',
                                          currency: usercurrency,
                                      })}
                                  </div>
                              );
                          }
                      },
                  },
                  {
                      // Header: "Actions",
                      // width: 200,
                      Header: () => (
                          <div
                              style={{
                                  // display: "flex",
                                  // justifyContent: "center",
                                  textAlign: 'center',
                              }}
                          >
                              Actions
                          </div>
                      ),
                      disableSortBy: true,
                      width: 80,
                      accessor: 'actions',
                      disableSortBy: true,
                      Cell: ({ row }) => {
                          // const num = parseInt(value);
                          return (
                              <div
                                  style={{
                                      display: 'flex',
                                      justifyContent: 'end',
                                  }}
                              >
                                  <ButtonGroup>
                                      <Button
                                          size='sm'
                                          variant='light'
                                          style={{ backgroundColor: 'white' }}
                                          className='border'
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              console.log('ddd');
                                              // navigate(`/showDetail/`, {
                                              //     state: {
                                              //         row: row.original,
                                              //         title: 'Expense',
                                              //     },
                                              // });
                                              const url = new URL(
                                                  `/showDetail/`,
                                                  window.location.origin
                                              );
                                              const params =
                                                  new URLSearchParams({
                                                      data: JSON.stringify({
                                                          row: row.original,
                                                          title: 'Expense',
                                                      }),
                                                  });
                                              url.search = params.toString();
                                              window.open(
                                                  url.toString(),
                                                  '_blank'
                                              );
                                          }}
                                      >
                                          View
                                      </Button>
                                  </ButtonGroup>
                              </div>
                          );
                      },
                  },
              ]
            : props.type === 'Timesheet'
            ? [
                  {
                      Header: 'Date',
                      sortType: 'basic',
                      width: 150,
                      accessor: 'invoice_created_at',

                      Cell: ({ row }) => {
                          if (row.original.timesheet_start_time) {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                          // alignItems: "center",
                                          marginLeft: 10,
                                      }}
                                  >
                                      <div style={{}}>
                                          {`${moment(
                                              new Date(
                                                  row.original.timesheet_start_time
                                              )
                                          ).format('DD MMM YYYY')}`}
                                      </div>
                                  </div>
                              );
                          } else {
                              return <div style={{ marginLeft: 10 }}>N/A</div>;
                          }
                      },
                  },
                  {
                      // Header: " Pending",
                      Header: () => (
                          <div
                              style={{
                                  // textAlign: "left",
                                  marginLeft: 10,
                              }}
                          >
                              Time
                          </div>
                      ),
                      disableSortBy: true,
                      width: 200,
                      accessor: 'jobtime',
                      // accessor: (elem) => elem.invoice_settled_amount,
                      Cell: ({ row }) => {
                          if (
                              row.original.timesheet_start_time &&
                              row.original.timesheet_end_time
                          ) {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          justifyContent: 'center',
                                          // alignItems: "center",
                                          marginLeft: 10,
                                      }}
                                  >
                                      <div style={{}}>
                                          {` ${moment(
                                              new Date(
                                                  row.original.timesheet_start_time
                                              )
                                          ).format('h:mm A')}  -  ${moment(
                                              new Date(
                                                  row.original.timesheet_end_time
                                              )
                                          ).format('h:mm A')}`}
                                      </div>
                                  </div>
                              );
                          } else {
                              return <div style={{ marginLeft: 10 }}>N/A</div>;
                          }
                      },
                  },
                  {
                      Header: 'Description',
                      accessor: 'timesheet_name',
                      disableSortBy: true,
                      width: 400,
                      Cell: ({ value }) => {
                          if (!value) {
                              return <div style={{}}>{'-'}</div>;
                          } else {
                              return (
                                  <div style={{ maxWidth: 400 }}>
                                      {truncateTextByWordCount(value, 100)}
                                  </div>
                              );
                          }
                      },
                  },
                  {
                      // Header: "Actions",
                      // width: 200,
                      Header: () => (
                          <div
                              style={{
                                  // display: "flex",
                                  // justifyContent: "center",
                                  textAlign: 'center',
                              }}
                          >
                              Actions
                          </div>
                      ),
                      disableSortBy: true,
                      width: 80,
                      accessor: 'actions',
                      disableSortBy: true,
                      Cell: ({ row }) => {
                          // const num = parseInt(value);
                          return (
                              <div
                                  style={{
                                      display: 'flex',
                                      justifyContent: 'end',
                                  }}
                              >
                                  <ButtonGroup>
                                      <Button
                                          size='sm'
                                          variant='light'
                                          style={{ backgroundColor: 'white' }}
                                          className='border'
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              console.log('ddd');
                                              // navigate(`/showDetail/`, {
                                              //     state: {
                                              //         row: row.original,
                                              //         title: 'Timesheet',
                                              //     },
                                              // });
                                              const url = new URL(
                                                  `/showDetail/`,
                                                  window.location.origin
                                              );
                                              const params =
                                                  new URLSearchParams({
                                                      data: JSON.stringify({
                                                          row: row.original,
                                                          title: 'Timesheet',
                                                      }),
                                                  });
                                              url.search = params.toString();
                                              window.open(
                                                  url.toString(),
                                                  '_blank'
                                              );
                                          }}
                                      >
                                          View
                                      </Button>
                                  </ButtonGroup>
                              </div>
                          );
                      },
                  },
              ]
            : props.type === 'Transaction'
            ? [
                  {
                      Header: 'Date',
                      sortType: 'basic',

                      width: 80,
                      Cell: ({ row, elem }) => {
                          return (
                              <div>
                                  {moment(
                                      new Date(
                                          row.original.transaction_created_at
                                      )
                                  ).format('DD MMM YYYY')}
                              </div>
                          );
                      },
                      accessor: 'invoice_created_at',
                  },
                  {
                      Header: 'Total',
                      accessor: 'transaction_total_amount',
                      disableSortBy: true,
                      width: 100,
                      Cell: ({ value, row }) => {
                          const num = Number(value);
                          if (Number.isNaN(num)) {
                              return (
                                  <div style={{ textAlign: 'right' }}>
                                      {'-'}
                                  </div>
                              );
                          } else {
                              return (
                                  <div style={{ textAlign: 'right' }}>
                                      {num.toLocaleString('en-US', {
                                          style: 'currency',
                                          currency: usercurrency,
                                      })}
                                  </div>
                              );
                          }
                      },
                  },
                  {
                      // Header: "Actions",
                      // width: 200,
                      Header: () => (
                          <div
                              style={{
                                  // display: "flex",
                                  // justifyContent: "center",
                                  textAlign: 'center',
                              }}
                          >
                              Actions
                          </div>
                      ),
                      disableSortBy: true,
                      width: 80,
                      accessor: 'actions',
                      disableSortBy: true,
                      Cell: ({ row }) => {
                          // const num = parseInt(value);
                          return (
                              <div
                                  style={{
                                      display: 'flex',
                                      justifyContent: 'end',
                                  }}
                              >
                                  <ButtonGroup>
                                      <Button
                                          size='sm'
                                          variant='light'
                                          style={{ backgroundColor: 'white' }}
                                          className='border'
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              console.log('ddd');
                                              // navigate(`/showDetail/`, {
                                              //     state: {
                                              //         row: row.original,
                                              //         title: 'Transaction',
                                              //     },
                                              // });
                                              const url = new URL(
                                                  `/showDetail/`,
                                                  window.location.origin
                                              );
                                              const params =
                                                  new URLSearchParams({
                                                      data: JSON.stringify({
                                                          row: row.original,
                                                          title: 'Transaction',
                                                      }),
                                                  });
                                              url.search = params.toString();
                                              window.open(
                                                  url.toString(),
                                                  '_blank'
                                              );
                                          }}
                                      >
                                          View
                                      </Button>
                                  </ButtonGroup>
                              </div>
                          );
                      },
                  },
              ]
            : [];

    const columns = useMemo(() => COLUMNS, []);
    const data = props.data;

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable(
            {
                columns,
                data,
                initialState: {
                    sortBy: [
                        {
                            id: 'invoice_created_at', // Column accessor
                            desc: true, // Sort in ascending order (true for descending)
                        },
                    ],
                },
            },
            useSortBy
        );
    return (
        <>
            <Table striped bordered size='sm' {...getTableProps()}>
                <thead style={{ color: 'black', backgroundColor: '#F2f2f4' }}>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getFooterGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps()
                                    )}
                                >
                                    {column.render('Header')}
                                    {!column.disableSortBy && (
                                        <span>
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <FontAwesomeIcon
                                                        icon={faSortUp}
                                                        className='ms-2'
                                                    />
                                                ) : (
                                                    <FontAwesomeIcon
                                                        icon={faSortDown}
                                                        className='ms-2'
                                                    />
                                                )
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={faSort}
                                                    className='ms-2'
                                                />
                                            )}
                                        </span>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()} style={{ borderTop: 0 }}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            style={{
                                                width: cell.column.width,
                                            }}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </>
    );
};

const Invoices = (props) => {
    let data = props.dataInvoice;
    const filter = props.filter;
    const navigate = useNavigate();

    console.log(props.filter);

    if (filter) {
        data = props.dataInvoice.filter(
            (elem) => elem.invoice_payment_status === filter
        );
    }

    return (
        <>
            {/* <Card>
        <Card.Header> */}
            {props.loading ? (
                <>
                    {' '}
                    <div
                        className='d-flex justify-content-center'
                        style={{ height: 400, alignItems: 'center' }}
                    >
                        <Spinner
                            animation='border'
                            variant='dark'
                            size='md'
                            className='me-2'
                        />
                    </div>
                </>
            ) : (
                <>
                    {data.length === 0 ? (
                        <div
                            className='d-flex justify-content-center align-items-center p-3 flex-column '
                            id={'Emptybox'}
                            style={{ cursor: 'pointer' }}
                        >
                            <p
                                // className="mt-2"
                                style={{
                                    fontSize: 20,
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}
                            >
                                {/* No Invoice Added. Start by Clicking On Add Invoice */}
                                No Invoice
                            </p>

                            <img
                                src={empty_invoice}
                                style={{
                                    height: '80px',
                                    marginTop: 30,
                                    marginBottom: 20,
                                }}
                                alt='empty_invoice'
                            ></img>
                        </div>
                    ) : (
                        <FilteringTable
                            type='Invoice'
                            data={data}
                            rowdata={props.rowdata}
                            // setInvoicedeleteModals={props.setInvoicedeleteModals}
                            // setdeleteinvoiceid={props.setdeleteinvoiceid}
                            filter={props.filter}
                        />
                    )}
                </>
            )}

            {/* </Card.Header>
      </Card> */}
        </>
    );
};

const Estimates = (props) => {
    let data = props.dataEstimate;
    const filter = props.filter;
    console.log(props.filter);
    const navigate = useNavigate();

    if (filter) {
        data = props.dataEstimate.filter(
            (elem) => elem.invoice_status === filter
        );
    }
    return (
        <>
            {/* <Card>
        <Card.Header> */}
            {data.length === 0 ? (
                <div
                    className='d-flex justify-content-center align-items-center p-3 flex-column '
                    id={'Emptybox'}
                    style={{ cursor: 'pointer' }}
                >
                    <p
                        // className="mt-2"
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            color: 'black',
                        }}
                    >
                        {/* No Invoice Added. Start by Clicking On Add Invoice */}
                        No Estimate
                    </p>

                    <img
                        src={empty_invoice}
                        style={{
                            height: '80px',
                            marginTop: 30,
                            marginBottom: 20,
                        }}
                        alt='empty_invoice'
                    ></img>
                </div>
            ) : (
                <FilteringTable
                    type='Estimate'
                    data={data}
                    rowdata={props.rowdata}
                    // setInvoicedeleteModals={props.setInvoicedeleteModals}
                    // setdeleteinvoiceid={props.setdeleteinvoiceid}
                />
            )}
            {/* </Card.Header>
      </Card> */}
        </>
    );
};

const Orders = (props) => {
    let data = props.dataOrder;
    const filter = props.filter;
    console.log(props);
    const navigate = useNavigate();
    if (filter) {
        data = props.dataOrder.filter((elem) => {
            if (filter === 'CONVERTED') {
                return elem.invoice_status === filter;
            } else if (filter === 'UNSCHEDULED') {
                return (
                    elem.invoice_time_status === 'PAST' &&
                    elem.invoice_latest_start_time === -1
                );
            } else if (filter === 'PAST') {
                return (
                    elem.invoice_time_status === 'PAST' &&
                    elem.invoice_latest_start_time !== -1
                );
            } else {
                return elem.invoice_time_status === filter;
            }
        });
    }
    return (
        <>
            {/* <Card>
        <Card.Header> */}
            {data.length === 0 ? (
                <div
                    className='d-flex justify-content-center align-items-center p-3 flex-column '
                    id={'Emptybox'}
                    style={{ cursor: 'pointer' }}
                >
                    <p
                        // className="mt-2"
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            color: 'black',
                        }}
                    >
                        {/* No Invoice Added. Start by Clicking On Add Invoice */}
                        No Job
                    </p>

                    <img
                        src={empty_invoice}
                        style={{
                            height: '80px',
                            marginTop: 30,
                            marginBottom: 20,
                        }}
                        alt='empty_invoice'
                    ></img>
                </div>
            ) : (
                <FilteringTable
                    type='Job'
                    data={data}
                    rowdata={props.rowdata}
                    // setInvoicedeleteModals={props.setInvoicedeleteModals}
                    // setdeleteinvoiceid={props.setdeleteinvoiceid}
                    filter={props.filter}
                />
            )}
            {/* </Card.Header>
      </Card> */}
        </>
    );
};

const Request = (props) => {
    let data = props.dataRequest;
    const filter = props.filter;
    console.log(props.filter);
    const navigate = useNavigate();

    if (filter) {
        data = props.dataRequest.filter(
            (elem) => elem.invoice_status === filter
        );
    }
    console.log(data);
    return (
        <>
            {/* <Card>
        <Card.Header> */}

            {data.length === 0 ? (
                <div
                    className='d-flex justify-content-center align-items-center p-3 flex-column '
                    id={'Emptybox'}
                    style={{ cursor: 'pointer' }}
                >
                    <p
                        // className="mt-2"
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            color: 'black',
                        }}
                    >
                        {/* No Invoice Added. Start by Clicking On Add Invoice */}
                        No Request
                    </p>

                    <img
                        src={empty_invoice}
                        style={{
                            height: '80px',
                            marginTop: 30,
                            marginBottom: 20,
                        }}
                        alt='empty_invoice'
                    ></img>
                </div>
            ) : (
                <FilteringTable
                    type='Request'
                    data={data}
                    rowdata={props.rowdata}
                    // setInvoicedeleteModals={props.setInvoicedeleteModals}
                    // setdeleteinvoiceid={props.setdeleteinvoiceid}
                />
            )}
            {/* </Card.Header>
      </Card> */}
        </>
    );
};

const Items = (props) => {
    let data = props.dataitems;
    const filter = props.filter;
    console.log(props.filter);
    const navigate = useNavigate();

    if (filter) {
        data = props.dataitems.filter((elem) => elem.item_type === filter);
    }
    console.log(data);
    return (
        <>
            {/* <Card>
        <Card.Header> */}

            {data.length === 0 ? (
                <div
                    className='d-flex justify-content-center align-items-center p-3 flex-column '
                    id={'Emptybox'}
                    style={{ cursor: 'pointer' }}
                >
                    <p
                        // className="mt-2"
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            color: 'black',
                        }}
                    >
                        {/* No Invoice Added. Start by Clicking On Add Invoice */}
                        No Items
                    </p>

                    <img
                        src={empty_invoice}
                        style={{
                            height: '80px',
                            marginTop: 30,
                            marginBottom: 20,
                        }}
                        alt='empty_invoice'
                    ></img>
                </div>
            ) : (
                <FilteringTable
                    type='Item'
                    data={data}
                    rowdata={props.rowdata}
                    // setInvoicedeleteModals={props.setInvoicedeleteModals}
                    // setdeleteinvoiceid={props.setdeleteinvoiceid}
                />
            )}
            {/* </Card.Header>
      </Card> */}
        </>
    );
};

const Expenses = (props) => {
    let data = props.dataExpense;
    const filter = props.filter;
    console.log(props.filter);
    const navigate = useNavigate();

    if (filter) {
        data = props.dataExpense.filter((elem) => elem.item_type === filter);
    }
    console.log(data);
    return (
        <>
            {/* <Card>
        <Card.Header> */}

            {data.length === 0 ? (
                <div
                    className='d-flex justify-content-center align-items-center p-3 flex-column '
                    id={'Emptybox'}
                    style={{ cursor: 'pointer' }}
                >
                    <p
                        // className="mt-2"
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            color: 'black',
                        }}
                    >
                        {/* No Invoice Added. Start by Clicking On Add Invoice */}
                        No Expense
                    </p>

                    <img
                        src={empty_invoice}
                        style={{
                            height: '80px',
                            marginTop: 30,
                            marginBottom: 20,
                        }}
                        alt='empty_invoice'
                    ></img>
                </div>
            ) : (
                <FilteringTable
                    type='Expense'
                    data={data}
                    rowdata={props.rowdata}
                    // setInvoicedeleteModals={props.setInvoicedeleteModals}
                    // setdeleteinvoiceid={props.setdeleteinvoiceid}
                />
            )}
            {/* </Card.Header>
      </Card> */}
        </>
    );
};
const Timesheets = (props) => {
    let data = props.dataTimesheet;
    const filter = props.filter;
    console.log(props.filter);
    const navigate = useNavigate();

    if (filter) {
        data = props.dataTimesheet.filter((elem) => elem.item_type === filter);
    }
    console.log(data);
    return (
        <>
            {/* <Card>
        <Card.Header> */}

            {data.length === 0 ? (
                <div
                    className='d-flex justify-content-center align-items-center p-3 flex-column '
                    id={'Emptybox'}
                    style={{ cursor: 'pointer' }}
                >
                    <p
                        // className="mt-2"
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            color: 'black',
                        }}
                    >
                        {/* No Invoice Added. Start by Clicking On Add Invoice */}
                        No Timesheets
                    </p>

                    <img
                        src={empty_invoice}
                        style={{
                            height: '80px',
                            marginTop: 30,
                            marginBottom: 20,
                        }}
                        alt='empty_invoice'
                    ></img>
                </div>
            ) : (
                <FilteringTable
                    type='Timesheet'
                    data={data}
                    rowdata={props.rowdata}
                    // setInvoicedeleteModals={props.setInvoicedeleteModals}
                    // setdeleteinvoiceid={props.setdeleteinvoiceid}
                />
            )}
            {/* </Card.Header>
      </Card> */}
        </>
    );
};
const Transactions = (props) => {
    let data = props.dataTransactions;
    const filter = props.filter;
    console.log(props.filter);
    const navigate = useNavigate();

    if (filter) {
        data = props.dataTransactions.filter(
            (elem) => elem.item_type === filter
        );
    }
    console.log(data);
    return (
        <>
            {/* <Card>
        <Card.Header> */}

            {data.length === 0 ? (
                <div
                    className='d-flex justify-content-center align-items-center p-3 flex-column '
                    id={'Emptybox'}
                    style={{ cursor: 'pointer' }}
                >
                    <p
                        // className="mt-2"
                        style={{
                            fontSize: 20,
                            fontWeight: 'bold',
                            color: 'black',
                        }}
                    >
                        {/* No Invoice Added. Start by Clicking On Add Invoice */}
                        No Transactions
                    </p>

                    <img
                        src={empty_invoice}
                        style={{
                            height: '80px',
                            marginTop: 30,
                            marginBottom: 20,
                        }}
                        alt='empty_invoice'
                    ></img>
                </div>
            ) : (
                <FilteringTable
                    type='Transaction'
                    data={data}
                    rowdata={props.rowdata}
                    // setInvoicedeleteModals={props.setInvoicedeleteModals}
                    // setdeleteinvoiceid={props.setdeleteinvoiceid}
                />
            )}
            {/* </Card.Header>
      </Card> */}
        </>
    );
};

const Portal = (props) => {
    let data = props.dataPortal;
    return (
        <>
            {data && data.hero_image ? (
                <div style={{ marginBottom: 20 }}>
                    <div
                        style={{
                            fontSize: 20,
                            color: 'black',
                            fontWeight: 'bold',
                            marginBottom: 10,
                        }}
                    >
                        Hero Image
                    </div>
                    <img
                        src={data.hero_image}
                        style={{ maxHeight: 180, maxWidth: 180 }}
                    />
                </div>
            ) : (
                <></>
            )}
            {data && data.services_list && data.services_list.length !== 0 ? (
                <div style={{ marginBottom: 20 }}>
                    <div
                        style={{
                            fontSize: 20,
                            color: 'black',
                            fontWeight: 'bold',
                            marginBottom: 10,
                        }}
                    >
                        Services
                    </div>
                    {data.services_list.map((elem) => (
                        <div style={{ marginTop: 10 }}>
                            {elem.title ? (
                                <>
                                    <div
                                        style={{
                                            fontSize: 13,
                                            fontWeight: 'bold',
                                            color: 'black',
                                        }}
                                    >
                                        Title
                                    </div>
                                    <div>{elem.title}</div>
                                </>
                            ) : (
                                <></>
                            )}
                            {elem.description ? (
                                <>
                                    <div
                                        style={{
                                            fontSize: 13,
                                            fontWeight: 'bold',
                                            color: 'black',
                                        }}
                                    >
                                        Description
                                    </div>
                                    <div>{elem.description}</div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <></>
            )}
            {data && data.about_us && Object.keys.length !== 0 ? (
                <>
                    <div style={{ marginBottom: 20 }}>
                        <div
                            style={{
                                fontSize: 20,
                                color: 'black',
                                fontWeight: 'bold',
                                marginBottom: 10,
                            }}
                        >
                            About Us
                        </div>

                        {data &&
                        data.about_us &&
                        Object.keys(data.about_us).includes('image') &&
                        data.about_us.image.length !== 0 ? (
                            <>
                                <img
                                    src={data.about_us.image}
                                    style={{
                                        maxHeight: 180,
                                        maxWidth: 180,
                                        marginBottom: 10,
                                    }}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                        {data &&
                        data.about_us &&
                        Object.keys(data.about_us).includes('description') &&
                        data.about_us.description.length !== 0 ? (
                            <div>{data.about_us.description}</div>
                        ) : (
                            <></>
                        )}
                    </div>
                </>
            ) : (
                <></>
            )}
            {data && data.team_members && data.team_members.length !== 0 ? (
                <div style={{ marginBottom: 20 }}>
                    <div
                        style={{
                            fontSize: 20,
                            color: 'black',
                            fontWeight: 'bold',
                            marginBottom: 10,
                        }}
                    >
                        Team Members
                    </div>
                    {data.team_members.map((elem) => (
                        <div style={{ marginBottom: 20 }}>
                            {elem.image ? (
                                <>
                                    <img
                                        src={elem.image}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                            {elem.name ? (
                                <>
                                    <div style={{ marginTop: 10 }}>
                                        {elem.name}
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    ))}
                </div>
            ) : (
                <></>
            )}
            {data && data.gallery_images && data.gallery_images.length !== 0 ? (
                <div style={{ marginBottom: 20 }}>
                    <div
                        style={{
                            fontSize: 20,
                            color: 'black',
                            fontWeight: 'bold',
                            marginBottom: 10,
                        }}
                    >
                        Image Gallery
                    </div>
                    {data.gallery_images.map((elem) => (
                        <span style={{ margin: 10 }}>
                            {elem.image ? (
                                <>
                                    <img
                                        src={elem.image}
                                        style={{
                                            maxHeight: 150,
                                            maxWidth: 150,
                                        }}
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </span>
                    ))}
                </div>
            ) : (
                <></>
            )}
            <div
                style={{
                    fontSize: 20,
                    color: 'black',
                    fontWeight: 'bold',
                    marginBottom: 10,
                }}
            >
                Business Hours
            </div>
            <div style={{ wordBreak: 'break-word' }}>
                {data &&
                data.working_hours &&
                Object.keys(data.working_hours).length !== 0 &&
                Object.keys(data.working_hours).includes('business_hours') &&
                data.working_hours.business_hours.length > 0
                    ? data.working_hours.business_hours
                          .sort((a, b) => {
                              return (
                                  daysOfWeek.indexOf(a.day) -
                                  daysOfWeek.indexOf(b.day)
                              );
                          })
                          .map((day) => {
                              return (
                                  <div
                                      style={{ display: 'flex', marginTop: 3 }}
                                  >
                                      <div
                                          style={{
                                              fontSize: '0.8rem',
                                              // color: "#a6a6a6",
                                              width: 85,
                                          }}
                                      >
                                          {capitalizeFirstLetter(day.day)}
                                      </div>
                                      <div
                                          style={{
                                              fontSize: '0.8rem',
                                              // color: "#a6a6a6",
                                              whiteSpace: 'pre-line',
                                              // backgroundColor: "pink",
                                          }}
                                      >
                                          {`${day.intervals
                                              .map(
                                                  (interval) =>
                                                      `${convertTo12HourFormat(
                                                          interval.from
                                                      )} - ${convertTo12HourFormat(
                                                          interval.to
                                                      )}\n`
                                              )
                                              .join('')}`}
                                      </div>
                                  </div>
                              );
                          })
                    : '-'}
            </div>
        </>
    );
};

function subscriptionText(rowData) {
    var result = '';
    if (rowData.ios_billing) {
        if (rowData.ios_billing.is_premium == true) {
            result = 'iOS Subscription (Active): ';
        } else {
            result = 'iOS Subscription (Discontinued): ';
        }
        var productId = rowData.ios_billing.transaction_product_id;
        productId = productId
            .replace(/_/g, ' ')
            .replace(/(?:^|\s)\S/g, function (a) {
                return a.toUpperCase();
            });
        result = result + productId;
    } else if (rowData.play_billing) {
        if (rowData.play_billing.is_premium == true) {
            result = 'Android Subscription (Active): ';
        } else {
            result = 'Android Subscription (Discontinued): ';
        }
        var productId = rowData.play_billing.product_id;
        productId = productId.replace(/^android\./, '');
        productId = productId
            .replace(/_/g, ' ')
            .replace(/(?:^|\s)\S/g, function (a) {
                return a.toUpperCase();
            });
        result = result + productId;
    } else {
        result = 'N/A';
    }
    return result;
}

function subscriptionTime(rowData) {
    var result = '';
    if (rowData.ios_billing) {
        result =
            moment(rowData.ios_billing.transaction_purchase_time).format(
                'MMM Do YYYY'
            ) +
            ' - ' +
            moment(rowData.ios_billing.transaction_end_time).format(
                'MMM Do YYYY'
            );
        if (rowData.ios_billing.is_premium == true) {
            result += ' (Active)';
        } else {
            result += ' (Discontinued)';
        }
    } else if (rowData.play_billing) {
        result =
            moment(rowData.play_billing.purchase_time).format('MMM Do YYYY') +
            ' - ' +
            moment(rowData.play_billing.end_time).format('MMM Do YYYY');
        if (rowData.play_billing.is_premium == true) {
            result += ' (Active)';
        } else {
            result += ' (Discontinued)';
        }
    } else {
        result = 'N/A';
    }
    return result;
}

const ViewPartner = (props) => {
    let usercurrency = localStorage.getItem('currency') || 'USD';
    const { id } = useParams();
    const navigate = useNavigate();
    const [pending, setpending] = useState(0);
    const [totalinvoice, settotalinvoice] = useState(0);
    const { isOpen } = useSidebar();
    const [openModals, setOpenModals] = useState(false);
    const [rowdata, setRowdata] = useState({});
    const [timeline, setTimeline] = useState([]);
    const [token, settoken] = useState('');
    const notyf = useContext(NotyfContext);
    const [loading, setloading] = useState(true);
    const [data, setData] = useState([]);
    const [activeTab, setactiveTab] = useState('first');
    const [dataInvoice, setDatainvoice] = useState([]);
    const [dataEstimate, setDataestimate] = useState([]);
    const [dataRequest, setDataRequest] = useState([]);
    const [dataOrder, setDataorder] = useState([]);
    const [items, setItems] = useState([]);
    const [appointments, setAppointments] = useState([]);
    const [expenses, setExpense] = useState([]);
    const [timesheets, setTimesheet] = useState([]);
    const [transactions, setTransaction] = useState([]);
    const [portal, setPortal] = useState({});
    const [nothavemodal, setNothavemodal] = useState(false);
    const [savenote, setSavenote] = useState(false);
    const [saveresponse, setSaveresponse] = useState(false);
    const [sendmsgmodal, setSendmsgmodal] = useState(false);
    const [filter, setFilter] = useState('');
    const [timelineFilter, setTimelineFilter] = useState('');
    const [fetcherror, setFetcherror] = useState(false);
    const [nothavetype, setNothavetype] = useState('Phone');
    const [opendeleteModals, setOpendeleteModals] = useState(false);
    const [unsubscribetype, setUnsubscribetype] = useState('');
    const [showRightBox, setShowRightBox] = useState(true);
    const getInvoicedata = async () => {
        var user = firebase.auth().currentUser;
        if (user) {
            let tokenss = await user.getIdToken();
            fetch(`https://bestmate.us/api/crm/get/${id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + tokenss,
                },
            })
                .then((res) => {
                    if (!res.ok) {
                        setFetcherror(true);
                        setloading(false);
                        throw new Error(
                            `HTTP error, status code ${res.status}`
                        );
                    }
                    return res.json();
                })
                .then((result) => {
                    setRowdata({
                        ...result.profile,
                        mixpanel: result.mixpanelLink
                            ? result.mixpanelLink
                            : '',
                    });
                    setData(result.invoices);
                    setItems(result.items);
                    setAppointments(result.appointments);
                    setExpense(result.expenses);
                    setTimesheet(result.timesheets);
                    setTimeline(result.history);
                    setTransaction(result.transactions);
                    setPortal((result && result.portal) || {});

                    let total = 0;
                    result.invoices.forEach((elem) => {
                        return (total +=
                            (isNaN(elem.invoice_grand_total)
                                ? 0
                                : elem.invoice_grand_total) -
                            (isNaN(elem.invoice_settled_amount)
                                ? 0
                                : elem.invoice_settled_amount));
                    });
                    console.log(total);
                    setpending(total);
                    setactiveTab('first');
                    setFilter('');
                    setTimelineFilter('');
                    setloading(false);
                })
                .catch((error) => {
                    console.error('Error fetching data: ', error);
                });
        }
        // });
    };

    useEffect(async () => {
        // setloading(true);
        if (loading) {
            setFetcherror(false);
            setRowdata({});
            setTimeline([]);
            setData([]);
            setactiveTab('first');

            var user = firebase.auth().currentUser;

            if (user) {
                let tokenss = await user.getIdToken();
                settoken(tokenss);
                getInvoicedata(tokenss);
            }
        }
    }, [loading]);
    useEffect(() => {
        console.log(data);
        const Inv = [];
        const Est = [];
        const Ord = [];
        const Req = [];
        data.filter((elem) => {
            if (elem.invoice_workflow_type === 'INVOICE') {
                Inv.push(elem);
            } else if (elem.invoice_workflow_type === 'ESTIMATE') {
                Est.push(elem);
            } else if (elem.invoice_workflow_type === 'ORDER') {
                Ord.push(elem);
            } else if (elem.invoice_workflow_type === 'REQUEST') {
                Req.push(elem);
            }
        });
        setDatainvoice(Inv);
        setDataestimate(Est);
        setDataorder(Ord);
        setDataRequest(Req);
        // const totalinvoice = dataInvoice.length;
        settotalinvoice(Inv.length);
    }, [data]);

    useEffect(() => {
        setFilter('');
    }, [activeTab]);

    const noteSubmitHandler = async (elem) => {
        const dat = {
            customerId: id,
            clientEmail: rowdata.business_email,
            textBody: elem.subject,
        };
        var user = firebase.auth().currentUser;

        if (user) {
            let tokenss = await user.getIdToken();
            fetch('https://bestmate.us/api/crm/saveNote', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + tokenss,
                },
                body: JSON.stringify(dat),
            })
                .then(async (res) => {
                    if (res.ok) {
                        notyf.open({
                            type: isOpen ? 'home' : 'full',
                            message: `Note Saved Successfully`,
                            ripple: 'true',
                            dismissible: 'true',
                        });
                        setloading(true);
                    } else {
                        notyf.open({
                            type: isOpen ? 'home' : 'full',
                            message: `Something went wrong!`,
                            ripple: 'true',
                            dismissible: 'true',
                        });
                    }
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                    // setMailstatus("fail");
                });
        }

        setSavenote(!savenote);
    };

    const responseSubmitHandler = async (elem) => {
        const dat = {
            customerId: id,
            clientEmail: rowdata.business_email,
            textBody: elem.subject,
        };
        var user = firebase.auth().currentUser;

        if (user) {
            let tokenss = await user.getIdToken();
            fetch('https://bestmate.us/api/crm/setAsResponded', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + tokenss,
                },
                body: JSON.stringify(dat),
            })
                .then(async (res) => {
                    if (res.ok) {
                        notyf.open({
                            type: isOpen ? 'home' : 'full',
                            message: `Responded Successfully`,
                            ripple: 'true',
                            dismissible: 'true',
                        });
                        setloading(true);
                    } else {
                        notyf.open({
                            type: isOpen ? 'home' : 'full',
                            message: `Something went wrong!`,
                            ripple: 'true',
                            dismissible: 'true',
                        });
                    }
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                    // setMailstatus("fail");
                });
        }

        setSaveresponse(!saveresponse);
    };
    const unsubscribeHandler = async () => {
        const dat = {
            customerId: id,
            clientEmail: rowdata.business_email,
            unsubscribeType: unsubscribetype,
            recipientPhone: rowdata.business_phone,
        };
        console.log(dat);
        var user = firebase.auth().currentUser;

        if (user) {
            let tokenss = await user.getIdToken();
            fetch('https://bestmate.us/api/crm/unsubscribe', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + tokenss,
                },
                body: JSON.stringify(dat),
            })
                .then(async (res) => {
                    if (res.ok) {
                        notyf.open({
                            type: isOpen ? 'home' : 'full',
                            message: `Unsubscribed Successfully`,
                            ripple: 'true',
                            dismissible: 'true',
                        });
                        setUnsubscribetype('');
                        setOpendeleteModals(!opendeleteModals);
                        setloading(true);
                    } else {
                        notyf.open({
                            type: isOpen ? 'home' : 'full',
                            message: `Something went wrong!`,
                            ripple: 'true',
                            dismissible: 'true',
                        });
                        setUnsubscribetype('');
                        setOpendeleteModals(!opendeleteModals);
                    }
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                    setUnsubscribetype('');
                    setOpendeleteModals(!opendeleteModals);
                    // setMailstatus("fail");
                });
        }
    };

    const phonesendHandler = async (elem) => {
        const dat = {
            customerId: id,
            clientEmail: rowdata.business_email,
            textBody: elem.subject,
            recipientPhone: elem.phone,
        };
        var user = firebase.auth().currentUser;

        if (user) {
            let tokenss = await user.getIdToken();
            fetch('https://bestmate.us/api/crm/sendText', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + tokenss,
                },
                body: JSON.stringify(dat),
            })
                .then(async (res) => {
                    if (res.ok) {
                        notyf.open({
                            type: isOpen ? 'home' : 'full',
                            message: `Message Sent Successfully`,
                            ripple: 'true',
                            dismissible: 'true',
                        });
                        setloading(true);
                    } else {
                        notyf.open({
                            type: isOpen ? 'home' : 'full',
                            message: `Something went wrong!`,
                            ripple: 'true',
                            dismissible: 'true',
                        });
                    }
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                    // setMailstatus("fail");
                });
        }

        setSendmsgmodal(!sendmsgmodal);
    };

    return (
        <>
            {!loading ? (
                <>
                    {!fetcherror && rowdata && data ? (
                        <>
                            <Card
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    // backgroundColor: "white",
                                    padding: '0rem 1.5rem 2rem 1.5rem',
                                }}
                                className='pt-2 pb-2 border-bottom '
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            marginRight: 15,
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => navigate('/customer')}
                                    >
                                        <ArrowLeft
                                            size={30}
                                            strokeWidth={1}
                                            stroke='black'
                                        />
                                    </div>
                                    <div>
                                        <h2
                                            className='mt-3 lead'
                                            style={{
                                                fontSize: '24px',
                                                // , fontWeight: 100
                                            }}
                                        >
                                            {rowdata.business_user_name
                                                ? rowdata.business_user_name
                                                : 'Customer'}
                                        </h2>
                                        <Breadcrumb
                                            className='mb-0 lead'
                                            style={{ fontSize: '13px' }}
                                        >
                                            <Breadcrumb.Item active>
                                                CRM
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item
                                                active
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    navigate('/customer');
                                                }}
                                            >{`${props.type}s`}</Breadcrumb.Item>
                                            <Breadcrumb.Item
                                                active
                                                className='font-weight-bold'
                                            >
                                                {rowdata.business_user_name
                                                    ? rowdata.business_user_name
                                                    : 'Customer Detail'}
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                    </div>
                                </div>

                                <div
                                    style={{
                                        marginRight: '21px',
                                        display: 'flex',
                                    }}
                                >
                                    <div className='dropdown'>
                                        <div
                                            // className="dropdown-toggle"
                                            style={{
                                                // border: "2px #001A66 solid",
                                                border: '1px #838485 solid',
                                                borderRadius: '3px',
                                                padding: '0.375rem 0.75rem',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                color: '#676a6c',
                                                alignItems: 'center',
                                                textDecoration: 'none',
                                                fontSize: 'inherit',
                                                cursor: 'pointer',
                                                marginRight: '6px',
                                                fontWeight: 'bold',
                                            }}
                                            type='button'
                                            id='dropdownMenu2'
                                            data-toggle='dropdown'
                                            aria-haspopup='true'
                                            aria-expanded='false'
                                        >
                                            Unsubscribe
                                            <div className='dropdown-toggle ms-2'></div>
                                        </div>

                                        <div
                                            className='dropdown-menu'
                                            aria-labelledby='dropdownMenu2'
                                        >
                                            <button
                                                className='dropdown-item'
                                                type='button'
                                                eventKey='3'
                                                onClick={() => {
                                                    setUnsubscribetype('ALL');
                                                    setOpendeleteModals(true);
                                                }}
                                            >
                                                All
                                            </button>

                                            <button
                                                className='dropdown-item'
                                                type='button'
                                                eventKey='3'
                                                onClick={() => {
                                                    setUnsubscribetype(
                                                        'ALL_EMAILS'
                                                    );
                                                    setOpendeleteModals(true);
                                                }}
                                            >
                                                All Emails
                                            </button>
                                            <button
                                                className='dropdown-item'
                                                type='button'
                                                eventKey='3'
                                                onClick={() => {
                                                    setUnsubscribetype(
                                                        'TRIAL_EMAILS'
                                                    );
                                                    setOpendeleteModals(true);
                                                }}
                                            >
                                                Trial Emails
                                            </button>
                                            <button
                                                className='dropdown-item'
                                                type='button'
                                                eventKey='3'
                                                onClick={() => {
                                                    setUnsubscribetype(
                                                        'PERSONAL_EMAILS'
                                                    );
                                                    setOpendeleteModals(true);
                                                }}
                                            >
                                                Personal Emails
                                            </button>
                                            <button
                                                className='dropdown-item'
                                                type='button'
                                                eventKey='3'
                                                onClick={() => {
                                                    setUnsubscribetype(
                                                        'MESSAGES'
                                                    );
                                                    setOpendeleteModals(true);
                                                }}
                                            >
                                                Messages
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: '#3f80ea',
                                            borderRadius: '5px',
                                            padding: '8px 15px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            color: 'white',
                                            alignItems: 'center',
                                            textDecoration: 'none',
                                            cursor: 'pointer',
                                            marginRight: '6px',
                                        }}
                                        onClick={() => setSavenote(true)}
                                    >
                                        <Edit
                                            size={15}
                                            strokeWidth={3.5}
                                            stroke='white'
                                            style={{ marginRight: '5px' }}
                                        />
                                        Save Note
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: '#3f80ea',
                                            borderRadius: '5px',
                                            padding: '8px 15px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            color: 'white',
                                            alignItems: 'center',
                                            textDecoration: 'none',
                                            cursor: 'pointer',
                                            marginRight: '6px',
                                        }}
                                        onClick={() => setSaveresponse(true)}
                                    >
                                        <Edit
                                            size={15}
                                            strokeWidth={3.5}
                                            stroke='white'
                                            style={{ marginRight: '5px' }}
                                        />
                                        Mark last message as read
                                    </div>
                                    <div
                                        style={{
                                            backgroundColor: '#3f80ea',
                                            borderRadius: '5px',
                                            padding: '8px 15px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            color: 'white',
                                            alignItems: 'center',
                                            textDecoration: 'none',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => setSendmsgmodal(true)}
                                    >
                                        <Send
                                            size={15}
                                            strokeWidth={3.5}
                                            stroke='white'
                                            style={{ marginRight: '5px' }}
                                        />
                                        Send Message
                                    </div>
                                </div>
                            </Card>
                            <div style={{ padding: '0rem 1.5rem 2rem 1.5rem' }}>
                                <Row>
                                    {showRightBox ? (
                                        <Col lg='4'>
                                            <Card
                                                className='mb-0 border rounded-0'
                                                style={{ overflow: 'scroll' }}
                                            >
                                                <Card.Body>
                                                    <div>
                                                        <div
                                                            style={{
                                                                position:
                                                                    'absolute',
                                                                right: 15,
                                                                top: 15,
                                                                color: 'red',
                                                                cursor: 'pointer',
                                                                fontSize: 12,
                                                            }}
                                                            onClick={() =>
                                                                setShowRightBox(
                                                                    false
                                                                )
                                                            }
                                                        >
                                                            Hide Panel
                                                        </div>
                                                        <div
                                                            className='mb-0'
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                fontSize: 20,
                                                                color: 'black',
                                                                fontWeight:
                                                                    'bold',
                                                            }}
                                                        >{`${
                                                            rowdata.business_user_name
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                            rowdata.business_user_name.slice(
                                                                1
                                                            )
                                                        }`}</div>
                                                        <div
                                                            style={{
                                                                fontSize: 15,
                                                                textAlign:
                                                                    'center',
                                                            }}
                                                        >
                                                            {rowdata.business_name
                                                                ? rowdata.business_name
                                                                      .charAt(0)
                                                                      .toUpperCase() +
                                                                  rowdata.business_name.slice(
                                                                      1
                                                                  )
                                                                : ''}
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                rowdata.business_logo
                                                            }
                                                            style={{
                                                                maxHeight: 80,
                                                                maxWidth: 80,
                                                            }}
                                                        />
                                                    </div>

                                                    <div
                                                        style={{
                                                            marginTop: 15,

                                                            width: '100%',
                                                            marginBottom: 10,
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <a
                                                            onClick={
                                                                rowdata.business_phone
                                                                    ? (e) => {
                                                                          e.stopPropagation();
                                                                          window.location = `tel:${rowdata.business_phone}`;
                                                                      }
                                                                    : (e) => {
                                                                          e.stopPropagation();
                                                                          setNothavetype(
                                                                              'Phone'
                                                                          );
                                                                          setNothavemodal(
                                                                              true
                                                                          );
                                                                      }
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    '#DCF1ED',
                                                                padding:
                                                                    '8px 12px',
                                                                margin: 5,
                                                                borderRadius: 10,
                                                            }}
                                                        >
                                                            <Phone
                                                                size={17}
                                                                strokeWidth={2}
                                                                stroke='#09B29C'
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                        </a>

                                                        <a
                                                            onClick={
                                                                rowdata.business_email
                                                                    ? (e) => {
                                                                          e.stopPropagation();
                                                                          window.location = `mailto:${rowdata.business_email}`;
                                                                      }
                                                                    : (e) => {
                                                                          e.stopPropagation();
                                                                          setNothavetype(
                                                                              'Email'
                                                                          );
                                                                          setNothavemodal(
                                                                              true
                                                                          );
                                                                      }
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    '#FDE4DF',
                                                                padding:
                                                                    '8px 12px',

                                                                margin: 5,
                                                                borderRadius: 10,
                                                            }}
                                                        >
                                                            <Mail
                                                                size={17}
                                                                strokeWidth={2}
                                                                stroke='#EF6547'
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                        </a>

                                                        <a
                                                            // href={
                                                            //   rowdata.partner_number ? "tel:8178099375" : () => {}
                                                            // }
                                                            style={{
                                                                backgroundColor:
                                                                    '#FEF2E0',
                                                                padding:
                                                                    '8px 12px',

                                                                margin: 5,
                                                                borderRadius: 10,
                                                            }}
                                                        >
                                                            <MessageSquare
                                                                size={17}
                                                                strokeWidth={2}
                                                                stroke='#F6B84B'
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                        </a>
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                cursor: 'pointer',
                                                                color: '#3f80ea',
                                                                padding:
                                                                    '1px 10px',
                                                                marginTop: 5,
                                                                borderRadius: 5,
                                                                border: '1px solid #3f80ea',
                                                            }}
                                                            onClick={() => {
                                                                window.open(
                                                                    rowdata.mixpanel,
                                                                    '_blank'
                                                                );
                                                            }}
                                                        >
                                                            To Mixpanel
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            maxWidth: 250,
                                                            margin: 'auto',
                                                            // display: "flex",
                                                            // alignItems: "center",
                                                            // justifyContent: "center",
                                                            marginTop: 15,
                                                            marginBottom: 20,
                                                        }}
                                                    >
                                                        {/* <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "space-around ",
                            }}
                          >
                            <div
                              style={{
                                // width: "70px",
                                // height: "50px",
                                // backgroundColor: "pink",
                                border: "solid 0.5px #ebeced",
                                borderRadius: 3,
                                padding: 5,
                                // borderWidth: "10px",
                                // borderColor: "black",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                width: 100,
                              }}
                            >
                              <div style={{ color: "black", fontSize: "14px" }}>
                                {pending.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: usercurrency,
                                })}
                              </div>
                              <div
                                style={{
                                  // fontWeight: "bold",
                                  color: "grey",
                                  fontSize: 11,
                                }}
                              >
                                Pending
                              </div>
                            </div>
                            <div
                              style={{
                                // width: "70px",
                                // height: "50px",
                                // border: "solid 0.5px grey",
                                // borderRadius: 3,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                border: "solid 0.5px #ebeced",
                                borderRadius: 3,
                                padding: 5,
                                width: 100,

                                // backgroundColor: "pink",
                                // borderWidth: 1,
                                // borderColor: "grey",
                              }}
                            >
                              <div style={{ color: "black", fontSize: 14 }}>
                                {totalinvoice}
                              </div>
                              <div
                                style={{
                                  // fontWeight: "bold",
                                  color: "grey",
                                  fontSize: 11,
                                }}
                              >
                                Invoice
                              </div>
                            </div>
                          </div> */}
                                                    </div>
                                                    <Table
                                                        size='sm'
                                                        className='my-2'
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <th
                                                                    style={{
                                                                        color: '#545b61',
                                                                    }}
                                                                >
                                                                    Name
                                                                </th>
                                                                <td>
                                                                    {rowdata.business_user_name
                                                                        ? rowdata.business_user_name
                                                                              .charAt(
                                                                                  0
                                                                              )
                                                                              .toUpperCase() +
                                                                          rowdata.business_user_name.slice(
                                                                              1
                                                                          )
                                                                        : 'N/A'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th
                                                                    style={{
                                                                        color: '#545b61',
                                                                    }}
                                                                >
                                                                    Business
                                                                    Name
                                                                </th>
                                                                <td>
                                                                    {rowdata.business_name
                                                                        ? rowdata.business_name
                                                                              .charAt(
                                                                                  0
                                                                              )
                                                                              .toUpperCase() +
                                                                          rowdata.business_name.slice(
                                                                              1
                                                                          )
                                                                        : 'N/A'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th
                                                                    style={{
                                                                        color: '#545b61',
                                                                    }}
                                                                >
                                                                    Email
                                                                </th>
                                                                <td>
                                                                    {rowdata.business_email
                                                                        ? rowdata.business_email
                                                                        : 'N/A'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th
                                                                    style={{
                                                                        color: '#545b61',
                                                                    }}
                                                                >
                                                                    Phone
                                                                </th>
                                                                <td>
                                                                    {rowdata.business_phone
                                                                        ? rowdata.business_phone
                                                                        : 'N/A'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th
                                                                    style={{
                                                                        color: '#545b61',
                                                                    }}
                                                                >
                                                                    Industry
                                                                </th>
                                                                <td>
                                                                    {rowdata.business_industry
                                                                        ? rowdata.business_industry
                                                                        : 'N/A'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th
                                                                    style={{
                                                                        color: '#545b61',
                                                                    }}
                                                                >
                                                                    Platform
                                                                </th>
                                                                <td>
                                                                    {rowdata.business_platform
                                                                        ? rowdata.business_platform
                                                                        : 'N/A'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th
                                                                    style={{
                                                                        color: '#545b61',
                                                                    }}
                                                                >
                                                                    Subscription
                                                                </th>
                                                                <td>
                                                                    {subscriptionText(
                                                                        rowdata
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th
                                                                    style={{
                                                                        color: '#545b61',
                                                                    }}
                                                                >
                                                                    Subscription
                                                                    Period
                                                                </th>
                                                                <td>
                                                                    {subscriptionTime(
                                                                        rowdata
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th
                                                                    style={{
                                                                        color: '#545b61',
                                                                    }}
                                                                >
                                                                    Created At
                                                                </th>
                                                                <td>
                                                                    {rowdata.business_created_at
                                                                        ? moment(
                                                                              new Date(
                                                                                  rowdata.business_created_at
                                                                              )
                                                                          ).format(
                                                                              'MMM DD YYYY, hh:mm a'
                                                                          )
                                                                        : 'N/A'}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th
                                                                    style={{
                                                                        color: '#545b61',
                                                                    }}
                                                                >
                                                                    Currency
                                                                </th>
                                                                <td>
                                                                    {rowdata.business_currency_code
                                                                        ? rowdata.business_currency_code
                                                                        : 'N/A'}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                    <div
                                                        style={{
                                                            padding: '0 8px',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                                color: '#545b61',
                                                            }}
                                                        >
                                                            Address
                                                        </div>
                                                        <div
                                                            style={{
                                                                marginLeft: 1,
                                                                marginTop: 2,
                                                                fontSize: 12.5,
                                                                color: 'grey',
                                                            }}
                                                        >
                                                            {rowdata.business_address &&
                                                            Object.keys(
                                                                rowdata.business_address
                                                            ).length !== 0
                                                                ? ''
                                                                : 'N/A'}
                                                            {rowdata.business_address &&
                                                            rowdata
                                                                .business_address
                                                                .street
                                                                ? rowdata
                                                                      .business_address
                                                                      .street
                                                                : ''}

                                                            <div>
                                                                {rowdata.business_address &&
                                                                rowdata
                                                                    .business_address
                                                                    .city
                                                                    ? rowdata
                                                                          .business_address
                                                                          .city
                                                                    : ''}
                                                                {rowdata.business_address &&
                                                                rowdata
                                                                    .business_address
                                                                    .city &&
                                                                rowdata
                                                                    .business_address
                                                                    .state
                                                                    ? ', '
                                                                    : ''}
                                                                {rowdata.business_address &&
                                                                rowdata
                                                                    .business_address
                                                                    .state
                                                                    ? rowdata
                                                                          .business_address
                                                                          .state
                                                                    : ''}
                                                            </div>
                                                            <div>
                                                                {rowdata.business_address &&
                                                                rowdata
                                                                    .business_address
                                                                    .country
                                                                    ? rowdata
                                                                          .business_address
                                                                          .country
                                                                    : ''}
                                                                {rowdata.business_address &&
                                                                rowdata
                                                                    .business_address
                                                                    .country &&
                                                                rowdata
                                                                    .business_address
                                                                    .zip_code
                                                                    ? ' - '
                                                                    : ''}
                                                                {rowdata.business_address &&
                                                                rowdata
                                                                    .business_address
                                                                    .zip_code
                                                                    ? rowdata
                                                                          .business_address
                                                                          .zip_code
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr
                                                        style={{
                                                            borderColor:
                                                                '#dee6ed',
                                                            marginTop: 10,
                                                            marginBottom: 10,
                                                        }}
                                                    />
                                                    <div
                                                        style={{
                                                            padding: '0 8px',
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                                color: '#545b61',
                                                            }}
                                                        >
                                                            Referrer
                                                        </div>
                                                        <div
                                                            style={{
                                                                marginLeft: 1,
                                                                marginTop: 2,
                                                                fontSize: 12.5,
                                                                color: 'grey',
                                                            }}
                                                        >
                                                            {Object.keys(
                                                                rowdata
                                                            ).includes(
                                                                'business_referrer'
                                                            )
                                                                ? getObjectWithNA(
                                                                      rowdata.business_referrer
                                                                  )
                                                                : 'N/A'}
                                                        </div>
                                                    </div>
                                                    <hr
                                                        style={{
                                                            borderColor:
                                                                '#dee6ed',
                                                            marginTop: 10,
                                                            marginBottom: 10,
                                                        }}
                                                    />
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ) : (
                                        <></>
                                    )}
                                    <Col lg={showRightBox ? '8' : '12'}>
                                        {/* <Card
                      className="mb-0 border rounded-0"
                      // style={{ minHeight: "100%" }}
                    >
                      <Card.Header
                        className=""
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Card.Title className="mb-0">Timeline</Card.Title>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Filter
                            size={20}
                            strokeWidth={2}
                            stroke="grey"
                            style={{ cursor: "pointer" }}
                          />
                          <Form.Group
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // backgroundColor: "red",
                              marginLeft: 10,
                            }}
                          >
                            <Form.Label style={{ margin: 0, marginRight: 5 }}>
                              Type
                            </Form.Label>

                            <Form.Select
                              value={timelineFilter}
                              style={{ width: 130 }}
                              onChange={(e) => {
                                // controller.abort();
                                // setsearchloading(true);
                                // setSearchdata([]);
                                // setSearchid(0);
                                // setSearchhasmore(true);
                                // setTempsearchtotal(e.target.value);
                                // handleSearch2(e.target.value);
                                setTimelineFilter(e.target.value);
                              }}
                            >
                              <option value="">All</option>
                              <option value="EMAILS">Email</option>
                              <option value="MESSAGES">Messages</option>
                              <option value="NOTES">Notes</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                      </Card.Header>
                      <Card.Body
                        className="pt-2"
                        style={{ maxHeight: "400px", overflow: "scroll" }}
                      >
                        <ul className="timeline m-3">
                          {timeline
                            .filter((elem) => {
                              if (timelineFilter === "MESSAGES") {
                                return (
                                  elem.type === "INCOMING_TEXT_MESSAGE" ||
                                  elem.type === "RESPONDED" ||
                                  elem.type === "OUTGOING_TEXT_MESSAGE"
                                );
                              } else if (timelineFilter === "NOTES") {
                                return elem.type === "NOTE";
                              } else if (timelineFilter === "EMAILS") {
                                return elem.type === "OUTGOING_AUTOMATED_EMAIL";
                              } else {
                                return elem;
                              }
                            })
                            .map((elem) => {
                              if (elem.type === "INCOMING_TEXT_MESSAGE") {
                                return (
                                  <>
                                    <li className="timeline-item">
                                      <div className="d-flex">
                                        <div
                                          className="flex-grow-1"
                                          style={{ lineHeight: 1.3 }}
                                        >
                                          <small className="float-end text-navy">
                                            {timeAgoFromTimestampWithMillis(
                                              elem.created_at
                                            )}
                                          </small>

                                          <span style={{ color: "#408af7" }}>
                                            Recevied{" "}
                                          </span>
                                          a <strong style={{}}>Message</strong>
                                          <br />
                                          <small className="text-muted">
                                            {moment(
                                              new Date(elem.created_at)
                                            ).format(
                                              "MMM DD YYYY, ddd hh:mm a"
                                            )}
                                          </small>
                                          <div
                                            style={{
                                              marginLeft: 30,
                                              marginRight: 30,
                                              marginTop: 10,
                                              marginBottom: 10,
                                            }}
                                          >
                                            <div
                                              className="border text-md p-2 mt-1"
                                              style={{
                                                whiteSpace: "pre-line",
                                                backgroundColor: "#90b9f5",
                                                color: "black",
                                              }}
                                            >
                                              {elem.body}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <hr />
                                    </li>
                                  </>
                                );
                              } else if (
                                elem.type === "OUTGOING_TEXT_MESSAGE"
                              ) {
                                return (
                                  <>
                                    <li className="timeline-item">
                                      <div className="d-flex">
                                        <div
                                          className="flex-grow-1"
                                          style={{ lineHeight: 1.3 }}
                                        >
                                          <small className="float-end text-navy">
                                            {timeAgoFromTimestampWithMillis(
                                              elem.created_at
                                            )}
                                          </small>
                                          <span style={{ color: "#09B29C" }}>
                                            Sent{" "}
                                          </span>
                                          a <strong style={{}}>Message</strong>
                                          <br />
                                          <small className="text-muted">
                                            {moment(
                                              new Date(elem.created_at)
                                            ).format(
                                              "MMM DD YYYY, ddd hh:mm a"
                                            )}
                                          </small>
                                          <div
                                            style={{
                                              marginLeft: 30,
                                              marginRight: 30,
                                              marginTop: 20,
                                              marginBottom: 10,
                                            }}
                                          >
                                            <div
                                              className="border text-md p-2 mt-1"
                                              style={{
                                                whiteSpace: "pre-line",
                                                backgroundColor: "#DCF1ED",
                                                color: "black",
                                              }}
                                            >
                                              {elem.body}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <hr />
                                    </li>
                                  </>
                                );
                              } else if (elem.type === "NOTE") {
                                return (
                                  <>
                                    <li className="timeline-item">
                                      <div className="d-flex">
                                        <div
                                          className="flex-grow-1"
                                          style={{ lineHeight: 1.3 }}
                                        >
                                          <small className="float-end text-navy">
                                            {timeAgoFromTimestampWithMillis(
                                              elem.created_at
                                            )}
                                          </small>
                                          New<strong> Note</strong> added
                                          <br />
                                          <small className="text-muted">
                                            {moment(
                                              new Date(elem.created_at)
                                            ).format(
                                              "MMM DD YYYY, ddd hh:mm a"
                                            )}
                                          </small>
                                          <div
                                            style={{
                                              marginLeft: 30,
                                              marginRight: 30,
                                              marginTop: 10,
                                              marginBottom: 10,
                                            }}
                                          >
                                            <p
                                              className="border text-md p-2 mt-1"
                                              style={{
                                                whiteSpace: "pre-line",
                                                backgroundColor: "#FEF2E0",
                                                color: "black",
                                                // fontWeight: "bold",
                                              }}
                                            >
                                              {elem.body}
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <hr />
                                    </li>
                                  </>
                                );
                              } else if (elem.type === "UNSUBSCRIBE") {
                                return (
                                  <>
                                    <li className="timeline-item">
                                      <div className="d-flex">
                                        <div
                                          className="flex-grow-1"
                                          style={{ lineHeight: 1.3 }}
                                        >
                                          <small className="float-end text-navy">
                                            {timeAgoFromTimestampWithMillis(
                                              elem.created_at
                                            )}
                                          </small>
                                          <strong> Unsubscribe</strong> from{" "}
                                          <strong>
                                            {elem.unsubscribeType === "ALL"
                                              ? "All"
                                              : elem.unsubscribeType ===
                                                "ALL_EMAILS"
                                              ? "All Emails"
                                              : elem.unsubscribeType ===
                                                "TRIAL_EMAILS"
                                              ? "Trial Emails"
                                              : elem.unsubscribeType ===
                                                "PERSONAL_EMAILS"
                                              ? "Personal Emails"
                                              : elem.unsubscribeType ===
                                                "MESSAGES"
                                              ? "Messages"
                                              : ""}{" "}
                                          </strong>
                                          <br />
                                          <small className="text-muted">
                                            {moment(
                                              new Date(elem.created_at)
                                            ).format(
                                              "MMM DD YYYY, ddd hh:mm a"
                                            )}
                                          </small>
                                        </div>
                                      </div>

                                      <hr />
                                    </li>
                                  </>
                                );
                              } else if (elem.type === "RESPONDED") {
                                return (
                                  <>
                                    <li className="timeline-item">
                                      <div className="d-flex">
                                        <div
                                          className="flex-grow-1"
                                          style={{ lineHeight: 1.3 }}
                                        >
                                          <small className="float-end text-navy">
                                            {timeAgoFromTimestampWithMillis(
                                              elem.created_at
                                            )}
                                          </small>
                                          New<strong> Response</strong> added
                                          <br />
                                          <small className="text-muted">
                                            {moment(
                                              new Date(elem.created_at)
                                            ).format(
                                              "MMM DD YYYY, ddd hh:mm a"
                                            )}
                                          </small>
                                          <div
                                            style={{
                                              marginLeft: 30,
                                              marginRight: 30,
                                              marginTop: 10,
                                              marginBottom: 10,
                                            }}
                                          >
                                            <p
                                              className="border text-md p-2 mt-1"
                                              style={{
                                                whiteSpace: "pre-line",
                                                color: "black",
                                              }}
                                            >
                                              {elem.body}
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <hr />
                                    </li>
                                  </>
                                );
                              } else if (
                                elem.type === "OUTGOING_AUTOMATED_EMAIL"
                              ) {
                                return (
                                  <>
                                    <li className="timeline-item">
                                      <div className="d-flex">
                                        <div
                                          className="flex-grow-1"
                                          style={{ lineHeight: 1.3 }}
                                        >
                                          <small className="float-end text-navy">
                                            {timeAgoFromTimestampWithMillis(
                                              elem.created_at
                                            )}
                                          </small>
                                          Sent an{" "}
                                          <strong>Automated Email</strong>
                                          <br />
                                          <small className="text-muted">
                                            {moment(
                                              new Date(elem.created_at)
                                            ).format(
                                              "MMM DD YYYY, ddd hh:mm a"
                                            )}
                                          </small>
                                          <div
                                            style={{
                                              marginLeft: 30,
                                              marginRight: 30,
                                              marginTop: 20,
                                              marginBottom: 10,
                                            }}
                                          >
                                            <div
                                              className="border text-sm text-muted p-2 mt-1"
                                              style={{
                                                maxHeight: "200px",
                                                overflow: "scroll",
                                                // backgroundColor: "pink",
                                              }}
                                            >
                                              <EmailTemplate
                                                email={elem.body}
                                              />
                                            </div>
                                            <div
                                              style={{
                                                backgroundColor: "#407FEA",
                                                fontSize: "12px",
                                                width: "50px",
                                                padding: "5px",
                                                marginTop: "10px",
                                                color: "white",
                                                textAlign: "center",
                                                cursor: "pointer",
                                              }}
                                              // onClick={() =>
                                              //    navigate("/email-template")
                                              // }
                                            >
                                              Open
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <hr />
                                    </li>
                                  </>
                                );
                              }
                            })}
                        </ul>
                      </Card.Body>
                    </Card> */}
                                        <Card
                                            className='mb-0 pt-3   border rounded-0'
                                            // style={{ minHeight: "100%" }}
                                        >
                                            {/* <Card.Header className="">
                        <Card.Title className="mb-0">Work</Card.Title>
                      </Card.Header> */}
                                            <Card.Body
                                                className='pt-0'
                                                // style={{ maxHeight: "500px", overflow: "scroll" }}
                                            >
                                                <Tab.Container
                                                    id='left-tabs-example'
                                                    defaultActiveKey='first'
                                                >
                                                    <Nav
                                                        variant='tabs'
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between',
                                                            alignItems:
                                                                'center',
                                                            // backgroundColor: "pink",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',

                                                                flexWrap:
                                                                    'wrap',
                                                            }}
                                                        >
                                                            <Nav.Item
                                                                style={{
                                                                    width: 100,
                                                                    maxWidth:
                                                                        '100%',
                                                                }}
                                                            >
                                                                <Nav.Link
                                                                    className='text-dark'
                                                                    onClick={() => {
                                                                        setactiveTab(
                                                                            'first'
                                                                        );
                                                                        setFilter(
                                                                            ''
                                                                        );
                                                                    }}
                                                                    style={
                                                                        activeTab ===
                                                                        'first'
                                                                            ? {
                                                                                  borderBottom:
                                                                                      '2px solid black',
                                                                                  fontWeight:
                                                                                      'bold',
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                            : {
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                    }
                                                                    eventKey='first'
                                                                >
                                                                    Timeline
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item
                                                                style={{
                                                                    width: 100,
                                                                    maxWidth:
                                                                        '100%',
                                                                }}
                                                            >
                                                                <Nav.Link
                                                                    className='text-dark'
                                                                    onClick={() => {
                                                                        setactiveTab(
                                                                            'second'
                                                                        );
                                                                        setFilter(
                                                                            ''
                                                                        );
                                                                    }}
                                                                    style={
                                                                        activeTab ===
                                                                        'second'
                                                                            ? {
                                                                                  borderBottom:
                                                                                      '2px solid black',
                                                                                  fontWeight:
                                                                                      'bold',
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                            : {
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                    }
                                                                    eventKey='second'
                                                                >
                                                                    Estimates
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item
                                                                style={{
                                                                    width: 100,
                                                                    maxWidth:
                                                                        '100%',
                                                                }}
                                                            >
                                                                <Nav.Link
                                                                    className='text-dark'
                                                                    onClick={() => {
                                                                        setactiveTab(
                                                                            'third'
                                                                        );
                                                                        setFilter(
                                                                            ''
                                                                        );
                                                                    }}
                                                                    style={
                                                                        activeTab ===
                                                                        'third'
                                                                            ? {
                                                                                  borderBottom:
                                                                                      '2px solid black',
                                                                                  fontWeight:
                                                                                      'bold',
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                            : {
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                    }
                                                                    eventKey='third'
                                                                >
                                                                    Bookings
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item
                                                                style={{
                                                                    width: 100,
                                                                    maxWidth:
                                                                        '100%',
                                                                }}
                                                            >
                                                                <Nav.Link
                                                                    className='text-dark'
                                                                    onClick={() => {
                                                                        setactiveTab(
                                                                            'fourth'
                                                                        );
                                                                        setFilter(
                                                                            ''
                                                                        );
                                                                    }}
                                                                    style={
                                                                        activeTab ===
                                                                        'fourth'
                                                                            ? {
                                                                                  borderBottom:
                                                                                      '2px solid black',
                                                                                  fontWeight:
                                                                                      'bold',
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                            : {
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                    }
                                                                    eventKey='fourth'
                                                                >
                                                                    Invoices
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item
                                                                style={{
                                                                    width: 100,
                                                                    maxWidth:
                                                                        '100%',
                                                                }}
                                                            >
                                                                <Nav.Link
                                                                    className='text-dark'
                                                                    onClick={() => {
                                                                        setactiveTab(
                                                                            'fifth'
                                                                        );
                                                                        setFilter(
                                                                            ''
                                                                        );
                                                                    }}
                                                                    style={
                                                                        activeTab ===
                                                                        'fifth'
                                                                            ? {
                                                                                  borderBottom:
                                                                                      '2px solid black',
                                                                                  fontWeight:
                                                                                      'bold',
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                            : {
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                    }
                                                                    eventKey='fifth'
                                                                >
                                                                    Request
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            {/* <Nav.Item
                                style={{
                                  width: 100,
                                  maxWidth: "100%",
                                }}
                              >
                                <Nav.Link
                                  className="text-dark"
                                  onClick={() => {
                                    setactiveTab("sixth");
                                    setFilter("");
                                  }}
                                  style={
                                    activeTab === "sixth"
                                      ? {
                                          borderBottom: "2px solid black",
                                          fontWeight: "bold",
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                        }
                                      : {
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                        }
                                  }
                                  eventKey="sixth"
                                >
                                  Partners
                                </Nav.Link>
                              </Nav.Item> */}
                                                            <Nav.Item
                                                                style={{
                                                                    width: 100,
                                                                    maxWidth:
                                                                        '100%',
                                                                }}
                                                            >
                                                                <Nav.Link
                                                                    className='text-dark'
                                                                    onClick={() => {
                                                                        setactiveTab(
                                                                            'seventh'
                                                                        );
                                                                        setFilter(
                                                                            ''
                                                                        );
                                                                    }}
                                                                    style={
                                                                        activeTab ===
                                                                        'seventh'
                                                                            ? {
                                                                                  borderBottom:
                                                                                      '2px solid black',
                                                                                  fontWeight:
                                                                                      'bold',
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                            : {
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                    }
                                                                    eventKey='seventh'
                                                                >
                                                                    Items
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item
                                                                style={{
                                                                    width: 100,
                                                                    maxWidth:
                                                                        '100%',
                                                                }}
                                                            >
                                                                <Nav.Link
                                                                    className='text-dark'
                                                                    onClick={() => {
                                                                        setactiveTab(
                                                                            'eight'
                                                                        );
                                                                        setFilter(
                                                                            ''
                                                                        );
                                                                    }}
                                                                    style={
                                                                        activeTab ===
                                                                        'eight'
                                                                            ? {
                                                                                  borderBottom:
                                                                                      '2px solid black',
                                                                                  fontWeight:
                                                                                      'bold',
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                            : {
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                    }
                                                                    eventKey='eight'
                                                                >
                                                                    Expenses
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item
                                                                style={{
                                                                    width: 100,
                                                                    maxWidth:
                                                                        '100%',
                                                                }}
                                                            >
                                                                <Nav.Link
                                                                    className='text-dark'
                                                                    onClick={() => {
                                                                        setactiveTab(
                                                                            'ninth'
                                                                        );
                                                                        setFilter(
                                                                            ''
                                                                        );
                                                                    }}
                                                                    style={
                                                                        activeTab ===
                                                                        'ninth'
                                                                            ? {
                                                                                  borderBottom:
                                                                                      '2px solid black',
                                                                                  fontWeight:
                                                                                      'bold',
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                            : {
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                    }
                                                                    eventKey='ninth'
                                                                >
                                                                    Timesheets
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item
                                                                style={{
                                                                    width: 100,
                                                                    maxWidth:
                                                                        '100%',
                                                                }}
                                                            >
                                                                <Nav.Link
                                                                    className='text-dark'
                                                                    onClick={() => {
                                                                        setactiveTab(
                                                                            'tenth'
                                                                        );
                                                                        setFilter(
                                                                            ''
                                                                        );
                                                                    }}
                                                                    style={
                                                                        activeTab ===
                                                                        'tenth'
                                                                            ? {
                                                                                  borderBottom:
                                                                                      '2px solid black',
                                                                                  fontWeight:
                                                                                      'bold',
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                            : {
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                    }
                                                                    eventKey='tenth'
                                                                >
                                                                    Transactions
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                            <Nav.Item
                                                                style={{
                                                                    width: 100,
                                                                    maxWidth:
                                                                        '100%',
                                                                }}
                                                            >
                                                                <Nav.Link
                                                                    className='text-dark'
                                                                    onClick={() => {
                                                                        setactiveTab(
                                                                            'eleventh'
                                                                        );
                                                                        setFilter(
                                                                            ''
                                                                        );
                                                                    }}
                                                                    style={
                                                                        activeTab ===
                                                                        'eleventh'
                                                                            ? {
                                                                                  borderBottom:
                                                                                      '2px solid black',
                                                                                  fontWeight:
                                                                                      'bold',
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                            : {
                                                                                  width: '100%',
                                                                                  display:
                                                                                      'flex',
                                                                                  justifyContent:
                                                                                      'center',
                                                                              }
                                                                    }
                                                                    eventKey='eleventh'
                                                                >
                                                                    Portal
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        </div>
                                                    </Nav>

                                                    {activeTab !== 'first' ? (
                                                        <div
                                                            style={{
                                                                // backgroundColor: "pink",
                                                                // paddingRight: 10,
                                                                padding:
                                                                    '13px 0',
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'flex-end',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <Filter
                                                                size={20}
                                                                strokeWidth={2}
                                                                stroke='grey'
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                            <Form.Group
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    // backgroundColor: "red",
                                                                    marginLeft: 10,
                                                                }}
                                                            >
                                                                <Form.Label
                                                                    style={{
                                                                        margin: 0,
                                                                        marginRight: 5,
                                                                    }}
                                                                >
                                                                    Status
                                                                </Form.Label>

                                                                <Form.Select
                                                                    value={
                                                                        filter
                                                                    }
                                                                    style={{
                                                                        width: 130,
                                                                    }}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        // controller.abort();
                                                                        // setsearchloading(true);
                                                                        // setSearchdata([]);
                                                                        // setSearchid(0);
                                                                        // setSearchhasmore(true);
                                                                        // setTempsearchtotal(e.target.value);
                                                                        // handleSearch2(e.target.value);
                                                                        setFilter(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                >
                                                                    {activeTab ===
                                                                    'second' ? (
                                                                        <>
                                                                            <option value=''>
                                                                                All
                                                                            </option>
                                                                            <option value='OPEN'>
                                                                                Open
                                                                            </option>
                                                                            <option value='ACCEPTED'>
                                                                                Accepted
                                                                            </option>
                                                                            <option value='CONVERTED'>
                                                                                Converted
                                                                            </option>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {activeTab ===
                                                                            'third' ? (
                                                                                <>
                                                                                    <option value=''>
                                                                                        All
                                                                                    </option>
                                                                                    <option value='UPCOMING'>
                                                                                        Upcoming
                                                                                    </option>
                                                                                    <option value='UNSCHEDULED'>
                                                                                        Unscheduled
                                                                                    </option>
                                                                                    <option value='PAST'>
                                                                                        Past
                                                                                    </option>
                                                                                    <option value='CONVERTED'>
                                                                                        Invoiced
                                                                                    </option>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {activeTab ===
                                                                                    'fourth' ? (
                                                                                        <>
                                                                                            <option value=''>
                                                                                                All
                                                                                            </option>
                                                                                            <option value='UNPAID'>
                                                                                                Unpaid
                                                                                            </option>
                                                                                            <option value='PAID'>
                                                                                                Paid
                                                                                            </option>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            {activeTab ===
                                                                                            'fifth' ? (
                                                                                                <>
                                                                                                    <option value=''>
                                                                                                        All
                                                                                                    </option>
                                                                                                    <option value='OPEN'>
                                                                                                        Open
                                                                                                    </option>
                                                                                                    <option value='CONVERTED'>
                                                                                                        Converted
                                                                                                    </option>
                                                                                                    <option value='ARcHIVED'>
                                                                                                        Archived
                                                                                                    </option>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {activeTab ===
                                                                                                    'sixth' ? (
                                                                                                        <>
                                                                                                            <option value=''>
                                                                                                                All
                                                                                                            </option>
                                                                                                            <option value='CUSTOMER'>
                                                                                                                Customer
                                                                                                            </option>
                                                                                                            <option value='LEAD'>
                                                                                                                Lead
                                                                                                            </option>
                                                                                                            <option value='SUPPLIER'>
                                                                                                                Supplier
                                                                                                            </option>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {activeTab ===
                                                                                                            'seventh' ? (
                                                                                                                <>
                                                                                                                    <option value=''>
                                                                                                                        All
                                                                                                                    </option>
                                                                                                                    <option value='PRODUCT'>
                                                                                                                        Product
                                                                                                                    </option>
                                                                                                                    <option value='SERVICE'>
                                                                                                                        Service
                                                                                                                    </option>
                                                                                                                </>
                                                                                                            ) : (
                                                                                                                <>
                                                                                                                    <option value=''>
                                                                                                                        All
                                                                                                                    </option>
                                                                                                                </>
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            style={{
                                                                padding:
                                                                    '13px 0',
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'flex-end',
                                                                alignItems:
                                                                    'center',
                                                            }}
                                                        >
                                                            <Filter
                                                                size={20}
                                                                strokeWidth={2}
                                                                stroke='grey'
                                                                style={{
                                                                    cursor: 'pointer',
                                                                }}
                                                            />
                                                            <Form.Group
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                    // backgroundColor: "red",
                                                                    marginLeft: 10,
                                                                }}
                                                            >
                                                                <Form.Label
                                                                    style={{
                                                                        margin: 0,
                                                                        marginRight: 5,
                                                                    }}
                                                                >
                                                                    Type
                                                                </Form.Label>

                                                                <Form.Select
                                                                    value={
                                                                        timelineFilter
                                                                    }
                                                                    style={{
                                                                        width: 130,
                                                                    }}
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        // controller.abort();
                                                                        // setsearchloading(true);
                                                                        // setSearchdata([]);
                                                                        // setSearchid(0);
                                                                        // setSearchhasmore(true);
                                                                        // setTempsearchtotal(e.target.value);
                                                                        // handleSearch2(e.target.value);
                                                                        setTimelineFilter(
                                                                            e
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                >
                                                                    <option value=''>
                                                                        All
                                                                    </option>
                                                                    <option value='EMAILS'>
                                                                        Email
                                                                    </option>
                                                                    <option value='MESSAGES'>
                                                                        Messages
                                                                    </option>
                                                                    <option value='NOTES'>
                                                                        Notes
                                                                    </option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </div>
                                                    )}
                                                    <Tab.Content style={{}}>
                                                        <Tab.Pane eventKey='first'>
                                                            <Card.Body
                                                                className='pt-2'
                                                                style={{
                                                                    maxHeight:
                                                                        '600px',
                                                                    overflow:
                                                                        'scroll',
                                                                }}
                                                            >
                                                                <hr
                                                                    style={{
                                                                        marginLeft: 47,
                                                                        marginRight: 15,
                                                                    }}
                                                                />
                                                                <ul className='timeline m-3'>
                                                                    {timeline
                                                                        .filter(
                                                                            (
                                                                                elem
                                                                            ) => {
                                                                                if (
                                                                                    timelineFilter ===
                                                                                    'MESSAGES'
                                                                                ) {
                                                                                    return (
                                                                                        elem.type ===
                                                                                            'INCOMING_TEXT_MESSAGE' ||
                                                                                        elem.type ===
                                                                                            'RESPONDED' ||
                                                                                        elem.type ===
                                                                                            'OUTGOING_TEXT_MESSAGE'
                                                                                    );
                                                                                } else if (
                                                                                    timelineFilter ===
                                                                                    'NOTES'
                                                                                ) {
                                                                                    return (
                                                                                        elem.type ===
                                                                                        'NOTE'
                                                                                    );
                                                                                } else if (
                                                                                    timelineFilter ===
                                                                                    'EMAILS'
                                                                                ) {
                                                                                    return (
                                                                                        elem.type ===
                                                                                        'OUTGOING_AUTOMATED_EMAIL'
                                                                                    );
                                                                                } else {
                                                                                    return elem;
                                                                                }
                                                                            }
                                                                        )
                                                                        .map(
                                                                            (
                                                                                elem
                                                                            ) => {
                                                                                if (
                                                                                    elem.type ===
                                                                                    'INCOMING_TEXT_MESSAGE'
                                                                                ) {
                                                                                    return (
                                                                                        <>
                                                                                            <li className='timeline-item'>
                                                                                                <div className='d-flex'>
                                                                                                    <div
                                                                                                        className='flex-grow-1'
                                                                                                        style={{
                                                                                                            lineHeight: 1.3,
                                                                                                        }}
                                                                                                    >
                                                                                                        <small className='float-end text-navy'>
                                                                                                            {timeAgoFromTimestampWithMillis(
                                                                                                                elem.created_at
                                                                                                            )}
                                                                                                        </small>
                                                                                                        {/* Recevied a{" "}
                                          <strong style={{ color: "#DCF1ED" }}>
                                            Message
                                          </strong> */}
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: '#408af7',
                                                                                                            }}
                                                                                                        >
                                                                                                            Recevied{' '}
                                                                                                        </span>

                                                                                                        a{' '}
                                                                                                        <strong
                                                                                                            style={{}}
                                                                                                        >
                                                                                                            Message
                                                                                                        </strong>
                                                                                                        <br />
                                                                                                        <small className='text-muted'>
                                                                                                            {moment(
                                                                                                                new Date(
                                                                                                                    elem.created_at
                                                                                                                )
                                                                                                            ).format(
                                                                                                                'MMM DD YYYY, ddd hh:mm a'
                                                                                                            )}
                                                                                                        </small>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                marginLeft: 30,
                                                                                                                marginRight: 30,
                                                                                                                marginTop: 10,
                                                                                                                marginBottom: 10,
                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                className='border text-md p-2 mt-1'
                                                                                                                style={{
                                                                                                                    whiteSpace:
                                                                                                                        'pre-line',
                                                                                                                    backgroundColor:
                                                                                                                        '#90b9f5',
                                                                                                                    color: 'black',
                                                                                                                }}
                                                                                                            >
                                                                                                                {
                                                                                                                    elem.body
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <hr />
                                                                                            </li>
                                                                                        </>
                                                                                    );
                                                                                } else if (
                                                                                    elem.type ===
                                                                                    'OUTGOING_TEXT_MESSAGE'
                                                                                ) {
                                                                                    return (
                                                                                        <>
                                                                                            <li className='timeline-item'>
                                                                                                <div className='d-flex'>
                                                                                                    <div
                                                                                                        className='flex-grow-1'
                                                                                                        style={{
                                                                                                            lineHeight: 1.3,
                                                                                                        }}
                                                                                                    >
                                                                                                        <small className='float-end text-navy'>
                                                                                                            {timeAgoFromTimestampWithMillis(
                                                                                                                elem.created_at
                                                                                                            )}
                                                                                                        </small>
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: '#09B29C',
                                                                                                            }}
                                                                                                        >
                                                                                                            Sent{' '}
                                                                                                        </span>

                                                                                                        a{' '}
                                                                                                        <strong
                                                                                                            style={{}}
                                                                                                        >
                                                                                                            Message
                                                                                                        </strong>
                                                                                                        <br />
                                                                                                        <small className='text-muted'>
                                                                                                            {moment(
                                                                                                                new Date(
                                                                                                                    elem.created_at
                                                                                                                )
                                                                                                            ).format(
                                                                                                                'MMM DD YYYY, ddd hh:mm a'
                                                                                                            )}
                                                                                                        </small>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                marginLeft: 30,
                                                                                                                marginRight: 30,
                                                                                                                marginTop: 20,
                                                                                                                marginBottom: 10,
                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                className='border text-md p-2 mt-1'
                                                                                                                style={{
                                                                                                                    whiteSpace:
                                                                                                                        'pre-line',
                                                                                                                    backgroundColor:
                                                                                                                        '#DCF1ED',
                                                                                                                    color: 'black',
                                                                                                                }}
                                                                                                            >
                                                                                                                {
                                                                                                                    elem.body
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <hr />
                                                                                            </li>
                                                                                        </>
                                                                                    );
                                                                                } else if (
                                                                                    elem.type ===
                                                                                    'NOTE'
                                                                                ) {
                                                                                    return (
                                                                                        <>
                                                                                            <li className='timeline-item'>
                                                                                                <div className='d-flex'>
                                                                                                    <div
                                                                                                        className='flex-grow-1'
                                                                                                        style={{
                                                                                                            lineHeight: 1.3,
                                                                                                        }}
                                                                                                    >
                                                                                                        <small className='float-end text-navy'>
                                                                                                            {timeAgoFromTimestampWithMillis(
                                                                                                                elem.created_at
                                                                                                            )}
                                                                                                        </small>
                                                                                                        New
                                                                                                        <strong>
                                                                                                            {' '}
                                                                                                            Note
                                                                                                        </strong>{' '}
                                                                                                        added
                                                                                                        <br />
                                                                                                        <small className='text-muted'>
                                                                                                            {moment(
                                                                                                                new Date(
                                                                                                                    elem.created_at
                                                                                                                )
                                                                                                            ).format(
                                                                                                                'MMM DD YYYY, ddd hh:mm a'
                                                                                                            )}
                                                                                                        </small>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                marginLeft: 30,
                                                                                                                marginRight: 30,
                                                                                                                marginTop: 10,
                                                                                                                marginBottom: 10,
                                                                                                            }}
                                                                                                        >
                                                                                                            <p
                                                                                                                className='border text-md p-2 mt-1'
                                                                                                                style={{
                                                                                                                    whiteSpace:
                                                                                                                        'pre-line',
                                                                                                                    backgroundColor:
                                                                                                                        '#FEF2E0',
                                                                                                                    color: 'black',
                                                                                                                    // fontWeight: "bold",
                                                                                                                }}
                                                                                                            >
                                                                                                                {
                                                                                                                    elem.body
                                                                                                                }
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <hr />
                                                                                            </li>
                                                                                        </>
                                                                                    );
                                                                                } else if (
                                                                                    elem.type ===
                                                                                    'UNSUBSCRIBE'
                                                                                ) {
                                                                                    return (
                                                                                        <>
                                                                                            <li className='timeline-item'>
                                                                                                <div className='d-flex'>
                                                                                                    <div
                                                                                                        className='flex-grow-1'
                                                                                                        style={{
                                                                                                            lineHeight: 1.3,
                                                                                                        }}
                                                                                                    >
                                                                                                        <small className='float-end text-navy'>
                                                                                                            {timeAgoFromTimestampWithMillis(
                                                                                                                elem.created_at
                                                                                                            )}
                                                                                                        </small>
                                                                                                        <strong>
                                                                                                            {' '}
                                                                                                            Unsubscribe
                                                                                                        </strong>{' '}
                                                                                                        from{' '}
                                                                                                        <strong>
                                                                                                            {elem.unsubscribeType ===
                                                                                                            'ALL'
                                                                                                                ? 'All'
                                                                                                                : elem.unsubscribeType ===
                                                                                                                  'ALL_EMAILS'
                                                                                                                ? 'All Emails'
                                                                                                                : elem.unsubscribeType ===
                                                                                                                  'TRIAL_EMAILS'
                                                                                                                ? 'Trial Emails'
                                                                                                                : elem.unsubscribeType ===
                                                                                                                  'PERSONAL_EMAILS'
                                                                                                                ? 'Personal Emails'
                                                                                                                : elem.unsubscribeType ===
                                                                                                                  'MESSAGES'
                                                                                                                ? 'Messages'
                                                                                                                : ''}{' '}
                                                                                                        </strong>
                                                                                                        <br />
                                                                                                        <small className='text-muted'>
                                                                                                            {moment(
                                                                                                                new Date(
                                                                                                                    elem.created_at
                                                                                                                )
                                                                                                            ).format(
                                                                                                                'MMM DD YYYY, ddd hh:mm a'
                                                                                                            )}
                                                                                                        </small>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <hr />
                                                                                            </li>
                                                                                        </>
                                                                                    );
                                                                                } else if (
                                                                                    elem.type ===
                                                                                    'RESPONDED'
                                                                                ) {
                                                                                    return (
                                                                                        <>
                                                                                            <li className='timeline-item'>
                                                                                                <div className='d-flex'>
                                                                                                    <div
                                                                                                        className='flex-grow-1'
                                                                                                        style={{
                                                                                                            lineHeight: 1.3,
                                                                                                        }}
                                                                                                    >
                                                                                                        <small className='float-end text-navy'>
                                                                                                            {timeAgoFromTimestampWithMillis(
                                                                                                                elem.created_at
                                                                                                            )}
                                                                                                        </small>
                                                                                                        New
                                                                                                        <strong>
                                                                                                            {' '}
                                                                                                            Response
                                                                                                        </strong>{' '}
                                                                                                        added
                                                                                                        <br />
                                                                                                        <small className='text-muted'>
                                                                                                            {moment(
                                                                                                                new Date(
                                                                                                                    elem.created_at
                                                                                                                )
                                                                                                            ).format(
                                                                                                                'MMM DD YYYY, ddd hh:mm a'
                                                                                                            )}
                                                                                                        </small>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                marginLeft: 30,
                                                                                                                marginRight: 30,
                                                                                                                marginTop: 10,
                                                                                                                marginBottom: 10,
                                                                                                            }}
                                                                                                        >
                                                                                                            <p
                                                                                                                className='border text-md p-2 mt-1'
                                                                                                                style={{
                                                                                                                    whiteSpace:
                                                                                                                        'pre-line',
                                                                                                                    color: 'black',
                                                                                                                }}
                                                                                                            >
                                                                                                                {
                                                                                                                    elem.body
                                                                                                                }
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <hr />
                                                                                            </li>
                                                                                        </>
                                                                                    );
                                                                                } else if (
                                                                                    elem.type ===
                                                                                    'OUTGOING_AUTOMATED_EMAIL'
                                                                                ) {
                                                                                    return (
                                                                                        <>
                                                                                            <li className='timeline-item'>
                                                                                                <div className='d-flex'>
                                                                                                    <div
                                                                                                        className='flex-grow-1'
                                                                                                        style={{
                                                                                                            lineHeight: 1.3,
                                                                                                        }}
                                                                                                    >
                                                                                                        <small className='float-end text-navy'>
                                                                                                            {timeAgoFromTimestampWithMillis(
                                                                                                                elem.created_at
                                                                                                            )}
                                                                                                        </small>
                                                                                                        Sent
                                                                                                        an{' '}
                                                                                                        <strong>
                                                                                                            Automated
                                                                                                            Email
                                                                                                        </strong>
                                                                                                        <br />
                                                                                                        <small className='text-muted'>
                                                                                                            {moment(
                                                                                                                new Date(
                                                                                                                    elem.created_at
                                                                                                                )
                                                                                                            ).format(
                                                                                                                'MMM DD YYYY, ddd hh:mm a'
                                                                                                            )}
                                                                                                        </small>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                marginLeft: 30,
                                                                                                                marginRight: 30,
                                                                                                                marginTop: 20,
                                                                                                                marginBottom: 10,
                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                className='border text-sm text-muted p-2 mt-1'
                                                                                                                style={{
                                                                                                                    maxHeight:
                                                                                                                        '200px',
                                                                                                                    overflow:
                                                                                                                        'scroll',
                                                                                                                    // backgroundColor: "pink",
                                                                                                                }}
                                                                                                            >
                                                                                                                <EmailTemplate
                                                                                                                    email={
                                                                                                                        elem.body
                                                                                                                    }
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    backgroundColor:
                                                                                                                        '#407FEA',
                                                                                                                    fontSize:
                                                                                                                        '12px',
                                                                                                                    width: '50px',
                                                                                                                    padding:
                                                                                                                        '5px',
                                                                                                                    marginTop:
                                                                                                                        '10px',
                                                                                                                    color: 'white',
                                                                                                                    textAlign:
                                                                                                                        'center',
                                                                                                                    cursor: 'pointer',
                                                                                                                }}
                                                                                                                // onClick={() =>
                                                                                                                //    navigate("/email-template")
                                                                                                                // }
                                                                                                            >
                                                                                                                Open
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>

                                                                                                <hr />
                                                                                            </li>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            }
                                                                        )}
                                                                </ul>
                                                                {timeline.length ===
                                                                0 ? (
                                                                    <div
                                                                        className='d-flex justify-content-center align-items-center p-3 flex-column '
                                                                        id={
                                                                            'Emptybox'
                                                                        }
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                    >
                                                                        <p
                                                                            // className="mt-2"
                                                                            style={{
                                                                                fontSize: 20,
                                                                                fontWeight:
                                                                                    'bold',
                                                                                color: 'black',
                                                                            }}
                                                                        >
                                                                            {/* No Invoice Added. Start by Clicking On Add Invoice */}
                                                                            Nothing
                                                                            in
                                                                            Timeline
                                                                        </p>
                                                                    </div>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </Card.Body>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey='second'>
                                                            <Estimates
                                                                dataEstimate={
                                                                    dataEstimate
                                                                }
                                                                loading={
                                                                    loading
                                                                }
                                                                // setInvoicedeleteModals={setInvoicedeleteModals}
                                                                // setdeleteinvoiceid={setdeleteinvoiceid}
                                                                filter={filter}
                                                                rowdata={
                                                                    rowdata
                                                                }
                                                            />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey='third'>
                                                            <Orders
                                                                dataOrder={
                                                                    dataOrder
                                                                }
                                                                loading={
                                                                    loading
                                                                }
                                                                rowdata={
                                                                    rowdata
                                                                }
                                                                // setInvoicedeleteModals={setInvoicedeleteModals}
                                                                // setdeleteinvoiceid={setdeleteinvoiceid}
                                                                filter={filter}
                                                            />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey='fourth'>
                                                            <Invoices
                                                                dataInvoice={
                                                                    dataInvoice
                                                                }
                                                                loading={
                                                                    loading
                                                                }
                                                                rowdata={
                                                                    rowdata
                                                                }
                                                                // setInvoicedeleteModals={setInvoicedeleteModals}
                                                                // setdeleteinvoiceid={setdeleteinvoiceid}
                                                                filter={filter}
                                                            />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey='fifth'>
                                                            <Request
                                                                dataRequest={
                                                                    dataRequest
                                                                }
                                                                loading={
                                                                    loading
                                                                }
                                                                // setInvoicedeleteModals={setInvoicedeleteModals}
                                                                // setdeleteinvoiceid={setdeleteinvoiceid}
                                                                filter={filter}
                                                                rowdata={
                                                                    rowdata
                                                                }
                                                            />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey='seventh'>
                                                            <Items
                                                                dataitems={
                                                                    items
                                                                }
                                                                loading={
                                                                    loading
                                                                }
                                                                // setInvoicedeleteModals={setInvoicedeleteModals}
                                                                // setdeleteinvoiceid={setdeleteinvoiceid}
                                                                filter={filter}
                                                                rowdata={
                                                                    rowdata
                                                                }
                                                            />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey='eight'>
                                                            <Expenses
                                                                dataExpense={
                                                                    expenses
                                                                }
                                                                loading={
                                                                    loading
                                                                }
                                                                // setInvoicedeleteModals={setInvoicedeleteModals}
                                                                // setdeleteinvoiceid={setdeleteinvoiceid}
                                                                filter={filter}
                                                                rowdata={
                                                                    rowdata
                                                                }
                                                            />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey='ninth'>
                                                            <Timesheets
                                                                dataTimesheet={
                                                                    timesheets
                                                                }
                                                                loading={
                                                                    loading
                                                                }
                                                                // setInvoicedeleteModals={setInvoicedeleteModals}
                                                                // setdeleteinvoiceid={setdeleteinvoiceid}
                                                                filter={filter}
                                                                rowdata={
                                                                    rowdata
                                                                }
                                                            />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey='tenth'>
                                                            <Transactions
                                                                dataTransactions={
                                                                    transactions
                                                                }
                                                                loading={
                                                                    loading
                                                                }
                                                                // setInvoicedeleteModals={setInvoicedeleteModals}
                                                                // setdeleteinvoiceid={setdeleteinvoiceid}
                                                                filter={filter}
                                                                rowdata={
                                                                    rowdata
                                                                }
                                                            />
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey='eleventh'>
                                                            <Portal
                                                                dataPortal={
                                                                    portal
                                                                }
                                                                loading={
                                                                    loading
                                                                }
                                                                // setInvoicedeleteModals={setInvoicedeleteModals}
                                                                // setdeleteinvoiceid={setdeleteinvoiceid}
                                                                filter={filter}
                                                                rowdata={
                                                                    rowdata
                                                                }
                                                            />
                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                </Tab.Container>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Modal
                                    show={nothavemodal}
                                    onHide={() => setNothavemodal(false)}
                                    centered
                                    size='sm'
                                >
                                    <Modal.Body style={{ padding: 0 }}>
                                        <div
                                            style={{
                                                width: '100%',
                                                backgroundColor: '#f4f4f4',
                                                height: 46,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '16px 24px',
                                            }}
                                        >
                                            <h1
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: 18,
                                                    color: 'black',
                                                    margin: 0,
                                                }}
                                            >
                                                Not Available
                                            </h1>
                                            <div
                                                onClick={() => {
                                                    setNothavemodal(false);
                                                }}
                                            >
                                                <X
                                                    size={24}
                                                    strokeWidth={2}
                                                    stroke='black'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                padding: '10px 24px 0 24px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        margin: '15px 0 20px',
                                                    }}
                                                >
                                                    {nothavetype === 'Phone'
                                                        ? ' No phone number has been added.'
                                                        : ' No Email has been added.'}
                                                </p>
                                            </div>

                                            <hr style={{ margin: 0 }} />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    margin: '12px 0',
                                                }}
                                            >
                                                <button
                                                    style={{
                                                        padding: '5px 10px',
                                                        backgroundColor:
                                                            '#3679e8',
                                                        borderWidth: 0,
                                                        color: 'white',
                                                        width: 80,
                                                    }}
                                                    onClick={() => {
                                                        setNothavemodal(false);
                                                    }}
                                                >
                                                    OK
                                                </button>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                                <Modal
                                    show={sendmsgmodal}
                                    onHide={() => setSendmsgmodal(false)}
                                    centered
                                    size='sm'
                                >
                                    <Modal.Body style={{ padding: 0 }}>
                                        <div
                                            style={{
                                                width: '100%',
                                                backgroundColor: '#f4f4f4',
                                                height: 46,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '16px 24px',
                                            }}
                                        >
                                            <h1
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: 18,
                                                    color: 'black',
                                                    margin: 0,
                                                }}
                                            >
                                                Send Message
                                            </h1>
                                            <div
                                                onClick={() => {
                                                    setSendmsgmodal(false);
                                                }}
                                            >
                                                <X
                                                    size={24}
                                                    strokeWidth={2}
                                                    stroke='black'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                padding: '10px 24px 0 24px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    margin: '15px 0 20px',
                                                }}
                                            >
                                                <Formik
                                                    validationSchema={Yup.object().shape(
                                                        {
                                                            phone: Yup.string().required(
                                                                'Phone Number is required'
                                                            ),
                                                            subject:
                                                                Yup.string().required(
                                                                    'Message is required'
                                                                ),
                                                        }
                                                    )}
                                                    onSubmit={(elem) =>
                                                        phonesendHandler(elem)
                                                    }
                                                    initialValues={{
                                                        phone: rowdata.business_phone,
                                                        subject: '',
                                                    }}
                                                >
                                                    {({
                                                        handleSubmit,
                                                        handleChange,
                                                        handleBlur,
                                                        values,
                                                        touched,
                                                        isValid,
                                                        errors,
                                                        dirty,
                                                    }) => (
                                                        <Form
                                                            noValidate
                                                            onSubmit={
                                                                handleSubmit
                                                            }
                                                        >
                                                            <FloatingLabel
                                                                controlId='floatingInput'
                                                                label='Phone Number'
                                                                className='mb-3'
                                                            >
                                                                <Form.Control
                                                                    type='text'
                                                                    name='phone'
                                                                    placeholder='text'
                                                                    value={
                                                                        values.phone
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    isInvalid={
                                                                        !!errors.phone
                                                                    }
                                                                />
                                                                <Form.Control.Feedback type='invalid'>
                                                                    {
                                                                        errors.phone
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </FloatingLabel>

                                                            <Form.Group>
                                                                <Form.Control
                                                                    type='text'
                                                                    name='subject'
                                                                    placeholder='Message'
                                                                    value={
                                                                        values.subject
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    isInvalid={
                                                                        !!errors.subject
                                                                    }
                                                                    as='textarea'
                                                                    style={{
                                                                        height: '100px',
                                                                    }}
                                                                />
                                                                <Form.Control.Feedback type='invalid'>
                                                                    {
                                                                        errors.subject
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            <hr
                                                                style={{
                                                                    margin: 0,
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'flex-end',
                                                                    margin: '7px 0',
                                                                }}
                                                            >
                                                                <button
                                                                    type='button'
                                                                    style={{
                                                                        marginRight: 5,
                                                                        padding:
                                                                            '5px 10px',
                                                                        backgroundColor:
                                                                            'white',
                                                                        borderWidth: 1,
                                                                    }}
                                                                    onClick={() =>
                                                                        setSendmsgmodal(
                                                                            !sendmsgmodal
                                                                        )
                                                                    }
                                                                >
                                                                    Cancel
                                                                </button>
                                                                <button
                                                                    type='submit'
                                                                    style={{
                                                                        padding:
                                                                            '5px 10px',
                                                                        backgroundColor:
                                                                            '#3679e8',
                                                                        borderWidth: 0,
                                                                        color: 'white',
                                                                        width: 80,
                                                                    }}
                                                                    // onClick={() => {
                                                                    //   setOpenModals(!openModals);
                                                                    // }}
                                                                >
                                                                    Send
                                                                </button>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                                <Modal
                                    show={savenote}
                                    onHide={() => setSavenote(false)}
                                    centered
                                    size='sm'
                                >
                                    <Modal.Body style={{ padding: 0 }}>
                                        <div
                                            style={{
                                                width: '100%',
                                                backgroundColor: '#f4f4f4',
                                                height: 46,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '16px 24px',
                                            }}
                                        >
                                            <h1
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: 18,
                                                    color: 'black',
                                                    margin: 0,
                                                }}
                                            >
                                                Save Note
                                            </h1>
                                            <div
                                                onClick={() => {
                                                    setSavenote(false);
                                                }}
                                            >
                                                <X
                                                    size={24}
                                                    strokeWidth={2}
                                                    stroke='black'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                padding: '10px 24px 0 24px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    // display: "flex",
                                                    margin: '15px 0 20px',
                                                }}
                                            >
                                                <Formik
                                                    validationSchema={Yup.object().shape(
                                                        {
                                                            subject:
                                                                Yup.string().required(
                                                                    'Note is required'
                                                                ),
                                                        }
                                                    )}
                                                    onSubmit={(elem) =>
                                                        noteSubmitHandler(elem)
                                                    }
                                                    initialValues={{
                                                        mail: rowdata.business_email,
                                                        subject: '',
                                                    }}
                                                >
                                                    {({
                                                        handleSubmit,
                                                        handleChange,
                                                        handleBlur,
                                                        values,
                                                        touched,
                                                        isValid,
                                                        errors,
                                                        dirty,
                                                    }) => (
                                                        <Form
                                                            noValidate
                                                            onSubmit={
                                                                handleSubmit
                                                            }
                                                        >
                                                            {/* <FloatingLabel
                                controlId="floatingInput"
                                label="Note"
                                className="mb-3"
                              > */}
                                                            <Form.Group>
                                                                {/* <Form.Label>Row</Form.Label> */}
                                                                <Form.Control
                                                                    type='text'
                                                                    name='subject'
                                                                    placeholder='Note'
                                                                    value={
                                                                        values.subject
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    isInvalid={
                                                                        !!errors.subject
                                                                    }
                                                                    as='textarea'
                                                                    style={{
                                                                        resize: 'vertical',
                                                                    }}
                                                                    rows={4}
                                                                    // style={{
                                                                    //   height: "100px",
                                                                    // }}
                                                                />
                                                                <Form.Control.Feedback type='invalid'>
                                                                    {
                                                                        errors.subject
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            {/* </FloatingLabel> */}
                                                            <hr
                                                                style={{
                                                                    marginTop:
                                                                        '15px',
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'flex-end',
                                                                    margin: '7px 0',
                                                                }}
                                                            >
                                                                <button
                                                                    type='button'
                                                                    style={{
                                                                        marginRight: 5,
                                                                        padding:
                                                                            '5px 10px',
                                                                        backgroundColor:
                                                                            'white',
                                                                        borderWidth: 1,
                                                                    }}
                                                                    onClick={() =>
                                                                        setSavenote(
                                                                            !savenote
                                                                        )
                                                                    }
                                                                >
                                                                    Cancel
                                                                </button>
                                                                <button
                                                                    type='submit'
                                                                    style={{
                                                                        padding:
                                                                            '5px 10px',
                                                                        backgroundColor:
                                                                            '#3679e8',
                                                                        borderWidth: 0,
                                                                        color: 'white',
                                                                        width: 80,
                                                                    }}
                                                                    // onClick={() => {
                                                                    //   setOpenModals(!openModals);
                                                                    // }}
                                                                >
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                                <Modal
                                    show={saveresponse}
                                    onHide={() => setSaveresponse(false)}
                                    centered
                                    size='sm'
                                >
                                    <Modal.Body style={{ padding: 0 }}>
                                        <div
                                            style={{
                                                width: '100%',
                                                backgroundColor: '#f4f4f4',
                                                height: 46,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '16px 24px',
                                            }}
                                        >
                                            <h1
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: 18,
                                                    color: 'black',
                                                    margin: 0,
                                                }}
                                            >
                                                Mark last message as read
                                            </h1>
                                            <div
                                                onClick={() => {
                                                    setSaveresponse(false);
                                                }}
                                            >
                                                <X
                                                    size={24}
                                                    strokeWidth={2}
                                                    stroke='black'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                padding: '10px 24px 0 24px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    // display: "flex",
                                                    margin: '15px 0 20px',
                                                }}
                                            >
                                                <Formik
                                                    validationSchema={Yup.object().shape(
                                                        {}
                                                    )}
                                                    onSubmit={(elem) =>
                                                        responseSubmitHandler(
                                                            elem
                                                        )
                                                    }
                                                    initialValues={{
                                                        mail: rowdata.business_email,
                                                        subject: '',
                                                    }}
                                                >
                                                    {({
                                                        handleSubmit,
                                                        handleChange,
                                                        handleBlur,
                                                        values,
                                                        touched,
                                                        isValid,
                                                        errors,
                                                        dirty,
                                                    }) => (
                                                        <Form
                                                            noValidate
                                                            onSubmit={
                                                                handleSubmit
                                                            }
                                                        >
                                                            {/* <FloatingLabel
                                controlId="floatingInput"
                                label="Note"
                                className="mb-3"
                              > */}
                                                            <Form.Group>
                                                                {/* <Form.Label>Row</Form.Label> */}
                                                                <Form.Control
                                                                    type='text'
                                                                    name='subject'
                                                                    placeholder='Response'
                                                                    value={
                                                                        values.subject
                                                                    }
                                                                    onChange={
                                                                        handleChange
                                                                    }
                                                                    isInvalid={
                                                                        !!errors.subject
                                                                    }
                                                                    as='textarea'
                                                                    style={{
                                                                        resize: 'vertical',
                                                                    }}
                                                                    rows={4}
                                                                    // style={{
                                                                    //   height: "100px",
                                                                    // }}
                                                                />
                                                                <Form.Control.Feedback type='invalid'>
                                                                    {
                                                                        errors.subject
                                                                    }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>

                                                            {/* </FloatingLabel> */}
                                                            <hr
                                                                style={{
                                                                    marginTop:
                                                                        '15px',
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'flex-end',
                                                                    margin: '7px 0',
                                                                }}
                                                            >
                                                                <button
                                                                    type='button'
                                                                    style={{
                                                                        marginRight: 5,
                                                                        padding:
                                                                            '5px 10px',
                                                                        backgroundColor:
                                                                            'white',
                                                                        borderWidth: 1,
                                                                    }}
                                                                    onClick={() =>
                                                                        setSaveresponse(
                                                                            !saveresponse
                                                                        )
                                                                    }
                                                                >
                                                                    Cancel
                                                                </button>
                                                                <button
                                                                    type='submit'
                                                                    style={{
                                                                        padding:
                                                                            '5px 10px',
                                                                        backgroundColor:
                                                                            '#3679e8',
                                                                        borderWidth: 0,
                                                                        color: 'white',
                                                                        width: 80,
                                                                    }}
                                                                    // onClick={() => {
                                                                    //   setOpenModals(!openModals);
                                                                    // }}
                                                                >
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                                <Modal
                                    show={opendeleteModals}
                                    onHide={() =>
                                        setOpendeleteModals(!opendeleteModals)
                                    }
                                    centered
                                    size='sm'
                                >
                                    <Modal.Body style={{ padding: 0 }}>
                                        <div
                                            style={{
                                                width: '100%',
                                                backgroundColor: '#f4f4f4',
                                                height: 46,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '16px 24px',
                                            }}
                                        >
                                            <h1
                                                style={{
                                                    fontWeight: 'bold',
                                                    fontSize: 18,
                                                    color: 'black',
                                                    margin: 0,
                                                }}
                                            >
                                                Unsubscribe
                                            </h1>
                                            <div
                                                onClick={() =>
                                                    setOpendeleteModals(
                                                        !opendeleteModals
                                                    )
                                                }
                                            >
                                                <X
                                                    size={24}
                                                    strokeWidth={2}
                                                    stroke='black'
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: '10px 24px 0 24px',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    // justifyContent: "center",
                                                    // flexDirection: "column",
                                                    // alignItems: "center",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        margin: '15px 0 20px',
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    Are you sure you want to
                                                    Unsubscribe from{' '}
                                                    {unsubscribetype === 'ALL'
                                                        ? 'All'
                                                        : unsubscribetype ===
                                                          'ALL_EMAILS'
                                                        ? 'All Emails'
                                                        : unsubscribetype ===
                                                          'TRIAL_EMAILS'
                                                        ? 'Trial Emails'
                                                        : unsubscribetype ===
                                                          'PERSONAL_EMAILS'
                                                        ? 'Personal Emails'
                                                        : unsubscribetype ===
                                                          'MESSAGES'
                                                        ? 'Messages'
                                                        : ''}{' '}
                                                    ?
                                                </p>
                                            </div>

                                            <hr style={{ margin: 0 }} />
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    margin: '12px 0',
                                                }}
                                            >
                                                <button
                                                    type='button'
                                                    style={{
                                                        marginRight: 5,
                                                        padding: '5px 10px',
                                                        backgroundColor:
                                                            'white',
                                                        borderWidth: 1,
                                                    }}
                                                    onClick={() =>
                                                        setOpendeleteModals(
                                                            !opendeleteModals
                                                        )
                                                    }
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    style={{
                                                        padding: '5px 10px',
                                                        backgroundColor:
                                                            '#3679e8',
                                                        borderWidth: 0,
                                                        color: 'white',
                                                        // width: 80,
                                                    }}
                                                    onClick={() => {
                                                        unsubscribeHandler();
                                                    }}
                                                >
                                                    Unsubscribe
                                                </button>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </>
                    ) : (
                        <Page404Authenticated />
                    )}
                </>
            ) : (
                <div
                    className='d-flex justify-content-center align-items-center'
                    style={{ height: '70vh' }}
                >
                    <Spinner
                        animation='border'
                        variant='dark'
                        className='me-2'
                    />
                </div>
            )}
        </>
    );
};
export default ViewPartner;
