import React, { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Alert,
    Breadcrumb,
    Button,
    ButtonGroup,
    Card,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    FloatingLabel,
    Form,
    Modal,
    Row,
    Spinner,
} from 'react-bootstrap';
import Page404Authenticated from '../auth/Page404authenticated';
import icon from '../../assets/img/illustrations/icon.png';
import * as Yup from 'yup';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import '../../assets/css/App.css';

// import { useTable, useGlobalFilter } from "react-table";
// import { COLUMNS } from "./column";
// import { GlobalFilter } from "./GlobalFilter";
// import { useEffect, useState } from "react";
import { ArrowLeft, Edit, Plus, Send, X } from 'react-feather';
import useAuth from '../../hooks/useAuth';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { Formik } from 'formik';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import useSidebar from '../../hooks/useSidebar';
import NotyfContext from '../../contexts/NotyfContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPrint } from '@fortawesome/free-solid-svg-icons';
import InvoiceTemplate from '../../components/InvoiceTemplate';

const sumCalculation = (sum, invoice) => {
    return (
        Number(sum).toFixed(2) -
        Number(
            invoice.invoice_discount_type &&
                invoice.invoice_discount_type === 'absolute'
                ? Number(invoice.invoice_discount_rate).toFixed(2)
                : Number(sum * invoice.invoice_discount_rate).toFixed(2)
        ) +
        Number(
            invoice.invoice_tax_type && invoice.invoice_tax_type === 'absolute'
                ? Number(invoice.invoice_tax_rate).toFixed(2)
                : (
                      Number(
                          invoice.invoice_discount_type &&
                              invoice.invoice_discount_type === 'absolute'
                              ? sum - invoice.invoice_discount_rate
                              : sum * (1 - invoice.invoice_discount_rate)
                      ) * invoice.invoice_tax_rate
                  ).toFixed(2)
        )
    );
};
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const InvoiceDetail = () => {
    // const { bdata } = useAuth();
    var location = { state: {} };
    const data = new URLSearchParams(window.location.search);
    location.state = JSON.parse(data.get('data') || '{}');
    // const location = useLocation();
    console.log(location);
    let usercurrency = location.state.row
        ? location.state.row.invoice_currency_code
        : 'USD';

    const bdata = location.state ? location.state.bdata : {};
    console.log(bdata);

    const navigate = useNavigate();
    const { id } = useParams();
    const notyf = useContext(NotyfContext);
    const { isOpen } = useSidebar();
    const [mailstatus, setMailstatus] = useState('');
    const [subtotal, setSubtotal] = useState(0);
    const [openModals, setOpenModals] = useState(false);
    const [opendeleteModals, setOpendeleteModals] = useState(false);
    const [rowdata, setRowdata] = useState({});
    const [token, settoken] = useState('');
    const [loading, setLoading] = useState(true);
    const [type, setType] = useState('CUSTOMER');
    const [multiplier, setMultiplier] = useState(1);
    const [invoiceerror, setinvoiceerror] = useState(false);
    const [paidtotal, setpaidtotal] = useState(0);
    const [markaspaiddialog, setmarkaspaiddialog] = useState(false);
    const [acceptdialog, setacceptdialog] = useState(false);
    const [fetchError, setFetchError] = useState(false);
    const [workflow, setWorkflow] = useState({ type: 'Invoice' });
    // const [sendmecopy, setSendmecopy] = useState(false);

    console.log(rowdata);
    // const getRequiredData = (token) => {
    //   const timeZoneOffset = -(new Date().getTimezoneOffset() * 60);

    //   // fetch("https://bestmate.us/api/invoices/findAll", {
    //   fetch(
    //     `https://bestmate.us/api/invoices/get/${id}?timeZoneOffset=${timeZoneOffset}`,
    //     {
    //       method: "GET",
    //       headers: {
    //         Accept: "application/json",
    //         "Content-Type": "application/json",
    //         Authorization: "Bearer " + token,
    //       },
    //     }
    //   )
    //     .then((res) => {
    //       if (!res.ok) {
    //         setinvoiceerror(true);
    //         setLoading(false);
    //         setFetchError(true);
    //         throw new Error(`HTTP error, status code ${res.status}`);
    //       }

    //       return res.json();
    //     })
    //     .then((result) => {
    //       console.log("new asdfasdf");
    //       console.log(result);
    //       setRowdata(result);
    //       setpaidtotal(result.invoice_grand_total);
    //       console.log(location);
    //       if (location.state.action === "send") {
    //         setOpenModals(true);
    //       }
    //     })
    //     .catch((error) => console.error("Error fetching data: ", error));
    // };

    useEffect(async () => {
        setLoading(true);
        setFetchError(false);
        setSubtotal(0);
        var user = firebase.auth().currentUser;
        if (user) {
            let tokenss = await user.getIdToken();
            settoken(tokenss);
            if (
                location.state !== undefined &&
                Object.keys(location.state.row).length !== 0
            ) {
                console.log('first');

                setRowdata(location.state.row);
                // getBusinessData(tokenss);
                if (location.state.action === 'send') {
                    setOpenModals(true);
                }
                // setType(
                //   rowdata !== undefined
                //     ? rowdata.invoice_partner.partner_type
                //     : "CUSTOMER"
                // );
            }
            // else {
            // console.log("second");
            // getRequiredData(tokenss);
            // getBusinessData(tokenss);
            // setLoading(false);

            // }
        }
        // });
    }, [id]);

    useEffect(() => {
        if (rowdata && Object.keys(rowdata).length !== 0) {
            setinvoiceerror(false);
            setWorkflow({
                type: capitalizeFirstLetter(
                    rowdata.invoice_workflow_type.toLowerCase() === 'order'
                        ? 'job'
                        : rowdata.invoice_workflow_type.toLowerCase()
                ),
            });
            setType(
                rowdata.invoice_partner.partner_type
                    ? rowdata.invoice_partner.partner_type
                    : 'CUSTOMER'
            );
            const mult =
                rowdata !== undefined && Object.keys(rowdata).length !== 0
                    ? rowdata.invoice_partner.partner_price_list !==
                          undefined &&
                      Object.keys(rowdata.invoice_partner.partner_price_list)
                          .length !== 0
                        ? rowdata.invoice_partner.partner_type === 'SUPPLIER'
                            ? 1
                            : Number(
                                  rowdata.invoice_partner.partner_price_list
                                      .price_list_multiplier
                              )
                        : 1
                    : 1;
            setMultiplier(mult);
            rowdata.invoice_order.forEach((element) => {
                setSubtotal(
                    (prev) =>
                        prev +
                        element.item_quantity *
                            (rowdata.invoice_partner.partner_type === 'SUPPLIER'
                                ? element.item_cost_price
                                    ? element.item_cost_price
                                    : 0
                                : element.item_selling_price
                                ? element.item_selling_price
                                : 0) *
                            mult
                );
            });
            setLoading(false);
        } else if (rowdata === undefined) {
            setinvoiceerror(true);
            setLoading(false);
        }
        return () => setSubtotal(0);
    }, [rowdata]);

    // useEffect(async () => {
    //   setRowdata({});
    //   var user = firebase.auth().currentUser;
    //   if (user) {
    //     let tokenss = await user.getIdToken();
    //     settoken(tokenss);
    //     console.log("second");
    //     getRequiredData(tokenss);
    //     // getBusinessData(tokenss);
    //   }
    // }, [id]);

    // console.log(location);
    console.log(rowdata);
    console.log();
    // const type =
    //   rowdata !== undefined && Object.keys(rowdata).length !== 0
    //     ? rowdata.invoice_partner.partner_type
    //     : "CUSTOMER";
    // const multiplier =
    //   rowdata !== undefined && Object.keys(rowdata).length !== 0
    //     ? rowdata.invoice_partner.partner_price_list !== undefined &&
    //       Object.keys(rowdata.invoice_partner.partner_price_list).length !== 0
    //       ? type === "SUPPLIER"
    //         ? 1
    //         : Number(
    //             rowdata.invoice_partner.partner_price_list.price_list_multiplier
    //           )
    //       : 1
    //     : 1;

    // console.log(type, multiplier);
    const printableArea = useRef();
    console.log(rowdata, multiplier);

    // useEffect(() => {
    //   rowdata !== undefined && Object.keys(rowdata).length !== 0 ? (
    //     rowdata.invoice_order.forEach((element) => {
    //       setSubtotal(
    //         (prev) =>
    //           prev +
    //           element.item_quantity *
    //             (type === "SUPPLIER"
    //               ? element.item_cost_price
    //                 ? element.item_cost_price
    //                 : 0
    //               : element.item_selling_price
    //               ? element.item_selling_price
    //               : 0) *
    //             multiplier
    //       );
    //     })
    //   ) : (
    //     <></>
    //   );
    // }, [rowdata, multiplier]);

    // useEffect(() => {
    //   rowdata !== undefined ? (
    //     rowdata.invoice_workflow_type === "INVOICE" ? (
    //       setType("Invoice")
    //     ) : rowdata.invoice_workflow_type === "ESTIMATE" ? (
    //       setType("Estimate")
    //     ) : (
    //       setType("Order")
    //     )
    //   ) : (
    //     <></>
    //   );
    // }, [rowdata]);

    // console.log(subtotal);

    const submitData = async (dat) => {
        var user = firebase.auth().currentUser;

        if (user) {
            let tokenss = await user.getIdToken();
            fetch('https://bestmate.us/api/invoices/send', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + tokenss,
                },
                body: JSON.stringify(dat),
            })
                .then(async (res) => {
                    console.log(res);
                    // const res2 = await res.json();
                    // console.log(res2);
                    // setMailstatus("sent");
                    notyf.open({
                        type: isOpen ? 'home' : 'full',
                        message: `${workflow.type} email sent to ${dat.invoice_email_recipient}`,
                        ripple: 'true',
                        dismissible: 'true',
                    });
                })
                .catch((err) => {
                    console.log(err);
                    // setMailstatus("fail");
                });
        }
    };
    const submitHandler = (elem) => {
        const dat = {
            invoice_id: id,
            invoice_email_recipient: elem.mail,
            invoice_email_subject: elem.subject,
            invoice_send_copy: elem.sendcopytome,
        };
        submitData(dat);
        console.log(dat);
        setOpenModals(!openModals);
    };

    // let grandtotal =
    //   subtotal -
    //   subtotal * (rowdata.invoice_discount_rate / 100) +
    //   (subtotal - subtotal * (rowdata.invoice_discount_rate / 100)) *
    //     (rowdata.invoice_tax_rate / 100);

    const deleteHandler = async () => {
        var user = firebase.auth().currentUser;

        if (user) {
            let tokenss = await user.getIdToken();
            console.log(rowdata.invoice_id);
            fetch('https://bestmate.us/api/invoices/delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + tokenss,
                },
                body: JSON.stringify({
                    invoice_id: id,
                    // invoice_id: "BmuiRP5cpYWsOUluqHbj",
                }),
            })
                .then((res) => {
                    // navigate(-1);

                    navigate(`/${workflow.type}s`);
                })
                .catch((err) => console.log(err));
        }
    };
    const title = `${workflow.type} Detail`;
    console.log(rowdata);
    return (
        <>
            {/* {rowdata !== undefined && Object.keys(bdata).length !== 0 ? ( */}
            {!loading ? (
                <>
                    {!invoiceerror ? (
                        <>
                            <Helmet title={title} />
                            <Container fluid className='p-0 '>
                                {/* {mailstatus === "sent" ? (
              <Alert
                variant="primary"
                className="position-fixed bottom-0 start-50 w-25"
                onClose={() => setMailstatus("")}
                dismissible
              >
                <div className="alert-message">
                  <strong>Mail Sent Succesfully!</strong>
                </div>
              </Alert>
            ) : (
              <></>
            )}
            {mailstatus === "fail" ? (
              <Alert
                variant="danger"
                className="position-fixed bottom-0 end-0 w-25"
                onClose={() => setMailstatus("")}
                dismissible
              >
                <div className="alert-message">
                  <strong>Mail Not sent Please try later!</strong>
                </div>
              </Alert>
            ) : (
              <></>
            )} */}
                                <Card
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        // backgroundColor: "white",
                                        padding: '0rem 1.5rem 2rem 1.5rem',
                                    }}
                                    className='pt-2 pb-2 border-bottom '
                                >
                                    <div>
                                        <h2
                                            className='mt-3 lead'
                                            style={{
                                                fontSize: '24px',
                                                // , fontWeight: 100
                                            }}
                                        >
                                            {/* Invoices */}
                                            {title + 's'}
                                        </h2>
                                        <Breadcrumb
                                            className='mb-0 lead'
                                            style={{ fontSize: '13px' }}
                                        >
                                            <Breadcrumb.Item
                                                active
                                                onClick={() =>
                                                    navigate('/customer')
                                                }
                                                style={{ cursor: 'pointer' }}
                                            >
                                                Customers
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item
                                                onClick={() => navigate(-1)}
                                                style={{ cursor: 'pointer' }}
                                                active
                                            >
                                                Customer
                                            </Breadcrumb.Item>
                                            <Breadcrumb.Item
                                                active
                                                className='font-weight-bold'
                                            >
                                                {title + 's'}
                                            </Breadcrumb.Item>
                                        </Breadcrumb>
                                        <div style={{ display: 'flex' }}>
                                            {rowdata.invoice_parent &&
                                            rowdata.invoice_parent.length !==
                                                0 ? (
                                                <>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                            fontSize: 12,
                                                            // marginRight: 10,
                                                        }}
                                                    >
                                                        {`Created from : `}
                                                    </p>
                                                    {rowdata.invoice_parent.map(
                                                        (elem) => (
                                                            <p
                                                                style={{
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    fontSize: 12,
                                                                    marginLeft: 3,
                                                                    color: '#407fea',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >{` ${
                                                                elem.invoice_workflow_type ===
                                                                'ORDER'
                                                                    ? 'JOB'
                                                                    : elem.invoice_workflow_type
                                                            }#${
                                                                elem.invoice_number
                                                            } `}</p>
                                                        )
                                                    )}
                                                </>
                                            ) : (
                                                <></>
                                            )}

                                            {rowdata.invoice_children &&
                                            rowdata.invoice_children.length !==
                                                0 ? (
                                                <>
                                                    <p
                                                        style={{
                                                            margin: 0,
                                                            padding: 0,
                                                            fontSize: 12,
                                                            marginLeft:
                                                                rowdata.invoice_parent
                                                                    ? 10
                                                                    : 0,
                                                        }}
                                                    >
                                                        {`Used for : `}
                                                    </p>
                                                    {rowdata.invoice_children.map(
                                                        (elem) => (
                                                            <p
                                                                style={{
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    fontSize: 12,
                                                                    marginLeft: 3,
                                                                    color: '#407fea',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >{` ${
                                                                elem.invoice_workflow_type ===
                                                                'ORDER'
                                                                    ? 'JOB'
                                                                    : elem.invoice_workflow_type
                                                            }#${
                                                                elem.invoice_number
                                                            } `}</p>
                                                        )
                                                    )}
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </Card>
                                <div
                                    style={{
                                        padding: '0rem 1.5rem 2rem 1.5rem',
                                    }}
                                >
                                    <Row>
                                        <Col>
                                            {' '}
                                            <div style={{}}>
                                                <table
                                                    ref={printableArea}
                                                    border='0'
                                                    cellPadding='0'
                                                    cellSpacing='0'
                                                    // width="100%"
                                                    width='800'
                                                    align='center'
                                                    style={{
                                                        // width: "8s00",

                                                        // minWidth: "100%",
                                                        background: '#fff',
                                                        borderRadius:
                                                            '5px 5px 0 0',
                                                        fontFamily:
                                                            "'Libre Franklin', sans-serif",
                                                        fontSize: '13px',
                                                        fontWeight: '300',
                                                        color: '#000',
                                                    }}
                                                >
                                                    <tr style={{}}>
                                                        <td
                                                            // align="left"
                                                            // valign="top"
                                                            style={{
                                                                padding:
                                                                    '20px 30px',
                                                                borderBottom:
                                                                    '1px solid #ddd',
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'space-between',
                                                            }}
                                                        >
                                                            {bdata.business_logo ? (
                                                                <div>
                                                                    <img
                                                                        src={
                                                                            bdata.business_logo
                                                                        }
                                                                        style={{
                                                                            maxHeight: 135,
                                                                            width: 'auto',
                                                                            height: 'auto',
                                                                        }}
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}

                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                    justifyContent:
                                                                        'space-between',
                                                                }}
                                                            >
                                                                {workflow.type !==
                                                                'Job' ? (
                                                                    <h1
                                                                        style={{
                                                                            margin: '0',
                                                                            padding:
                                                                                '0',
                                                                            paddingBottom:
                                                                                '4px',
                                                                            fontSize:
                                                                                '30px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            marginBottom: 0,
                                                                            color: '#adadad',
                                                                            textAlign: `${
                                                                                bdata.business_logo
                                                                                    ? 'end'
                                                                                    : 'start'
                                                                            }`,
                                                                        }}
                                                                    >
                                                                        {workflow.type.toUpperCase()}
                                                                    </h1>
                                                                ) : (
                                                                    <h1
                                                                        style={{
                                                                            margin: '0',
                                                                            padding:
                                                                                '0',
                                                                            paddingBottom:
                                                                                '4px',
                                                                            fontSize:
                                                                                '30px',
                                                                            fontWeight:
                                                                                'bold',
                                                                            marginBottom: 0,
                                                                            color: '#adadad',
                                                                            textAlign: `${
                                                                                bdata.business_logo
                                                                                    ? 'end'
                                                                                    : 'start'
                                                                            }`,
                                                                        }}
                                                                    >
                                                                        {`${workflow.type.toUpperCase()} # ${
                                                                            rowdata.invoice_number
                                                                        }`}
                                                                    </h1>
                                                                )}

                                                                <div
                                                                    style={{
                                                                        width: 250,

                                                                        marginTop: 10,
                                                                    }}
                                                                >
                                                                    {workflow.type ===
                                                                    'Job' ? (
                                                                        <></>
                                                                    ) : (
                                                                        <div
                                                                            style={{
                                                                                display:
                                                                                    'flex',
                                                                                justifyContent:
                                                                                    'space-between',
                                                                                // padding: "2px 0",
                                                                            }}
                                                                        >
                                                                            <p
                                                                                style={{
                                                                                    margin: 0,
                                                                                }}
                                                                            >
                                                                                {
                                                                                    workflow.type
                                                                                }{' '}
                                                                                No:
                                                                            </p>
                                                                            <p
                                                                                style={{
                                                                                    margin: 0,
                                                                                    // fontSize: 15,
                                                                                    // fontWeight: "bold",
                                                                                    // color: "grey",
                                                                                }}
                                                                            >
                                                                                {
                                                                                    rowdata.invoice_number
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    {workflow.type ===
                                                                    'Job' ? (
                                                                        <></>
                                                                    ) : (
                                                                        <>
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        'flex',
                                                                                    justifyContent:
                                                                                        'space-between',
                                                                                    // padding: "2px 0",
                                                                                }}
                                                                            >
                                                                                <p
                                                                                    style={{
                                                                                        margin: 0,
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        workflow.type
                                                                                    }{' '}
                                                                                    Date:
                                                                                </p>
                                                                                <p
                                                                                    style={{
                                                                                        margin: 0,
                                                                                    }}
                                                                                >
                                                                                    {moment(
                                                                                        rowdata.invoice_created_at
                                                                                    ).format(
                                                                                        'MMM DD, YYYY'
                                                                                    )}
                                                                                </p>
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    display:
                                                                                        'flex',
                                                                                    justifyContent:
                                                                                        'space-between',
                                                                                    // padding: "2px 0",
                                                                                }}
                                                                            >
                                                                                <p
                                                                                    style={{
                                                                                        margin: 0,
                                                                                    }}
                                                                                >
                                                                                    {workflow.type ===
                                                                                    'Estimate'
                                                                                        ? 'Validity Date:'
                                                                                        : 'Due Date:'}
                                                                                </p>
                                                                                <p
                                                                                    style={{
                                                                                        margin: 0,
                                                                                    }}
                                                                                >
                                                                                    {moment(
                                                                                        rowdata.invoice_due_date
                                                                                    ).format(
                                                                                        'MMM DD, YYYY'
                                                                                    )}
                                                                                </p>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                    <div
                                                                        style={{
                                                                            display:
                                                                                'flex',
                                                                            justifyContent:
                                                                                'space-between',
                                                                            // padding: "2px 0",
                                                                        }}
                                                                    >
                                                                        <p
                                                                            style={{
                                                                                margin: 0,
                                                                                display:
                                                                                    'flex',
                                                                                justifyContent:
                                                                                    'center',
                                                                                alignItems:
                                                                                    'center',
                                                                            }}
                                                                        >
                                                                            Status
                                                                        </p>

                                                                        {rowdata.invoice_workflow_type ===
                                                                        'INVOICE' ? (
                                                                            <>
                                                                                {Number(
                                                                                    sumCalculation(
                                                                                        subtotal,
                                                                                        rowdata
                                                                                    ) -
                                                                                        (isNaN(
                                                                                            rowdata.invoice_settled_amount
                                                                                        )
                                                                                            ? (0).toFixed(
                                                                                                  2
                                                                                              )
                                                                                            : Number(
                                                                                                  rowdata.invoice_settled_amount
                                                                                              ).toFixed(
                                                                                                  2
                                                                                              ))
                                                                                ) >
                                                                                0 ? (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                display:
                                                                                                    'flex',

                                                                                                alignItems:
                                                                                                    'center',
                                                                                                justifyContent:
                                                                                                    'flex-end',
                                                                                                // backgroundColor: "pink",
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={{
                                                                                                    backgroundColor:
                                                                                                        '#DE5474',
                                                                                                    // padding: "4px",
                                                                                                    color: 'white',
                                                                                                    borderRadius:
                                                                                                        '5px',
                                                                                                    fontSize:
                                                                                                        '10px',
                                                                                                    fontWeight:
                                                                                                        'bold',
                                                                                                    width: 55,
                                                                                                    textAlign:
                                                                                                        'center',
                                                                                                }}
                                                                                            >
                                                                                                Pay
                                                                                                Now
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                display:
                                                                                                    'flex',
                                                                                                alignItems:
                                                                                                    'center',
                                                                                                justifyContent:
                                                                                                    'flex-end',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={{
                                                                                                    backgroundColor:
                                                                                                        '#1AB395',
                                                                                                    // padding: "4px",
                                                                                                    color: 'white',
                                                                                                    borderRadius:
                                                                                                        '5px',
                                                                                                    fontSize:
                                                                                                        '10px',
                                                                                                    fontWeight:
                                                                                                        'bold',
                                                                                                    width: 55,
                                                                                                    textAlign:
                                                                                                        'center',
                                                                                                }}
                                                                                            >
                                                                                                Paid
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : rowdata.invoice_workflow_type ===
                                                                          'ESTIMATE' ? (
                                                                            <>
                                                                                {rowdata.invoice_status ===
                                                                                'ACCEPTED' ? (
                                                                                    <>
                                                                                        <div
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                display:
                                                                                                    'flex',
                                                                                                alignItems:
                                                                                                    'center',
                                                                                                justifyContent:
                                                                                                    'flex-end',
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                style={{
                                                                                                    backgroundColor:
                                                                                                        '#1AB395',
                                                                                                    // padding: "4px",
                                                                                                    color: 'white',
                                                                                                    borderRadius:
                                                                                                        '5px',
                                                                                                    fontSize:
                                                                                                        '10px',
                                                                                                    fontWeight:
                                                                                                        'bold',
                                                                                                    width: 65,
                                                                                                    textAlign:
                                                                                                        'center',
                                                                                                }}
                                                                                            >
                                                                                                Accepted
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        {rowdata.invoice_status ===
                                                                                        'CONVERTED' ? (
                                                                                            <>
                                                                                                {rowdata.invoice_children.some(
                                                                                                    (
                                                                                                        e
                                                                                                    ) =>
                                                                                                        e.invoice_workflow_type ===
                                                                                                        'INVOICE'
                                                                                                ) ? (
                                                                                                    <>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                width: '100%',
                                                                                                                display:
                                                                                                                    'flex',
                                                                                                                alignItems:
                                                                                                                    'center',
                                                                                                                justifyContent:
                                                                                                                    'flex-end',
                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    // backgroundColor:
                                                                                                                    //   "#1a7bb8",
                                                                                                                    backgroundColor:
                                                                                                                        '#3f80ea',
                                                                                                                    // padding: "4px",
                                                                                                                    color: 'white',
                                                                                                                    borderRadius:
                                                                                                                        '5px',
                                                                                                                    fontSize:
                                                                                                                        '10px',
                                                                                                                    fontWeight:
                                                                                                                        'bold',
                                                                                                                    width: 65,
                                                                                                                    textAlign:
                                                                                                                        'center',
                                                                                                                }}
                                                                                                            >
                                                                                                                Invoiced
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        <div
                                                                                                            style={{
                                                                                                                width: '100%',
                                                                                                                display:
                                                                                                                    'flex',
                                                                                                                alignItems:
                                                                                                                    'center',
                                                                                                                justifyContent:
                                                                                                                    'flex-end',
                                                                                                            }}
                                                                                                        >
                                                                                                            <div
                                                                                                                style={{
                                                                                                                    backgroundColor:
                                                                                                                        '#71BE1D',
                                                                                                                    // padding: "4px",
                                                                                                                    color: 'white',
                                                                                                                    borderRadius:
                                                                                                                        '5px',
                                                                                                                    fontSize:
                                                                                                                        '10px',
                                                                                                                    fontWeight:
                                                                                                                        'bold',
                                                                                                                    width: 65,
                                                                                                                    textAlign:
                                                                                                                        'center',
                                                                                                                    lineHeight: 1.3,
                                                                                                                }}
                                                                                                            >
                                                                                                                Job
                                                                                                                Created
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                )}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                <div
                                                                                                    style={{
                                                                                                        width: '100%',
                                                                                                        display:
                                                                                                            'flex',
                                                                                                        alignItems:
                                                                                                            'center',
                                                                                                        justifyContent:
                                                                                                            'flex-end',
                                                                                                    }}
                                                                                                >
                                                                                                    <div
                                                                                                        style={{
                                                                                                            backgroundColor:
                                                                                                                '#DE5474',
                                                                                                            // padding: "4px",
                                                                                                            color: 'white',
                                                                                                            borderRadius:
                                                                                                                '5px',
                                                                                                            fontSize:
                                                                                                                '10px',
                                                                                                            fontWeight:
                                                                                                                'bold',
                                                                                                            width: 65,
                                                                                                            textAlign:
                                                                                                                'center',
                                                                                                        }}
                                                                                                    >
                                                                                                        Open
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {rowdata.invoice_status ===
                                                                                'CONVERTED' ? (
                                                                                    <div
                                                                                        style={{
                                                                                            width: '100%',
                                                                                            display:
                                                                                                'flex',
                                                                                            alignItems:
                                                                                                'center',
                                                                                            justifyContent:
                                                                                                'flex-end',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                // backgroundColor: "#1a7bb8",
                                                                                                backgroundColor:
                                                                                                    '#3f80ea',
                                                                                                // padding: "4px",
                                                                                                color: 'white',
                                                                                                borderRadius:
                                                                                                    '5px',
                                                                                                fontSize:
                                                                                                    '10px',
                                                                                                fontWeight:
                                                                                                    'bold',
                                                                                                width: 75,
                                                                                                textAlign:
                                                                                                    'center',
                                                                                            }}
                                                                                        >
                                                                                            Invoiced
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <>
                                                                                        {rowdata.invoice_time_status ===
                                                                                            'PAST' &&
                                                                                        rowdata.invoice_visits &&
                                                                                        rowdata
                                                                                            .invoice_visits
                                                                                            .length !==
                                                                                            0 ? (
                                                                                            <div
                                                                                                style={{
                                                                                                    width: '100%',
                                                                                                    display:
                                                                                                        'flex',
                                                                                                    alignItems:
                                                                                                        'center',
                                                                                                    justifyContent:
                                                                                                        'flex-end',
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    style={{
                                                                                                        backgroundColor:
                                                                                                            '#807e7f',
                                                                                                        // padding: "4px",
                                                                                                        color: 'white',
                                                                                                        borderRadius:
                                                                                                            '5px',
                                                                                                        fontSize:
                                                                                                            '10px',
                                                                                                        fontWeight:
                                                                                                            'bold',
                                                                                                        width: 75,
                                                                                                        textAlign:
                                                                                                            'center',
                                                                                                    }}
                                                                                                >
                                                                                                    Past
                                                                                                </div>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <>
                                                                                                {rowdata.invoice_time_status ===
                                                                                                    'UPCOMING' &&
                                                                                                rowdata.invoice_visits &&
                                                                                                rowdata
                                                                                                    .invoice_visits
                                                                                                    .length !==
                                                                                                    0 ? (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            width: '100%',
                                                                                                            display:
                                                                                                                'flex',
                                                                                                            alignItems:
                                                                                                                'center',
                                                                                                            justifyContent:
                                                                                                                'flex-end',
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            style={{
                                                                                                                backgroundColor:
                                                                                                                    '#71BE1D',
                                                                                                                // padding: "4px",
                                                                                                                color: 'white',
                                                                                                                borderRadius:
                                                                                                                    '5px',
                                                                                                                fontSize:
                                                                                                                    '10px',
                                                                                                                fontWeight:
                                                                                                                    'bold',
                                                                                                                width: 75,
                                                                                                                textAlign:
                                                                                                                    'center',
                                                                                                            }}
                                                                                                        >
                                                                                                            Upcoming
                                                                                                        </div>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div
                                                                                                        style={{
                                                                                                            width: '100%',
                                                                                                            display:
                                                                                                                'flex',
                                                                                                            alignItems:
                                                                                                                'center',
                                                                                                            justifyContent:
                                                                                                                'flex-end',
                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            style={{
                                                                                                                backgroundColor:
                                                                                                                    'white',
                                                                                                                padding:
                                                                                                                    '4px',
                                                                                                                color: 'grey',
                                                                                                                border: '1px solid #71BE1D',
                                                                                                                borderRadius:
                                                                                                                    '5px',
                                                                                                                fontSize:
                                                                                                                    '10px',
                                                                                                                fontWeight:
                                                                                                                    'bold',
                                                                                                                // width: 75,
                                                                                                                textAlign:
                                                                                                                    'center',
                                                                                                            }}
                                                                                                        >
                                                                                                            Unscheduled
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr style={{}}>
                                                        <td
                                                            align='left'
                                                            valign='top'
                                                            style={{
                                                                padding:
                                                                    '20px 30px',
                                                                borderBottom:
                                                                    workflow.type ===
                                                                    'Job'
                                                                        ? '1px solid #ddd'
                                                                        : '0px solid white',
                                                            }}
                                                        >
                                                            <table
                                                                border='0'
                                                                cellPadding='0'
                                                                cellSpacing='0'
                                                                width='100%'
                                                                align='center'
                                                            >
                                                                <tr>
                                                                    <td
                                                                        align='left'
                                                                        valign='top'
                                                                        width='50%'
                                                                    >
                                                                        <h4
                                                                            style={{
                                                                                margin: '0',
                                                                                padding:
                                                                                    '0',
                                                                                fontSize:
                                                                                    '10px',
                                                                                color: '#999',
                                                                            }}
                                                                        >
                                                                            {workflow.type ===
                                                                            'Estimate'
                                                                                ? 'NAME:'
                                                                                : 'BILL TO:'}
                                                                        </h4>
                                                                        {Object.keys(
                                                                            rowdata.invoice_partner
                                                                        )
                                                                            .length !==
                                                                        0 ? (
                                                                            <p
                                                                                style={{
                                                                                    margin: '0',
                                                                                    padding:
                                                                                        '0',
                                                                                    lineHeight:
                                                                                        '21px',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        margin: '0',
                                                                                        padding:
                                                                                            '0',
                                                                                        display:
                                                                                            'block',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        rowdata
                                                                                            .invoice_partner
                                                                                            .partner_first_name
                                                                                    }{' '}
                                                                                    {
                                                                                        rowdata
                                                                                            .invoice_partner
                                                                                            .partner_last_name
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    rowdata
                                                                                        .invoice_partner
                                                                                        .partner_billing_address
                                                                                        .street
                                                                                }{' '}
                                                                                <br />
                                                                                {
                                                                                    rowdata
                                                                                        .invoice_partner
                                                                                        .partner_billing_address
                                                                                        .city
                                                                                }{' '}
                                                                                <br />
                                                                                {
                                                                                    rowdata
                                                                                        .invoice_partner
                                                                                        .partner_billing_address
                                                                                        .state
                                                                                }{' '}
                                                                                {
                                                                                    rowdata
                                                                                        .invoice_partner
                                                                                        .partner_billing_address
                                                                                        .zip_code
                                                                                }{' '}
                                                                                {
                                                                                    rowdata
                                                                                        .invoice_partner
                                                                                        .partner_billing_address
                                                                                        .country
                                                                                }
                                                                            </p>
                                                                        ) : (
                                                                            <>
                                                                                Partner
                                                                                not
                                                                                selected
                                                                            </>
                                                                        )}
                                                                    </td>

                                                                    <td
                                                                        align='right'
                                                                        valign='top'
                                                                        width='50%'
                                                                    >
                                                                        <h4
                                                                            style={{
                                                                                margin: '0',
                                                                                padding:
                                                                                    '0',
                                                                                fontSize:
                                                                                    '10px',
                                                                                color: '#999',
                                                                            }}
                                                                        >
                                                                            FROM:
                                                                        </h4>
                                                                        {Object.keys(
                                                                            rowdata.invoice_partner
                                                                        )
                                                                            .length !==
                                                                        0 ? (
                                                                            <p
                                                                                style={{
                                                                                    margin: '0',
                                                                                    padding:
                                                                                        '0',
                                                                                    lineHeight:
                                                                                        '21px',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        margin: '0',
                                                                                        padding:
                                                                                            '0',
                                                                                        display:
                                                                                            'block',
                                                                                        fontWeight:
                                                                                            'bold',
                                                                                    }}
                                                                                >
                                                                                    {bdata.business_name
                                                                                        ? bdata.business_name
                                                                                        : bdata.business_user_name}
                                                                                </div>
                                                                                {
                                                                                    bdata
                                                                                        .business_address
                                                                                        ?.street
                                                                                }{' '}
                                                                                <br />
                                                                                {
                                                                                    bdata
                                                                                        .business_address
                                                                                        ?.city
                                                                                }{' '}
                                                                                <br />
                                                                                {
                                                                                    bdata
                                                                                        .business_address
                                                                                        ?.state
                                                                                }{' '}
                                                                                {
                                                                                    bdata
                                                                                        .business_address
                                                                                        ?.zip_code
                                                                                }{' '}
                                                                                {
                                                                                    bdata
                                                                                        .business_address
                                                                                        ?.country
                                                                                }
                                                                            </p>
                                                                        ) : (
                                                                            <>
                                                                                Partner
                                                                                not
                                                                                selected
                                                                            </>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    {workflow.type === 'Job' ? (
                                                        <tr style={{}}>
                                                            <td
                                                                align='left'
                                                                valign='top'
                                                                style={{
                                                                    padding:
                                                                        '20px 30px',
                                                                }}
                                                            >
                                                                <table
                                                                    border='0'
                                                                    cellPadding='0'
                                                                    cellSpacing='0'
                                                                    width='100%'
                                                                    align='center'
                                                                >
                                                                    <tr>
                                                                        <td
                                                                            align='left'
                                                                            valign='top'
                                                                            width='50%'
                                                                        >
                                                                            <p
                                                                                style={{
                                                                                    fontSize: 19,
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                }}
                                                                            >
                                                                                Job
                                                                                Description
                                                                            </p>
                                                                            <h4
                                                                                style={{
                                                                                    margin: '0',
                                                                                    padding:
                                                                                        '0',
                                                                                    fontSize:
                                                                                        '12px',
                                                                                    color: '#999',
                                                                                }}
                                                                            >
                                                                                Job
                                                                                Name:
                                                                            </h4>
                                                                            <p
                                                                                style={{
                                                                                    margin: '0',
                                                                                    padding:
                                                                                        '0',
                                                                                    lineHeight:
                                                                                        '21px',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        margin: '0',
                                                                                        padding:
                                                                                            '0',
                                                                                        display:
                                                                                            'block',
                                                                                        fontSize: 14,
                                                                                        // fontWeight: "bold",
                                                                                    }}
                                                                                >
                                                                                    {rowdata.invoice_title
                                                                                        ? rowdata.invoice_title
                                                                                        : '-'}
                                                                                </div>
                                                                            </p>
                                                                            <h4
                                                                                style={{
                                                                                    margin: '0',
                                                                                    padding:
                                                                                        '0',
                                                                                    fontSize:
                                                                                        '12px',
                                                                                    color: '#999',
                                                                                    marginTop: 10,
                                                                                }}
                                                                            >
                                                                                Job
                                                                                Description:
                                                                            </h4>
                                                                            <p
                                                                                style={{
                                                                                    margin: '0',
                                                                                    padding:
                                                                                        '0',
                                                                                    lineHeight:
                                                                                        '21px',
                                                                                    // backgroundColor: "pink",
                                                                                    maxWidth: 300,
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        margin: '0',
                                                                                        padding:
                                                                                            '0',
                                                                                        display:
                                                                                            'block',
                                                                                        fontSize: 14,
                                                                                        // fontWeight: "bold",
                                                                                    }}
                                                                                >
                                                                                    {rowdata.invoice_description
                                                                                        ? rowdata.invoice_description
                                                                                        : '-'}
                                                                                </div>
                                                                            </p>
                                                                        </td>
                                                                        <td
                                                                            align='left'
                                                                            valign='top'
                                                                            width='50%'
                                                                        >
                                                                            <p
                                                                                style={{
                                                                                    fontSize: 19,
                                                                                    fontWeight:
                                                                                        'bold',
                                                                                }}
                                                                            >
                                                                                Job
                                                                                Timings
                                                                            </p>

                                                                            <h4
                                                                                style={{
                                                                                    margin: '0',
                                                                                    padding:
                                                                                        '0',
                                                                                    fontSize:
                                                                                        '12px',
                                                                                    color: '#999',
                                                                                    marginTop: 10,
                                                                                }}
                                                                            >
                                                                                Job
                                                                                Timings:
                                                                            </h4>
                                                                            <p
                                                                                style={{
                                                                                    margin: '0',
                                                                                    padding:
                                                                                        '0',
                                                                                    lineHeight:
                                                                                        '21px',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        margin: '0',
                                                                                        padding:
                                                                                            '0',
                                                                                        display:
                                                                                            'block',
                                                                                        fontSize: 14,
                                                                                        // fontWeight: "bold",
                                                                                    }}
                                                                                >
                                                                                    {rowdata.invoice_visits &&
                                                                                    rowdata
                                                                                        .invoice_visits
                                                                                        .length !==
                                                                                        0
                                                                                        ? `${moment(
                                                                                              rowdata
                                                                                                  .invoice_visits[0]
                                                                                                  .appointment_start_time
                                                                                          ).format(
                                                                                              'h:mm A'
                                                                                          )} - ${moment(
                                                                                              rowdata
                                                                                                  .invoice_visits[0]
                                                                                                  .appointment_end_time
                                                                                          ).format(
                                                                                              'h:mm A'
                                                                                          )}`
                                                                                        : 'Not Scheduled'}
                                                                                </div>
                                                                            </p>
                                                                            <h4
                                                                                style={{
                                                                                    margin: '0',
                                                                                    padding:
                                                                                        '0',
                                                                                    fontSize:
                                                                                        '12px',
                                                                                    color: '#999',
                                                                                    marginTop: 10,
                                                                                }}
                                                                            >
                                                                                Job
                                                                                Date:
                                                                            </h4>
                                                                            <p
                                                                                style={{
                                                                                    margin: '0',
                                                                                    padding:
                                                                                        '0',
                                                                                    lineHeight:
                                                                                        '21px',
                                                                                }}
                                                                            >
                                                                                <div
                                                                                    style={{
                                                                                        margin: '0',
                                                                                        padding:
                                                                                            '0',
                                                                                        display:
                                                                                            'block',
                                                                                        fontSize: 14,
                                                                                        // fontWeight: "bold",
                                                                                    }}
                                                                                >
                                                                                    {rowdata.invoice_visits &&
                                                                                    rowdata
                                                                                        .invoice_visits
                                                                                        .length !==
                                                                                        0
                                                                                        ? `${moment(
                                                                                              rowdata
                                                                                                  .invoice_visits[0]
                                                                                                  .appointment_start_time
                                                                                          ).format(
                                                                                              'MMMM DD, YYYY'
                                                                                          )} `
                                                                                        : 'Not Scheduled'}
                                                                                </div>
                                                                            </p>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <></>
                                                    )}
                                                    <tr>
                                                        <td
                                                            align='right'
                                                            valign='top'
                                                            style={{
                                                                minHeight:
                                                                    workflow.type ===
                                                                    'Job'
                                                                        ? '500px'
                                                                        : '500px',
                                                                display:
                                                                    'table',
                                                                width: '100%',
                                                            }}
                                                        >
                                                            <table
                                                                border='0'
                                                                cellPadding='0'
                                                                cellSpacing='0'
                                                                width='100%'
                                                                align='center'
                                                            >
                                                                <tr>
                                                                    <td
                                                                        align='left'
                                                                        valign='top'
                                                                        style={{
                                                                            padding:
                                                                                '0 30px',
                                                                        }}
                                                                    >
                                                                        <table
                                                                            border='0'
                                                                            cellPadding='0'
                                                                            cellSpacing='0'
                                                                            width='100%'
                                                                            align='center'
                                                                        >
                                                                            <thead
                                                                                style={{
                                                                                    margin: '0',
                                                                                    padding:
                                                                                        '0',
                                                                                }}
                                                                            >
                                                                                <tr
                                                                                    className='th'
                                                                                    style={{
                                                                                        margin: '0',
                                                                                        padding:
                                                                                            '0',
                                                                                        background:
                                                                                            workflow.type ===
                                                                                            'Job'
                                                                                                ? '#1AB293'
                                                                                                : '#4f83cc',
                                                                                        color: '#fff',
                                                                                    }}
                                                                                >
                                                                                    <th
                                                                                        align='left'
                                                                                        valign='top'
                                                                                        style={{
                                                                                            padding:
                                                                                                '8px',
                                                                                            paddingLeft: 0,
                                                                                            // backgroundColor: "pink",
                                                                                            // paddingRight: 20,
                                                                                        }}
                                                                                    >
                                                                                        <p
                                                                                            style={{
                                                                                                margin: '4px 10px',
                                                                                            }}
                                                                                        >
                                                                                            Items
                                                                                        </p>
                                                                                    </th>
                                                                                    <th
                                                                                        align='center'
                                                                                        valign='top'
                                                                                        width='120px'
                                                                                        style={{
                                                                                            padding:
                                                                                                '8px',
                                                                                            // backgroundColor: "pink",
                                                                                            // paddingRight: 20,
                                                                                        }}
                                                                                    >
                                                                                        <p
                                                                                            align='center'
                                                                                            style={{
                                                                                                margin: '4px 10px',
                                                                                            }}
                                                                                        >
                                                                                            Qty
                                                                                        </p>
                                                                                    </th>
                                                                                    <th
                                                                                        align='center'
                                                                                        valign='top'
                                                                                        width='120px'
                                                                                        style={{
                                                                                            padding:
                                                                                                '8px',
                                                                                        }}
                                                                                    >
                                                                                        <p
                                                                                            align='center'
                                                                                            style={{
                                                                                                margin: '4px 10px',
                                                                                            }}
                                                                                        >
                                                                                            Unit
                                                                                            Cost
                                                                                        </p>
                                                                                    </th>
                                                                                    <th
                                                                                        align='right'
                                                                                        valign='top'
                                                                                        width='90px'
                                                                                        // align="right"
                                                                                        style={{
                                                                                            padding:
                                                                                                '8px',
                                                                                        }}
                                                                                    >
                                                                                        <p
                                                                                            align='right'
                                                                                            style={{
                                                                                                margin: '4px 10px',
                                                                                            }}
                                                                                        >
                                                                                            Total
                                                                                        </p>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody
                                                                                style={{
                                                                                    margin: '0',
                                                                                    padding:
                                                                                        '0',
                                                                                    border: '0',
                                                                                }}
                                                                            >
                                                                                {rowdata.invoice_order.map(
                                                                                    (
                                                                                        element,
                                                                                        index
                                                                                    ) => {
                                                                                        return (
                                                                                            <tr
                                                                                                key={
                                                                                                    index
                                                                                                }
                                                                                            >
                                                                                                <td
                                                                                                    align='left'
                                                                                                    valign='top'
                                                                                                    style={{
                                                                                                        borderBottom:
                                                                                                            '1px solid #ddd',
                                                                                                        padding:
                                                                                                            '8px',
                                                                                                    }}
                                                                                                >
                                                                                                    <p
                                                                                                        style={{
                                                                                                            margin: '0',
                                                                                                            padding:
                                                                                                                '0',
                                                                                                            lineHeight:
                                                                                                                '30px',
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            element.item_name
                                                                                                        }
                                                                                                    </p>
                                                                                                    {element.item_description ? (
                                                                                                        <p
                                                                                                            style={{
                                                                                                                margin: '0',
                                                                                                                padding:
                                                                                                                    '0',
                                                                                                                lineHeight:
                                                                                                                    '13px',
                                                                                                                fontSize: 11,
                                                                                                                marginBottom: 4,
                                                                                                            }}
                                                                                                        >
                                                                                                            {element
                                                                                                                .item_description
                                                                                                                .length >
                                                                                                            120
                                                                                                                ? element.item_description.substring(
                                                                                                                      0,
                                                                                                                      120
                                                                                                                  ) +
                                                                                                                  ' ...'
                                                                                                                : element.item_description}
                                                                                                        </p>
                                                                                                    ) : (
                                                                                                        <>

                                                                                                        </>
                                                                                                    )}
                                                                                                </td>
                                                                                                <td
                                                                                                    align='right'
                                                                                                    // valign="top"
                                                                                                    style={{
                                                                                                        borderBottom:
                                                                                                            '1px solid #ddd',
                                                                                                        padding:
                                                                                                            '8px',
                                                                                                    }}
                                                                                                >
                                                                                                    <p
                                                                                                        align='center'
                                                                                                        style={{
                                                                                                            margin: '0',
                                                                                                            padding:
                                                                                                                '0',
                                                                                                            lineHeight:
                                                                                                                '30px',
                                                                                                        }}
                                                                                                    >
                                                                                                        {
                                                                                                            element.item_quantity
                                                                                                        }
                                                                                                    </p>
                                                                                                </td>
                                                                                                <td
                                                                                                    align='right'
                                                                                                    // valign="top"
                                                                                                    style={{
                                                                                                        borderBottom:
                                                                                                            '1px solid #ddd',
                                                                                                        padding:
                                                                                                            '8px',
                                                                                                    }}
                                                                                                >
                                                                                                    <p
                                                                                                        align='center'
                                                                                                        style={{
                                                                                                            margin: '0',
                                                                                                            padding:
                                                                                                                '0',
                                                                                                            lineHeight:
                                                                                                                '30px',
                                                                                                        }}
                                                                                                    >
                                                                                                        {(
                                                                                                            (type ===
                                                                                                            'SUPPLIER'
                                                                                                                ? element.item_cost_price
                                                                                                                    ? element.item_cost_price
                                                                                                                    : 0
                                                                                                                : element.item_selling_price
                                                                                                                ? element.item_selling_price
                                                                                                                : 0) *
                                                                                                            multiplier
                                                                                                        ).toLocaleString(
                                                                                                            'en-US',
                                                                                                            {
                                                                                                                style: 'currency',
                                                                                                                currency:
                                                                                                                    rowdata &&
                                                                                                                    rowdata.invoice_currency_code
                                                                                                                        ? rowdata.invoice_currency_code
                                                                                                                        : usercurrency,
                                                                                                            }
                                                                                                        )}
                                                                                                    </p>
                                                                                                </td>
                                                                                                <td
                                                                                                    align='right'
                                                                                                    // valign="top"
                                                                                                    style={{
                                                                                                        borderBottom:
                                                                                                            '1px solid #ddd',
                                                                                                        padding:
                                                                                                            '8px',
                                                                                                    }}
                                                                                                >
                                                                                                    <p
                                                                                                        style={{
                                                                                                            margin: '0',
                                                                                                            padding:
                                                                                                                '0',
                                                                                                            lineHeight:
                                                                                                                '30px',
                                                                                                        }}
                                                                                                    >
                                                                                                        {(
                                                                                                            element.item_quantity *
                                                                                                            (type ===
                                                                                                            'SUPPLIER'
                                                                                                                ? element.item_cost_price
                                                                                                                    ? element.item_cost_price
                                                                                                                    : 0
                                                                                                                : element.item_selling_price
                                                                                                                ? element.item_selling_price
                                                                                                                : 0) *
                                                                                                            multiplier
                                                                                                        ).toLocaleString(
                                                                                                            'en-US',
                                                                                                            {
                                                                                                                style: 'currency',
                                                                                                                currency:
                                                                                                                    rowdata &&
                                                                                                                    rowdata.invoice_currency_code
                                                                                                                        ? rowdata.invoice_currency_code
                                                                                                                        : usercurrency,
                                                                                                            }
                                                                                                        )}
                                                                                                    </p>
                                                                                                </td>
                                                                                            </tr>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                                {workflow.type !==
                                                                'Job' ? (
                                                                    <></>
                                                                ) : (
                                                                    <>
                                                                        <tr>
                                                                            <td
                                                                                align='right'
                                                                                valign='top'
                                                                                style={{
                                                                                    padding:
                                                                                        '20px 38px 20px 30px',
                                                                                    paddingTop:
                                                                                        workflow.type ===
                                                                                        'Job'
                                                                                            ? 10
                                                                                            : 60,
                                                                                }}
                                                                            >
                                                                                <div>
                                                                                    <p
                                                                                        style={{
                                                                                            margin: '0',
                                                                                            padding:
                                                                                                '0',
                                                                                            lineHeight:
                                                                                                '24px',
                                                                                            fontWeight:
                                                                                                'bold',
                                                                                            // backgroundColor: "pink",
                                                                                            display:
                                                                                                'inline-flex',
                                                                                            minWidth:
                                                                                                '200px',
                                                                                            justifyContent:
                                                                                                'space-between',
                                                                                        }}
                                                                                    >
                                                                                        Subtotal:
                                                                                        <span
                                                                                            style={{
                                                                                                display:
                                                                                                    'inline-block',
                                                                                                minWidth:
                                                                                                    '90px',
                                                                                                // backgroundColor: "red",
                                                                                            }}
                                                                                        >
                                                                                            {subtotal.toLocaleString(
                                                                                                'en-US',
                                                                                                {
                                                                                                    style: 'currency',
                                                                                                    currency:
                                                                                                        rowdata &&
                                                                                                        rowdata.invoice_currency_code
                                                                                                            ? rowdata.invoice_currency_code
                                                                                                            : usercurrency,
                                                                                                }
                                                                                            )}
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                )}
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <table>
                                                            <tr>
                                                                <td width='100%'>
                                                                    {rowdata.invoice_notes ? (
                                                                        <td
                                                                            align='left'
                                                                            valign='top'
                                                                            style={{
                                                                                padding:
                                                                                    '0px 30px 20px 30px',
                                                                                // borderTop: "1px solid #ddd",
                                                                                // backgroundColor: "pink",
                                                                            }}
                                                                        >
                                                                            <strong
                                                                                style={{
                                                                                    display:
                                                                                        'block',
                                                                                }}
                                                                            >
                                                                                Notes:
                                                                            </strong>
                                                                            <p
                                                                                style={{
                                                                                    margin: '0',
                                                                                    padding:
                                                                                        '0',
                                                                                    lineHeight:
                                                                                        '21px',
                                                                                    // marginTop: 10,
                                                                                    // backgroundColor: "red",
                                                                                    whiteSpace:
                                                                                        'pre-line',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    rowdata.invoice_notes
                                                                                }
                                                                            </p>
                                                                        </td>
                                                                    ) : (
                                                                        <td
                                                                            align='left'
                                                                            valign='top'
                                                                            style={{
                                                                                padding:
                                                                                    '20px 30px',
                                                                            }}
                                                                        >
                                                                            <p
                                                                                style={{
                                                                                    margin: '0',
                                                                                    padding:
                                                                                        '0',
                                                                                    lineHeight:
                                                                                        '21px',
                                                                                }}
                                                                            ></p>
                                                                        </td>
                                                                    )}
                                                                </td>
                                                                <td
                                                                    align='right'
                                                                    valign='top'
                                                                    style={{
                                                                        padding:
                                                                            '20px 38px 20px 30px',
                                                                        // paddingTop:
                                                                        //   workflow.type === "Job" ? 10 : 60,
                                                                    }}
                                                                >
                                                                    <div>
                                                                        {workflow.type ===
                                                                        'Job' ? (
                                                                            <>

                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div>
                                                                                    <p
                                                                                        style={{
                                                                                            margin: '0',
                                                                                            padding:
                                                                                                '0',
                                                                                            lineHeight:
                                                                                                '24px',
                                                                                            fontWeight:
                                                                                                'bold',
                                                                                            // backgroundColor: "pink",
                                                                                            display:
                                                                                                'inline-flex',
                                                                                            minWidth:
                                                                                                '200px',
                                                                                            justifyContent:
                                                                                                'space-between',
                                                                                        }}
                                                                                    >
                                                                                        Subtotal:
                                                                                        <span
                                                                                            style={{
                                                                                                display:
                                                                                                    'inline-block',
                                                                                                minWidth:
                                                                                                    '90px',
                                                                                                // backgroundColor: "red",
                                                                                            }}
                                                                                        >
                                                                                            {subtotal.toLocaleString(
                                                                                                'en-US',
                                                                                                {
                                                                                                    style: 'currency',
                                                                                                    currency:
                                                                                                        rowdata &&
                                                                                                        rowdata.invoice_currency_code
                                                                                                            ? rowdata.invoice_currency_code
                                                                                                            : usercurrency,
                                                                                                }
                                                                                            )}
                                                                                        </span>
                                                                                    </p>
                                                                                </div>

                                                                                <div>
                                                                                    <p
                                                                                        style={{
                                                                                            margin: '0',
                                                                                            padding:
                                                                                                '0',
                                                                                            lineHeight:
                                                                                                '24px',
                                                                                            display:
                                                                                                'inline-flex',
                                                                                            minWidth:
                                                                                                '200px',
                                                                                            justifyContent:
                                                                                                'space-between',
                                                                                        }}
                                                                                    >
                                                                                        Discount:{' '}
                                                                                        <span
                                                                                            style={{
                                                                                                display:
                                                                                                    'inline-block',
                                                                                                minWidth:
                                                                                                    '90px',
                                                                                            }}
                                                                                        >
                                                                                            {`
                                      - ${Number(
                                          rowdata.invoice_discount_type &&
                                              rowdata.invoice_discount_type ===
                                                  'absolute'
                                              ? rowdata.invoice_discount_rate
                                              : subtotal *
                                                    rowdata.invoice_discount_rate
                                      ).toLocaleString('en-US', {
                                          style: 'currency',
                                          currency:
                                              rowdata &&
                                              rowdata.invoice_currency_code
                                                  ? rowdata.invoice_currency_code
                                                  : usercurrency,
                                      })}
                                        `}
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p
                                                                                        style={{
                                                                                            margin: '0',
                                                                                            padding:
                                                                                                '0',
                                                                                            lineHeight:
                                                                                                '24px',
                                                                                            display:
                                                                                                'inline-flex',
                                                                                            minWidth:
                                                                                                '200px',
                                                                                            justifyContent:
                                                                                                'space-between',
                                                                                        }}
                                                                                    >
                                                                                        Tax:
                                                                                        <span
                                                                                            style={{
                                                                                                display:
                                                                                                    'inline-block',
                                                                                                minWidth:
                                                                                                    '90px',
                                                                                            }}
                                                                                        >
                                                                                            {Number(
                                                                                                rowdata.invoice_tax_type &&
                                                                                                    rowdata.invoice_tax_type ===
                                                                                                        'absolute'
                                                                                                    ? rowdata.invoice_tax_rate
                                                                                                    : (rowdata.invoice_discount_type &&
                                                                                                      rowdata.invoice_discount_type ===
                                                                                                          'absolute'
                                                                                                          ? subtotal -
                                                                                                            rowdata.invoice_discount_rate
                                                                                                          : subtotal *
                                                                                                            (1 -
                                                                                                                rowdata.invoice_discount_rate)) *
                                                                                                          rowdata.invoice_tax_rate
                                                                                            ).toLocaleString(
                                                                                                'en-US',
                                                                                                {
                                                                                                    style: 'currency',
                                                                                                    currency:
                                                                                                        rowdata &&
                                                                                                        rowdata.invoice_currency_code
                                                                                                            ? rowdata.invoice_currency_code
                                                                                                            : usercurrency,
                                                                                                }
                                                                                            )}
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p
                                                                                        className='total-txt'
                                                                                        style={{
                                                                                            margin: '0',
                                                                                            padding:
                                                                                                '0',
                                                                                            paddingTop:
                                                                                                '5px',
                                                                                            fontWeight:
                                                                                                'bold',
                                                                                            lineHeight:
                                                                                                '24px',
                                                                                            display:
                                                                                                'inline-flex',
                                                                                            minWidth:
                                                                                                '200px',
                                                                                            justifyContent:
                                                                                                'space-between',
                                                                                        }}
                                                                                    >
                                                                                        Total:{' '}
                                                                                        <span
                                                                                            style={{
                                                                                                display:
                                                                                                    'inline-block',
                                                                                                minWidth:
                                                                                                    '90px',
                                                                                            }}
                                                                                        >
                                                                                            {Number(
                                                                                                sumCalculation(
                                                                                                    subtotal,
                                                                                                    rowdata
                                                                                                )
                                                                                            ).toLocaleString(
                                                                                                'en-US',
                                                                                                {
                                                                                                    style: 'currency',
                                                                                                    currency:
                                                                                                        rowdata &&
                                                                                                        rowdata.invoice_currency_code
                                                                                                            ? rowdata.invoice_currency_code
                                                                                                            : usercurrency,
                                                                                                }
                                                                                            )}
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                                <div>
                                                                                    <p
                                                                                        style={{
                                                                                            margin: '0',
                                                                                            padding:
                                                                                                '0',
                                                                                            lineHeight:
                                                                                                '24px',
                                                                                            display:
                                                                                                'inline-flex',
                                                                                            minWidth:
                                                                                                '200px',
                                                                                            justifyContent:
                                                                                                'space-between',
                                                                                        }}
                                                                                    >
                                                                                        Settled
                                                                                        Amount:{' '}
                                                                                        <span
                                                                                            style={{
                                                                                                display:
                                                                                                    'inline-block',
                                                                                                minWidth:
                                                                                                    '90px',
                                                                                            }}
                                                                                        >
                                                                                            {Number(
                                                                                                rowdata.invoice_settled_amount
                                                                                                    ? rowdata.invoice_settled_amount
                                                                                                    : 0
                                                                                            ).toLocaleString(
                                                                                                'en-US',
                                                                                                {
                                                                                                    style: 'currency',
                                                                                                    currency:
                                                                                                        rowdata &&
                                                                                                        rowdata.invoice_currency_code
                                                                                                            ? rowdata.invoice_currency_code
                                                                                                            : usercurrency,
                                                                                                }
                                                                                            )}
                                                                                        </span>
                                                                                    </p>
                                                                                </div>

                                                                                <strong
                                                                                    style={{
                                                                                        // display: "inline-block",
                                                                                        background:
                                                                                            '#f5f5f5',
                                                                                        marginTop:
                                                                                            '8px',
                                                                                        // width: "100%",
                                                                                        margin: '0',
                                                                                        padding:
                                                                                            '0',
                                                                                        lineHeight:
                                                                                            '24px',
                                                                                        display:
                                                                                            'inline-flex',
                                                                                        minWidth:
                                                                                            '230px',
                                                                                        justifyContent:
                                                                                            'space-between',
                                                                                        // backgroundColor: "red",
                                                                                        marginRight:
                                                                                            '-15px',
                                                                                        padding:
                                                                                            '5px 15px',
                                                                                    }}
                                                                                >
                                                                                    Balance
                                                                                    Amount:{' '}
                                                                                    <span
                                                                                        style={{
                                                                                            display:
                                                                                                'inline-block',
                                                                                            minWidth:
                                                                                                '90px',
                                                                                        }}
                                                                                    >
                                                                                        {Number(
                                                                                            sumCalculation(
                                                                                                subtotal,
                                                                                                rowdata
                                                                                            ) -
                                                                                                (isNaN(
                                                                                                    rowdata.invoice_settled_amount
                                                                                                )
                                                                                                    ? (0).toFixed(
                                                                                                          2
                                                                                                      )
                                                                                                    : Number(
                                                                                                          rowdata.invoice_settled_amount
                                                                                                      ).toFixed(
                                                                                                          2
                                                                                                      ))
                                                                                        ).toLocaleString(
                                                                                            'en-US',
                                                                                            {
                                                                                                style: 'currency',
                                                                                                currency:
                                                                                                    rowdata &&
                                                                                                    rowdata.invoice_currency_code
                                                                                                        ? rowdata.invoice_currency_code
                                                                                                        : usercurrency,
                                                                                            }
                                                                                        )}
                                                                                    </span>
                                                                                </strong>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </tr>
                                                    <tr style={{}}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                                paddingTop: 20,
                                                                // borderTop: "1px solid #ddd",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    marginRight: 5,
                                                                }}
                                                            >
                                                                Powered by
                                                            </div>
                                                            <img
                                                                src={icon}
                                                                alt='BestMate'
                                                                id='authheaderlogo'
                                                                style={{
                                                                    alignSelf:
                                                                        'center',
                                                                    height: 20,
                                                                    width: 22,
                                                                    marginBottom: 3,
                                                                }}
                                                            />
                                                            <div
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                    marginLeft: 5,
                                                                }}
                                                            >
                                                                BESTMATE
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                fontSize: 10,
                                                                color: '#888a8a',
                                                            }}
                                                        >
                                                            {workflow.type}{' '}
                                                            generated with
                                                            Bestmate - All in
                                                            one Business App
                                                        </div>
                                                        <div
                                                            style={{
                                                                textAlign:
                                                                    'center',
                                                                fontSize: 10,
                                                                color: '#888a8a',
                                                                lineHeight: 0.95,
                                                                marginBottom: 15,
                                                            }}
                                                        >
                                                            Visit bestmate.us
                                                            and open your
                                                            account free on
                                                            bestmate
                                                        </div>
                                                    </tr>
                                                </table>
                                                <table
                                                    border='0'
                                                    cellPadding='0'
                                                    cellSpacing='0'
                                                    // width="100%"
                                                    // maxWidth="400"
                                                    width='800'
                                                    align='center'
                                                    style={{
                                                        // width: "100%",
                                                        // maxWidth: "400",
                                                        backgroundColor:
                                                            'white',
                                                        borderTop:
                                                            '1px solid #ddd',
                                                        borderRadius:
                                                            '0 0 5px 5px',
                                                    }}
                                                >
                                                    <tr>
                                                        <td
                                                            align='right'
                                                            valign='top'
                                                            style={{
                                                                padding:
                                                                    '20px 30px',
                                                            }}
                                                        >
                                                            <span
                                                                onClick={() => {}}
                                                            >
                                                                <ReactToPrint
                                                                    trigger={() => (
                                                                        <button
                                                                            style={{
                                                                                display:
                                                                                    'inline-block',
                                                                                cursor: 'pointer',
                                                                                background:
                                                                                    '#eee',
                                                                                border: '1px solid #999',
                                                                                color: '#000',
                                                                                textDecoration:
                                                                                    'none',
                                                                                padding:
                                                                                    '5px 10px',
                                                                                borderRadius:
                                                                                    '10px',
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon={
                                                                                    faPrint
                                                                                }
                                                                                style={{
                                                                                    marginRight: 10,
                                                                                }}
                                                                            />
                                                                            Print
                                                                        </button>
                                                                    )}
                                                                    content={() =>
                                                                        printableArea.current
                                                                    }
                                                                />
                                                            </span>
                                                            <a
                                                                href={
                                                                    'https://bestmate.us/invoice/view/download/' +
                                                                    id
                                                                }
                                                                title='Download'
                                                                onClick={() => {}}
                                                                style={{
                                                                    display:
                                                                        'inline-block',
                                                                    background:
                                                                        '#eee',
                                                                    border: '1px solid #999',
                                                                    color: '#000',
                                                                    textDecoration:
                                                                        'none',
                                                                    padding:
                                                                        '5px 20px',
                                                                    marginLeft:
                                                                        '10px',
                                                                    borderRadius:
                                                                        '10px',
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faDownload
                                                                    }
                                                                    style={{
                                                                        marginRight: 10,
                                                                    }}
                                                                />
                                                                Download
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        </>
                    ) : (
                        <Page404Authenticated />
                    )}
                </>
            ) : (
                <div
                    className='d-flex justify-content-center align-items-center'
                    style={{ height: '70vh' }}
                >
                    <Spinner
                        animation='border'
                        variant='dark'
                        className='me-2'
                    />
                </div>
            )}
        </>
    );
};

export default InvoiceDetail;
