import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Navbar, Nav, Form, InputGroup } from "react-bootstrap";
import {
  AlertCircle,
  Bell,
  BellOff,
  Home,
  MessageCircle,
  UserPlus,
  Search,
  Map,
} from "react-feather";
import Clock from "react-live-clock";
import useSidebar from "../../hooks/useSidebar";
import moment from "moment";
import ReactCountryFlag from "react-country-flag";

// import NavbarLanguages from "./NavbarLanguages";
// import NavbarUser from "./NavbarUser";

import NavbarDropdown from "./NavbarDropdown";
import NavbarDropdownItem from "./NavbarDropdownItem";
import NavbarLanguages from "./NavbarLanguages";
import NavbarUser from "./NavbarUser";

import avatar1 from "../../assets/img/avatars/avatar.jpg";
import avatar3 from "../../assets/img/avatars/avatar-3.jpg";
import avatar4 from "../../assets/img/avatars/avatar-4.jpg";
import avatar5 from "../../assets/img/avatars/avatar-5.jpg";

const notifications = [
  {
    type: "important",
    title: "Update completed",
    description: "Restart server 12 to complete the update.",
    time: "2h ago",
  },
  {
    type: "default",
    title: "Lorem ipsum",
    description: "Aliquam ex eros, imperdiet vulputate hendrerit et.",
    time: "6h ago",
  },
  {
    type: "login",
    title: "Login from 192.186.1.1",
    description: "",
    time: "6h ago",
  },
  {
    type: "request",
    title: "New connection",
    description: "Anna accepted your request.",
    time: "12h ago",
  },
];

const messages = [
  {
    name: "Ashley Briggs",
    avatar: avatar5,
    description: "Nam pretium turpis et arcu. Duis arcu tortor.",
    time: "15m ago",
  },
  {
    name: "Chris Wood",
    avatar: avatar1,
    description: "Curabitur ligula sapien euismod vitae.",
    time: "2h ago",
  },
  {
    name: "Stacie Hall",
    avatar: avatar4,
    description: "Pellentesque auctor neque nec urna.",
    time: "4h ago",
  },
  {
    name: "Bertha Martin",
    avatar: avatar3,
    description: "Aenean tellus metus, bibendum sed, posuere ac, mattis non.",
    time: "5h ago",
  },
];
const NavbarComponent = () => {
  const { t } = useTranslation();
  const { isOpen, setIsOpen, behavior, setBehavior } = useSidebar();
  const [countryCode, setCountrycode] = useState("");

  useEffect(() => {
    fetch("https://ipapi.co/json/", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const result = await res.json();
        console.log(result);
        setCountrycode(result.country);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Navbar
      // variant="light"
      expand
      className="navbar-bg border "
      style={{
        boxShadow: "none",
        // backgroundColor: "#f0f3fc",
        // backgroundColor: "#F2F2F4",
        // width: isOpen ? "calc(100% - 220px)" : "100%",
        //   // width: "calc(100% - 220px)",
        //   // display: "flex",
        //   flexGrow: 1,

        position: "sticky",
        top: 0,
        zIndex: 20,
        //   flex: 1,
      }}
    >
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          // setIsOpen(!isOpen);
          console.log(behavior);
          if (behavior === "sticky") {
            setBehavior("compact");
          } else {
            setBehavior("sticky");
          }
        }}
        // style={{ flexGrow: 1 }}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Navbar.Collapse>
        <Nav className="navbar-align">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 10px",
            }}
          >
            {new Date().toDateString()}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0 10px",
            }}
          >
            <Clock format={"hh:mm a"} ticking={true} />
          </div>

          {/* <NavbarDropdown
            header="New Messages"
            footer="Show all messages"
            icon={MessageCircle}
            count={messages.length}
            showBadge
          >
            {messages.map((item, key) => {
              return (
                <NavbarDropdownItem
                  key={key}
                  icon={
                    <img
                      className="avatar img-fluid rounded-circle"
                      src={item.avatar}
                      alt={item.name}
                    />
                  }
                  title={item.name}
                  description={item.description}
                  time={item.time}
                  spacing
                />
              );
            })}
          </NavbarDropdown> */}
          {/* <NavbarDropdown
            header="New Notifications"
            footer="Show all notifications"
            icon={BellOff}
            count={notifications.length}
          >
            {notifications.map((item, key) => {
              let icon = <Bell size={18} className="text-warning" />;

              if (item.type === "important") {
                icon = <AlertCircle size={18} className="text-danger" />;
              }

              if (item.type === "login") {
                icon = <Home size={18} className="text-primary" />;
              }

              if (item.type === "request") {
                icon = <UserPlus size={18} className="text-success" />;
              }

              return (
                <NavbarDropdownItem
                  key={key}
                  icon={icon}
                  title={item.title}
                  description={item.description}
                  time={item.time}
                />
              );
            })}
          </NavbarDropdown> */}
          {/* <NavbarLanguages /> */}
          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
