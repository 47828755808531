import React, { useEffect, useState } from 'react';
import {
    useRoutes,
    Redirect,
    useNavigate,
    Navigate,
    useLocation,
} from 'react-router-dom';
import firebase from 'firebase/compat/app';
// import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from 'react-helmet-async';
// import { store } from "./redux/store";

import './i18n';
// import routes from "./routes";

import { ThemeProvider } from './contexts/ThemeContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { LayoutProvider } from './contexts/LayoutContext';
import ChartJsDefaults from './utils/ChartJsDefaults';
import { FormProvider } from './contexts/InvoiceformContext';
import useAuth from './hooks/useAuth';

import AuthLayout from './layouts/Auth';
import DashboardLayout from './layouts/Dashboard';
//Dashboard
import Default from './pages/dashboards/Default';

// Pages
// import Blank from "./pages/pages/Blank";
// import Invoice from "./pages/pages/Invoice";
// import Estimate from "./pages/pages/Estimate";
// import Orders from "./pages/pages/Orders";
// import Settings from "./pages/pages/Settings";
// import Product from "./pages/pages/Product";
// import Services from "./pages/pages/Services";
import Customer from './pages/pages/Customer';
// import Supplier from "./pages/pages/Supplier";
// import Transaction from "./pages/pages/Transaction";
// import TransactionAdd from "./pages/pages/TransactionAdd";
// import PriceList from "./pages/pages/PriceList";
// import PriceListAdd from "./pages/pages/PriceListAdd";
// import PriceListDisplay from "./pages/pages/PriceListDisplay";
// import PriceListEdit from "./pages/pages/PriceListEdit";
// import Schedule from "./pages/pages/Schedule";
// import ScheduleEdit from "./pages/pages/ScheduleEdit";
// import Expense from "./pages/pages/Expense";
// import InvoiceSelection from "./pages/pages/InvoiceSelection";

// Auth
import Page500 from './pages/auth/Page500';
import Page404 from './pages/auth/Page404';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import ResetPassword from './pages/auth/ResetPassword';

// Protected routes
// import ProtectedPage from "./pages/protected/ProtectedPage";
// import Partnerform from "./pages/pages/Partnerform";
// import Service from "./pages/pages/Services";
// import Itemform from "./pages/pages/Itemform";
// import BusinessProfile from "./pages/pages/BusinessProfile";
import InvoiceDetail from './pages/pages/InvoiceDetail';
// import JobDetail from "./pages/pages/JobDetail";
// import EditInvoice from "./pages/pages/EditInvoice";
// import InputComponentPartner from "./pages/pages/InputComponentPartner";
// import InputComponentItem from "./pages/pages/InputComponentItem";
// import OnlinePayments from "./pages/pages/OnlinePayments";
// import AddInvoice from "./pages/pages/AddInvoice";
// import AddSchedule from "./pages/pages/AddSchedule";
import ViewPartner from './pages/pages/ViewPartner';
import Page404Authenticated from './pages/auth/Page404authenticated';
// import Subscription from "./pages/pages/Subscription";
import NewBusinessmodal from './components/NewBusinessmodal';
// import Onboarding from "./pages/pages/Onboarding";
// import Timesheet from "./pages/pages/Timesheet";
import { usePremium } from './contexts/PremiumContext';
import { SelectedProvider } from './contexts/SelectedTab';
import EmailTemplate from './pages/pages/EmailTemplate';
import ShowDetail from './pages/pages/ShowDetail';
import Analytics from './pages/pages/Analytics';

const MyModal = (props) => {
    const [createnewmodal, setCreatenewmodal] = useState(true);
    return (
        <>
            <React.Fragment>
                <NewBusinessmodal
                    createnewmodal={createnewmodal}
                    setCreatenewmodal={setCreatenewmodal}
                />
            </React.Fragment>
        </>
    );
};
const App = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { moreInfo, setMoreInfo } = usePremium();
    const [businesExist, setBusinessExist] = useState(false);
    const [business_email, setBusiness_email] = useState('');

    console.log(location);
    const { bdata, isAuthenticated, isInitialized, token, currency, setBdata } =
        useAuth();
    let routes = [];
    if (isInitialized && !isAuthenticated) {
        routes = [
            {
                path: '/',
                element: <Navigate replace to='/sign-in' />,
            },
            {
                path: '/',
                element: <AuthLayout />,

                children: [
                    {
                        path: 'sign-in',
                        element: <SignIn />,
                    },
                    {
                        path: 'reset-password',
                        element: <ResetPassword />,
                    },
                ],
            },
            {
                path: '*',
                element: <AuthLayout />,
                children: [
                    {
                        path: '*',
                        element: <Page404 />,
                    },
                ],
            },
        ];
    } else if (isInitialized && isAuthenticated) {
        routes = [
            {
                path: '/',
                element: <Navigate replace to='/customer' />,
            },
            {
                path: '/sign-in',
                element: <Navigate replace to='/customer' />,
            },
            {
                path: '/sign-up',
                element: <Navigate replace to='/customer' />,
            },
            {
                path: '/',
                element: <DashboardLayout />,
                children: [
                    {
                        path: 'customer',
                        element: <Customer />,
                    },
                    {
                        path: 'analytics',
                        element: <Analytics endpoint={'analyticsDashboard1'} />,
                    },
                    {
                        path: 'analyticsDashboard2',
                        element: <Analytics endpoint={'analyticsDashboard2'} />,
                    },
                    {
                        path: 'analyticsDashboard3',
                        element: <Analytics endpoint={'analyticsDashboard3'} />,
                    },
                    {
                        path: 'analyticsDashboard4',
                        element: <Analytics endpoint={'analyticsDashboard4'} />,
                    },
                    {
                        path: 'analyticsDashboard5',
                        element: <Analytics endpoint={'analyticsDashboard5'} />,
                    },
                    {
                        path: 'analyticsDashboard6',
                        element: <Analytics endpoint={'analyticsDashboard6'} />,
                    },
                    {
                        path: 'customer/:id',
                        element: <ViewPartner type='Customer' />,
                    },
                    {
                        path: 'email-template',
                        element: <EmailTemplate />,
                    },
                    {
                        path: 'work/:id',
                        element: <InvoiceDetail />,
                    },
                    {
                        path: 'showDetail',
                        element: <ShowDetail />,
                    },
                ],
            },

            {
                path: '*',
                element: <DashboardLayout />,
                children: [
                    {
                        path: '*',
                        element: <Page404Authenticated />,
                    },
                ],
            },
        ];
    }

    const getData = async () => {
        var user = firebase.auth().currentUser;
        let token = await user.getIdToken();
        setBusiness_email(user.email);
        fetch('https://bestmate.us/api/business/more-info', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    setBusinessExist(!result.businessExists);
                    setMoreInfo(result);
                },
                (error) => {
                    console.error('Error fetching data: ', error);
                }
            );
    };

    useEffect(() => {
        if (isInitialized && isAuthenticated) {
            getData();
        } else {
            setBusinessExist(false);
        }
    }, [isAuthenticated]);

    const content = useRoutes(routes);
    console.log(content);
    console.log(bdata, isAuthenticated, isInitialized, token, currency);
    return (
        <HelmetProvider>
            <Helmet
                titleTemplate='%s | BestMate: The Next Generation Business App'
                defaultTitle='BestMate: The Next Generation Business App'
            />
            <SelectedProvider>
                <FormProvider>
                    <ThemeProvider>
                        <SidebarProvider>
                            <LayoutProvider>
                                <ChartJsDefaults />
                                {document.documentElement?.style.setProperty(
                                    '--widthh',
                                    window.screen.width
                                )}
                                {content}
                            </LayoutProvider>
                        </SidebarProvider>
                    </ThemeProvider>
                </FormProvider>
            </SelectedProvider>
        </HelmetProvider>
    );
};

export default App;
