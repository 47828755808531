import React, { useState } from "react";
import { useSSR } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { usePremium } from "../contexts/PremiumContext";
import { Modal } from "react-bootstrap";
import { X } from "react-feather";

// const Content = ({ children }) => <div>{children}</div>;
const Content = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { moreInfo } = usePremium();
  let blockpages = ["/estimates/add", "/jobs/add", "/invoices/add"];
  console.log(location);
  const res = blockpages.includes(location.pathname);
  console.log(res);
  return (
    <>
      <div
        className="content"
        style={{ padding: 0, marginTop: 0, position: "relative" }}
      >
        {/* {res ? ( */}
        {/* {res &&
        moreInfo &&
        !moreInfo.isTrialUser &&
        !moreInfo.isPremiumUser &&
        !moreInfo.isFreeUser ? (
          <Modal
            show={true}
            onHide={() => {}}
            centered
            size="sm"
            className="createnewschedule"
          >
            <Modal.Body style={{ padding: 0 }}>
              <div style={{}}>
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "#f4f4f4",
                    height: 46,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "16px 24px",
                  }}
                >
                  <h1
                    style={{
                      fontWeight: "bold",
                      fontSize: 18,
                      color: "black",
                      margin: 0,
                    }}
                  >
                    {`Upgrade to Premium plan`}
                  </h1>
                  <div
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <X
                      size={24}
                      strokeWidth={2}
                      stroke="black"
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                <div style={{ padding: "10px 24px 0 24px" }}>
                  <div
                    style={{
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "20px 5px",
                    }}
                  >
                    <p
                      style={{
                        margin: 0,
                        // fontSize: "13px",
                        fontWeight: "500",

                        textAlign: "center",
                      }}
                    >
                      You have Exceeded the maximum number of Invoices,
                      Estimate, Schedule you can create under the Free Plan.
                      Upgrate to Premium Plan to Continue using the App.
                    </p>
                 
                  </div>

                  <hr style={{ margin: 0 }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: "7px 0",
                    }}
                  >
                    <button
                      style={{
                        border: "1px solid #407FEA",
                        cursor: "pointer",
                        color: "#407FEA",
                        padding: "3px 10px",
                        backgroundColor: "white",
                      }}
                      onClick={() => navigate("/subscription")}
                    >
                      Upgrade Now
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <></>
        )} */}
        {children}
      </div>
    </>
  );
};
export default Content;
