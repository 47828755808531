import { BarChart2, LogOut, Users } from 'react-feather';

const pagesSection = [
    {
        href: '/customer',
        icon: Users,
        title: 'Customer',
    },
    {
        href: '/analytics',
        icon: BarChart2,
        title: 'Analytics Pt 1',
    },
    {
        href: '/analyticsDashboard2',
        icon: BarChart2,
        title: 'Analytics Pt 2',
    },
    {
        href: '/analyticsDashboard3',
        icon: BarChart2,
        title: 'Analytics Pt 3',
    },
    {
        href: '/analyticsDashboard4',
        icon: BarChart2,
        title: 'Analytics Pt 4',
    },
    {
        href: '/analyticsDashboard5',
        icon: BarChart2,
        title: 'Analytics Pt 5',
    },
    {
        href: '/analyticsDashboard6',
        icon: BarChart2,
        title: 'Analytics Pt 6',
    },
    {
        href: '/',
        icon: LogOut,
        title: 'Sign Out',
    },
];

const navItems = [
    {
        title: '',
        pages: pagesSection,
    },
];

export default navItems;
