import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import {
    Breadcrumb,
    Card,
    Col,
    Container,
    Row,
    Spinner,
} from 'react-bootstrap';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import '../../assets/css/App.css';
import Table from './Table';

const Analytics = (props) => {
    console.log(props);
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState('');
    const [data, setData] = useState([]);

    const getData = (token, signal) => {
        const url1 = `https://bestmate.us/api/crm/${props.endpoint}`;
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        };
        fetch(url1, { headers })
            .then((responses) => {
                return responses.json();
            })
            .then((res) => {
                setTitle(res.dashboardTitle);
                setData(res.dashboardData);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        setLoading(true);
        const abortController = new AbortController();
        const signal = abortController.signal;
        var user = firebase.auth().currentUser;

        if (user) {
            const runFunction = async () => {
                let myToken = await user.getIdToken();
                setToken(myToken);
                setData([]);
                getData(myToken, signal);
            };
            runFunction();
        }

        return () => {
            abortController.abort();
        };
    }, [props.endpoint]);

    return (
        <React.Fragment>
            <Helmet title='Analytics' />
            {loading ? (
                <div
                    className='d-flex justify-content-center align-items-center'
                    style={{ height: '100%' }}
                >
                    <Spinner
                        animation='border'
                        variant='dark'
                        className='me-2'
                    />
                </div>
            ) : (
                <Container fluid className='p-0'>
                    <Card
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '0rem 1.5rem 2rem 1.5rem',
                        }}
                        className='pt-2 pb-2 border-bottom '
                    >
                        <div>
                            <h2
                                className='mt-3 lead'
                                style={{
                                    fontSize: '24px',
                                }}
                            >
                                {title}
                            </h2>
                            <Breadcrumb
                                className='mb-0 lead'
                                style={{ fontSize: '13px' }}
                            >
                                <Breadcrumb.Item active>CRM</Breadcrumb.Item>
                                <Breadcrumb.Item
                                    active
                                    className='font-weight-bold'
                                >
                                    {title}
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </Card>
                    <div
                        style={{
                            padding: '0rem 1.5rem 2rem 1.5rem',
                        }}
                    >
                        <Row>
                            <Col>
                                <Row style={{}}>
                                    <Col lg='12'>
                                        <Card
                                            className='mb-2 border rounded-0'
                                            style={{
                                                position: 'sticky',
                                                top: 0,
                                            }}
                                        >
                                            <Card.Header>
                                                <>
                                                    {
                                                        <>
                                                            {data.map(
                                                                (elem) => (
                                                                    <>
                                                                        <div
                                                                            style={{
                                                                                fontSize: 30,
                                                                                fontWeight:
                                                                                    'bold',
                                                                                color: 'black',
                                                                                marginBottom: 10,
                                                                                marginTop: 30,
                                                                            }}
                                                                        >
                                                                            {
                                                                                elem.title
                                                                            }
                                                                        </div>
                                                                        <Table
                                                                            data={
                                                                                elem.data
                                                                            }
                                                                        />
                                                                    </>
                                                                )
                                                            )}
                                                        </>
                                                    }
                                                </>
                                            </Card.Header>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Container>
            )}
        </React.Fragment>
    );
};

export default Analytics;
