import React from "react";

import { Dropdown } from "react-bootstrap";

import { PieChart, Settings, User } from "react-feather";
import useAuth from "../../hooks/useAuth";

import avatar1 from "../../assets/img/avatars/avatar.jpg";
import { useNavigate } from "react-router-dom";
import { usePremium } from "../../contexts/PremiumContext";

const NavbarUser = () => {
  const { setMoreInfo } = usePremium();
  const navigate = useNavigate();
  const { signOut, bdata } = useAuth();
  const signout = async (e) => {
    e.preventDefault();
    try {
      await signOut();
      setMoreInfo(null);
      navigate("/");
      // navigate("/sign-in");
    } catch (error) {
      console.log(error.message);
    }
  };

  console.log(bdata);
  console.log("999999999");

  return (
    <Dropdown
      className="nav-item"
      align="end"
      // style={{ minWidth: 130, display: "flex", justifyContent: "flex-end" }}
    >
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          {/* <img
            src={avatar1}
            className="avatar img-fluid rounded-circle me-1"
            alt="Chris Wood"
          /> */}
          <span className="text-dark" style={{ fontWeight: "bold" }}>
            {bdata.business_name
              ? ` ${bdata.business_name} `
              : bdata.business_user_name
              ? ` ${bdata.business_user_name} `
              : ` Welcome `}
            {/* {` ${bdata.business_name} `} */}
          </span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu drop="end">
        {/* <Dropdown.Item onClick={() => navigate("/dashboard/profile")}>
          <User size={18} className="align-middle me-2" />
          Business Profile
        </Dropdown.Item> */}
        {/* <Dropdown.Divider /> */}
        {/* <Dropdown.Item
          onClick={() => window.open("mailto:support@bestmate.us")}
        >
          Contact Support
        </Dropdown.Item> */}
        <Dropdown.Item onClick={signout}>Sign out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarUser;
