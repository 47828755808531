import React, { useState } from "react";

const PremiumContext = React.createContext();

export const usePremium = () => React.useContext(PremiumContext);

export const PremiumProvider = ({ children }) => {
  const [moreInfo, setMoreInfo] = useState(null);

  return (
    <PremiumContext.Provider value={{ moreInfo, setMoreInfo }}>
      {children}
    </PremiumContext.Provider>
  );
};
